import React, { useEffect } from "react";
import {
    Modal,
    Form,
    Row,
    Col,
    Select,
    Tag,
    Steps,
    Input,
    Upload,
    DatePicker,
    Checkbox,
} from "antd";
import { useStepsForm } from "sunflower-antd";
import "../thuNhan/CaNhan.css"
import moment from "moment";

import { localhost } from "../../../../server";

const dateFormat = "DD-MM-YYYY";

const ModalDetailContractExpire = ({ open, onCancel, dataDetail }) => {
    // const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const BoxNewSchedule_pro = () => {
        const {
            form,
            current,
            gotoStep,
            stepsProps,
            formProps,
            submit,
            formLoading,
        } = useStepsForm({
            async submit(values) {
                await new Promise((r) => setTimeout(r, 500));
                return "ok";
            },
            total: 2,
            isBackValidate: false,
        });
        var arrUserUpdate = dataDetail.user_update.map(
            (item, index) => dataDetail.user_update[index].username
        );
        var arrTagTeam = dataDetail.tag_team.map(
            (item, index) => dataDetail.tag_team[index].name
        );

        useEffect(() => {
            form.resetFields();
            if (open) {
                form.setFieldsValue({
                    customer: dataDetail.customer[0].full_name,
                    auto_extend: dataDetail.auto_extend,
                    content: dataDetail.content,
                    day_expired: dataDetail.day_expired,
                    day_sign: dataDetail.day_sign,
                    tag_team: arrTagTeam,
                    type_document: dataDetail.type_document.length > 0 ? dataDetail.type_document[0].name : undefined,
                    rule_type_document: dataDetail.rule_type_document.length > 0 ? dataDetail.rule_type_document[0].name : undefined,
                    user_create: dataDetail.user_create[0].username,
                    user_update: arrUserUpdate,
                    project: dataDetail.project,
                    code_number: dataDetail.code_number,
                    file_document: dataDetail.file_document.map((item, index) => {
                        return item.name_file;
                    }),
                });
            }
        }, [open]);

        const FirstStep = () => {
            const formatDate = (current) => {
                return moment(current).format(dateFormat);
            };

            return (
                <>
                    <Modal
                        visible={open}
                        onCancel={onCancel}
                        footer={false}
                        width={screen.width > 768 ? 1000 : "80%"}
                    >
                        <Row className="RowThuNhan" key={1} style={{marginTop: "3%"}}>
                            <Col span={12}>
                                <Form.Item
                                    name="customer"
                                    label="Tên khách hàng"
                                    rules={[{ required: true }]}
                                    key="customer"
                                >
                                    <Input readOnly maxLength={100} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="user_create"
                                    label="Người tạo"
                                    rules={[{ required: true }]}
                                    key="user_create"
                                >
                                    <Input readOnly maxLength={100} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginLeft: "16.7%" }}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="rule_type_document"
                                                label="Tài liệu"
                                                rules={[{ required: true, message: "Không được để trống" }]}
                                                key="rule_type_document"
                                                style={{ width: "100%" }}
                                            >
                                                <Input readOnly maxLength={100} style={{ width: "95%" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} style={{ width: "400px" }}>
                                            <Form.Item
                                                name="type_document"
                                                key="type_document"
                                                style={{ width: "105%" }}
                                            // style={{ marginLeft: 35 }}
                                            >
                                                <Input readOnly maxLength={100} />
                                            </Form.Item>
                                        </Col>
                                    </div>

                                </Row>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="project"
                                    label="Tên dự án"
                                    key="project"
                                >
                                    <Input readOnly maxLength={100} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="tag_team" label="Team" key="tag_team" rules={[{ required: true }]}>
                                    <Select
                                        placeholder="Chọn người được chia sẻ"
                                        showSearch={false}
                                        optionFilterProp="children"
                                        mode="multiple"
                                        maxTagCount="responsive"
                                        open={false}
                                        disabled
                                        key="tag_team1"
                                    >
                                        {dataDetail.tag_team.map((item, index) => (
                                            <Option key={item.name} value={item.id}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="code_number"
                                    label="Số hiệu"
                                    key="code_number"
                                >
                                    <Input readOnly maxLength={100} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="user_update"
                                    label="Người được chia sẻ"
                                    key="user_update"
                                >
                                    <Select
                                        placeholder="Chọn người được chia sẻ"
                                        showSearch={false}
                                        optionFilterProp="children"
                                        mode="multiple"
                                        maxTagCount="responsive"
                                        open={false}
                                        disabled
                                        key="user_update1"
                                    >
                                        {dataDetail.user_update.map((item, index) => (
                                            <Option key={item.username} value={item.id}>
                                                {item.username}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="auto_extend"
                                    label="Tự động tái tục"
                                    key="auto_extend"
                                >
                                    <Checkbox checked={dataDetail.auto_extend}></Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="day_sign"
                                    label="Ngày kí kết"
                                    rules={[{ required: true }]}
                                    key="day_sign"
                                >
                                    <DatePicker
                                        disabled
                                        style={{ width: "100%" }}
                                        format={formatDate}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="day_expired"
                                    label="Ngày hết hạn"
                                    key="day_expired"
                                >
                                    <DatePicker
                                        disabled
                                        style={{ width: "100%" }}
                                        format={formatDate}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="content" label="Nội dung" key="content" rules={[{ required: true }]}>
                                    <Input.TextArea readOnly></Input.TextArea>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="file_document"
                                    label="File Upload"
                                    key="file_document"
                                >
                                    <div style={{ overflowY: "auto", height: 100 }}>
                                        {dataDetail.file_document.map((item, index) => (
                                            <Row
                                                style={{ marginBottom: "5px", width: "100%" }}
                                                key="detail"
                                            >
                                                <Col>
                                                    <Tag
                                                        style={{ fontSize: "12px" }}
                                                        color="blue"
                                                        value={item.name_file}
                                                        key={item.name_file}
                                                        className="detail-contract"
                                                    >
                                                        <a
                                                            href={localhost + "/media/" + item.file_contract}
                                                            // href={"/media/" + item.file_contract}
                                                            target="_blank"
                                                        >
                                                            {item.name_file}
                                                        </a>
                                                    </Tag>
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Modal>


                </>
            );
        };

        const formList = [<FirstStep />];

        return (
            <Form {...layout} {...formProps} style={{ width: "100%" }} key="form">
                {formList[current]}
            </Form>
        );
    };

    return (
        <div>
            <BoxNewSchedule_pro />
        </div>
    );
};

export default ModalDetailContractExpire
import { authAxios } from "../api/axiosClient";
import { exportUser, manageDetailUser, manageRole, manageUser, templateUser } from "../constants";

export const GetManageUser = (params = {}) => {
    const url = manageUser;
    return authAxios().get(url, { params });
};

export const GetRole = (params = {}) => {
    const url = manageRole;
    return authAxios().get(url, params);
};

export const GetDetailUser = (id, params = {}) => {
    const url = manageDetailUser;
    return authAxios().get(url(id), params);
};

export const PutUserApi = (params = {}) => {
    const url = manageUser;
    return authAxios().put(url, params);
};

export const ExportUser = (params = {}) => {
    const url = exportUser;
    return authAxios().get(url, params);
};

export const TemplateUser = (params = {}) => {
    const url = templateUser;
    return authAxios().get(url, params);
};

export const PostUser = (params = {}) => {
    const url = manageUser;
    return authAxios().post(url, params);
};
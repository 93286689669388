import { DownloadOutlined, EditOutlined, LockOutlined, UnlockOutlined, UploadOutlined } from '@ant-design/icons';
import { Box, Button, Grid } from '@mui/material';
import { Col, Input, Popconfirm, Row, Select, Table } from 'antd';
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { DownloadTypeDocument, GetDetailTypeDocument, GetListTypeDocument, GetRuleTypeDocumentAll, GetTypeDocumentAll, PutTypeDocumentApi } from '../../../../api/manageDocs';
import { errorHandle, openNotificationWithIcon } from '../../../Function';
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import '../taiLieu/taiLieu.css';
import ModalImportFileDocument from './ModalImportFileDocument';
import { useSelector } from 'react-redux';
import ModalAddDocs from './ModalAddDocs';
import ModalEditNameDocument from './ModalEditNameDocument';
import ModalEditRuleDocument from './ModalEditRuleDocument';

const { Search } = Input;
const { Option } = Select;

const validateMessages = {
  required: "${label} không được để trống!",
  types: {
    email: "${label} is not in the correct email format!",
    number: "${label} not numbers!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const TaiLieu = () => {
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const checkRoleManageAdmin = userInfo.role_name.toLowerCase() === "manage_admin"

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isModalAddNew, setIsModalAddNew] = useState(false);
  const [lsRole, setLsRole] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [detailTypeDocument, setDetailTypeDocument] = useState();
  const [dataAllTypeDocs, setDataAllTypeDocs] = useState([])
  const [dataAllNameDocs, setDataAllNameDocs] = useState([])
  const [isModalImportFile, setIsModalImportFile] = useState(false);
  const [isOpenModalEditRuleDoc, setIsOpenModalEditRuleDoc] = useState(false)
  const [pager, setPager] = useState({
    pageSize: pageSize,
    count: 0,
    current: 1,
  });
  const fetchData = (params = {}) => {
    setLoading(true);
    GetListTypeDocument(params)
      .then((res) => {
        setData(res.data.results);
        setPager({
          current: params.page,
          pageSize: params.page_size,
          count: res.data.count,
        });
        setLoading(false);
      })
      .catch((err) => {
        errorHandle(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    const id = setInterval(() => {
      fetchData({ page: 1, page_size: pager.pageSize, search });
      clearInterval(id);
    }, 300);
    return () => clearInterval(id);
  }, [search]);

  const showModalAddDocs = () => {
    setIsModalAddNew(true);
  };

  const onLock_Unlock = (record) => {
    const lock = {
      type_document_id: record.id,
      is_active: !record.is_active,
      name_type_document: record.name,
      is_lock: false,
    };
    PutTypeDocumentApi(lock)
      .then((res) => {
        openNotificationWithIcon("success", res.data.result)
        fetchData({ page: pager.current, page_size: pager.pageSize, search });
      })
      .catch((err) => {
        if (err.data.error) {
          openNotificationWithIcon("error", err.data.error);
        }
      });
  };

  const onShowModalEdit = (record) => {
    GetDetailTypeDocument(record.id)
      .then((re) => {
        setDetailTypeDocument(re.data);
        setIsEditing(true);
      })
      .catch((err) => { });
  };

  const renderDetailsButton = (record) => {
    return (
      <Box sx={{ width: "100%" }}>
        <Grid item container xs={12}>
          <Grid
            xs={5}
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
          ></Grid>
          <Grid
            xs={1}
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <span
              title="Chỉnh sửa"
              className={"col-6"}
              style={{ color: "#4290d3", cursor: "pointer", marginRight: 10 }}
              onClick={() => onShowModalEdit(record)}
            >
              <EditOutlined />
            </span>
          </Grid>
          <Grid
            xs={2}
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {record.is_active === false ? (
              <span
                title={"Tài liệu đang khóa"}
                className={"col-6"}
                style={{ color: "red", cursor: "pointer" }}
              >
                <Popconfirm
                  title="Xác nhận mở khóa tài liệu"
                  onConfirm={() => onLock_Unlock(record)}
                  okText="Mở khóa"
                  cancelText="Hủy"
                >
                  <LockOutlined style={{ color: "#ff4d4f" }} />

                </Popconfirm>
              </span>
            ) : (
              <span
                title={"Tài liệu đang mở"}
                className={"col-6"}
                style={{ color: "#008000", cursor: "pointer" }}
              >
                <Popconfirm
                  title="Xác nhận khóa loại tài liệu"
                  onConfirm={() => onLock_Unlock(record)}
                  okText="Khóa"
                  cancelText="Hủy"
                >
                  <UnlockOutlined style={{ color: "green" }} />

                </Popconfirm>
              </span>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "10%",
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
    },
    {
      title: "Loại tài liệu",
      dataIndex: "name_rule",
      key: "name_rule",
      align: "left",
      ellipsis: true,
      render: (text, record) => text
    },
    {
      title: "Tên tài liệu",
      dataIndex: "name",
      key: "name",
      align: "left",
      ellipsis: true,
      render: (text, record) => text
    },
    {
      title: "Thao tác",
      align: "center",
      render: renderDetailsButton,
      width: "20%",
    },
  ];

  const onSearch = (e) => {
    setSearch(e);
  };

  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = pagination.pageSize;
    setPager({ ...currentPager });
    fetchData({
      status: currentPager.status,
      page_size: pagination.pageSize,
      page: pagination.current,
      search,
    });
  };

  const fetchAllTypeDocument = () => {
    // setLoading(true);
    GetRuleTypeDocumentAll()
      .then((res) => {
        setDataAllTypeDocs(res.data)
        // setData(res.data.results);
        // setPager({
        //   current: params.page,
        //   pageSize: params.page_size,
        //   count: res.data.count,
        // });
        // setLoading(false);
      })
      .catch((err) => {
        errorHandle(err);
        // setLoading(false);
      });
  };

  const fetchAllNameDocument = () => {
    // setLoading(true);
    GetTypeDocumentAll()
      .then((res) => {
        setDataAllNameDocs(res.data)
        // setData(res.data.results);
        // setPager({
        //   current: params.page,
        //   pageSize: params.page_size,
        //   count: res.data.count,
        // });
        // setLoading(false);
      })
      .catch((err) => {
        errorHandle(err);
        // setLoading(false);
      });
  };

  useEffect(() => {

  }, [dataAllTypeDocs, dataAllNameDocs]);

  const showModalEditRuleDoc = () => {
    setIsOpenModalEditRuleDoc(true)
  }

  const fileDownload_TypeDocs = () => {
    // setLoading_page(true);
    const fnFileDownload = require("js-file-download");
    const filename =
      "DanhSachTaiLieu_" + moment(new Date()).format("DDMMYYYY") + ".xlsx";
    DownloadTypeDocument({ responseType: "blob" })
      .then((res) => {
        fnFileDownload(res.data, filename);
        // setLoading_page(false);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Không tải được danh sách hồ sơ. Bạn vui lòng thử lại."
        );
        // setLoading_page(false);
      });
  };

  const showModalImportFile = () => {
    setIsModalImportFile(true);
  };

  return (
    <>
      <Row style={{ width: "100%", marginBottom: "10px" }}>
        {screen.width > 768 ? (
          <>
            <Col span={16}>
              <Button
                className="btnAddUser"
                style={{ marginRight: "15px", float: "left" }}
                onClick={showModalAddDocs}
              >
                <ControlPointIcon style={{ marginRight: "5px", fontSize: 20 }} /> Thêm mới
              </Button>
              <Button
                onClick={fileDownload_TypeDocs}
                className="btnAddUser"
                style={{ float: "left", backgroundColor: "green" }}
              >
                <DownloadOutlined
                  style={{
                    fontSize: 16,
                    marginRight: 5,
                  }}
                />
                Tải xuống
              </Button>
              {/* <Button
                className="btnAddUser"
                style={{
                  float: "left",
                  backgroundColor: "#FF4C49",
                  marginLeft: 15,
                }}
                onClick={showModalImportFile}
              >
                <UploadOutlined
                  style={{
                    fontSize: 16,
                    marginRight: 5,
                  }}
                />
                Tải lên
              </Button> */}
            </Col>

            <Col span={8}>
              <Search
                placeholder="Nhập thông tin cần Tìm kiếm"
                allowClear
                enterButton="Tìm kiếm"
                size="middle"
                onSearch={onSearch}
                loading={false}
              />
            </Col>
          </>
        ) : (
          <>
            <Col span={8}>
              <Button
                className="btnAddUser"
                style={{ marginRight: "16px", float: "left", height: 32 }}
                onClick={showModalAddDocs}
              >
                <ControlPointIcon style={{ marginRight: "5px" }} /> Thêm mới
              </Button>
            </Col>
            <Col span={16}>
              <Search
                placeholder="Nhập thông tin cần Tìm kiếm"
                allowClear
                enterButton
                size="middle"
                onSearch={onSearch}
                loading={false}
              />
            </Col>
          </>
        )}
      </Row>

      <Row>
        <Col span={18} offset={3}>
          <Table
            columns={columns}
            dataSource={data}
            onChange={handleChange}
            rowClassName={"styleTaiLieu"}
            locale={{ emptyText: "Không có dữ liệu" }}
            style={{ position: "absolute" }}
            scroll={{
              y: "68vh",
            }}
            pagination={{
              current: pager.current,
              pageSize: pager.pageSize,
              // showSizeChanger: true,
              // showQuickJumper: true,
              total: pager.count,
              // pageSizeOptions: ["10", "15", "25", "50"],
              // locale: {
              //   jump_to: "Đến trang: ",
              //   page: "",
              // },
              showSizeChanger: true,
            }}
            size="middle"
          />
        </Col>
      </Row>


      <ModalAddDocs
        open={isModalAddNew}
        onCancel={() => setIsModalAddNew(false)}
        lsRole={lsRole}
        loading={loading}
        fetchData={fetchData}
        pager={pager}
        dataAllTypeDocs={dataAllTypeDocs}
        dataAllNameDocs={dataAllNameDocs}
        search={search}
        fetchAllTypeDocument={fetchAllTypeDocument}
        fetchAllNameDocument={fetchAllNameDocument}
      />

      <ModalEditNameDocument
        open={isEditing}
        data={detailTypeDocument}
        onCancel={() => setIsEditing(false)}
        fetchData={fetchData}
        pager={pager}
        // loading= {loading}
        search={search}

      />

      <ModalEditRuleDocument
        isOpenModalEditRuleDoc={isOpenModalEditRuleDoc}
        setIsOpenModalEditRuleDoc={setIsOpenModalEditRuleDoc}
        fetchData={fetchData}
        pager={pager}
        search={search}
      />

      <ModalImportFileDocument
        open={isModalImportFile}
        onCancel={() => setIsModalImportFile(false)}
        fetchData={fetchData}
        pager={pager}
        search={search}

      />
    </>
  );
};

export default TaiLieu
import React from "react";
import { notification } from "antd";
import Cookies from 'universal-cookie';
const cookies = new Cookies

export function openNotificationWithIcon(type, message, description, className,duration) {
  notification[type]({
    message: message,
    description: description,
    className: className,
    duration: duration,
    style: {
      whiteSpace: "pre-wrap",
      maxHeight: "50vh",
      overflowY: "auto",
    }
  });
};

export const validateMessages = {
  required: 'Please enter your ${label} !',
  types: {
    email: '${label} không đúng định dạng email!',
    number: '${label} không phải số!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

export const errorHandle = (err) => {
  let dataError
  if (!err.data) {
    return;
  }
  // if (err.status === 500) {
  //     dataError = "Dữ liệu có vấn đề, vui lòng kiểm tra lại"
  // }
  if (err.status === 400) {
    dataError = err.data.error
  }
  // const dataError = Object.entries(err.data).map(([key, value]) => <p>{value}</p>)
  openNotificationWithIcon('error', 'Error', dataError)
}

export function toSlug(str) {
  // Chuyển hết sang chữ thường
  // str = str.toLowerCase();     

  // xóa dấu
  str = str
    .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp

  // Thay ký tự đĐ
  str = str.replace(/[đĐ]/g, 'd');

  // Xóa ký tự đặc biệt
  // str = str.replace(/([^0-9a-z-\s])/g, '');

  // Xóa khoảng trắng
  str = str.replace(/(\s+)/g, '');

  // Xóa ký tự - liên tiếp
  str = str.replace(/-+/g, '-');

  // xóa phần dư - ở đầu & cuối
  str = str.replace(/^-+|-+$/g, '');

  // return
  return str;
}

export const QuiTacDatTenKH = () => {
  // const [dacBiet, setDacbiet] = useState("\": <> ;'");
  const dacBiet = "\": <> ;'";
  return (
    <>
      <div style={{ fontSize: 15 }}>
        <p>
          <span style={{ color: "red" }}>*</span> Sử dụng ký tự la-tinh (VN,
          EN…) để nhập thông tin.
        </p>
        <p>
          <span style={{ color: "red" }}>*</span> Trường hợp văn bản, file
          upload tải lên có thông tin cần nhập sử dụng ngôn ngữ khác (VD: hợp
          đồng với thị trường Japan) thì chuyển hóa thành ký tự quốc tế đối
          với thông tin cần nhập.
        </p>
        <u>
          <b>1. Đặt tên khách hàng</b>
        </u>
        <p>
          - Tên khách hàng được đặt theo tên công ty, tên đối tác chịu trách
          nhiệm được ghi rõ trên văn bản (hợp đồng, phụ lục, hóa đơn, biên
          bản…).
        </p>
        <p>
          - Tên khách hàng không được dài quá 30 ký tự (bao gồm cả dấu cách,
          dấu chữ cái tiếng Việt).
        </p>
        <p>- Tên khách hàng phải được ghi in hoa toàn bộ</p>
        <p>
          <span style={{ color: "red" }}>*</span> Trừ các ký tự đặc biệt như:{" "}
          {dacBiet}{" "}
        </p>
        <p>VD: CÔNG TY TNHH MTV THÉP 247</p>
        <u>
          <b>2. Đặt tên theo hợp đông</b>
        </u>
        <p>
          - Tên tổ chức là tên gọi đầy đủ của khách hàng, lấy theo tên đại
          diện ký kết được ghi rõ trên văn bản tải lên.
        </p>
        <p>VD: CÔNG TY TNHH MTV THÉP 247 HỒ CHÍ MINH</p>
        <u>
          <b>3. Mã khách hàng</b>
        </u>
        <p>
          - Mã KH là mã định danh khách hàng do Rainscales đặt (client code), mỗi
          khách hàng có một mã riêng và duy nhất.
        </p>
        <p>
          - Trường hợp chưa có mã khách hàng, liên hệ SMAST team để lấy thông
          tin mã.
        </p>
        {/* <u>
          <b>4. Địa chỉ</b>
        </u>
        <p>
          - Địa chỉ là địa chỉ của khách hàng, đối tác được ghi trên văn bản
          tải lên
        </p>
        <p>
          - Thứ tự nhập địa chỉ như sau: Quốc gia, thành phố/ tỉnh, quận/
          huyện, xã/ phường/ thị trấn, đường, số nhà, chi tiết vị trí
        </p>
        <p>
          VD: Việt Nam, Thành phố Hồ Chí Minh, quận 1, phường Thạch Thang,
          đường Đồng Khởi, số 30, chung cư Monarchy tầng 7.
        </p>
        <u>
          <b>5. Số điện thoại</b>
        </u>
        <p>
          - Là số điện thoại của doanh nghiệp, khách hàng, đối tác ghi rõ trên
          văn bản tải lên
        </p> */}
      </div>
    </>
  );
};
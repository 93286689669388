import { authAxios } from "../api/axiosClient";
import { manageBoPhan, manageDetailDepartment, manageDetailTeam, manageTeam } from "../constants";

export const GetManageTeam = (params = {}) => {
    const url = manageTeam;
    return authAxios().get(url, { params });
};

export const GetBoPhan = (params = {}) => {
    const url = manageBoPhan;
    return authAxios().get(url, params);
};

export const LockTeam = (params = {}) => {
    const url = manageTeam;
    return authAxios().put(url, params);
};

export const GetDetailTeam = (id, params = {}) => {
    const url = manageDetailTeam;
    return authAxios().get(url(id), params);
};

export const PutTeam = (id, params = {}) => {
    const url = manageDetailTeam;
    return authAxios().put(url(id), params);
};

export const PostTeam = (params = {}) => {
    const url = manageTeam;
    return authAxios().post(url, params);
};

export const PostBoPhan = (params = {}) => {
    const url = manageBoPhan;
    return authAxios().post(url, params);
};

export const PutDepartment = (id, params = {}) => {
    const url = manageDetailDepartment;
    return authAxios().put(url(id), params);
};

export const GetDetailDepartment = (id, params = {}) => {
    const url = manageDetailDepartment;
    return authAxios().get(url(id), { params });
};

import React, { useEffect, useState } from "react";
import {
    Modal,
    Form,
    Input,
    Row,
    Col,
    Select,
    AutoComplete,
} from "antd";
import {openNotificationWithIcon, toSlug } from "../../../Function";
import {
    PostTeam,
    PostBoPhan,
} from "../../../../api/manageTeam";
import moment from "moment";

import {Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import "../qlyNguoiDung.css";

const { Option } = Select;
const { Search } = Input;

const validateMessages = {
    required: "Không được để trống ô ${label} !",
    types: {
        email: "${label} đang sai định dạng!",
        number: "${label} not numbers!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const ModalAddTeam = ({
    open,
    onCancel,
    lsRole,
    loading,
    boPhan,
    fetchData,
    pager,
    getListBoPhan,
    data
}) => {
    const [form] = Form.useForm();
    const [loadingAddNew, setLoadingAddNew] = useState(false);

    const postTeam = (values) => {
        setLoadingAddNew(true);
        const addTeam = {
            name: values.name.trim(),
            description: "",
            bo_phan:
                form.getFieldValue("bo_phan") !== null
                    ? boPhan.filter(
                        (item) => item.name == form.getFieldValue("bo_phan")
                    )[0].id
                    : null,
        };
        PostTeam(addTeam)
            .then((res) => {
                if (res.data.error) {
                    setLoadingAddNew(false);
                    openNotificationWithIcon("error", res.data.error);
                } else {
                    setLoadingAddNew(false);
                    openNotificationWithIcon(
                        "success",
                        "Thành công",
                        "Thêm mới team thành công"
                    );
                    fetchData({ page: pager.current, page_size: pager.pageSize });
                    onCloseModal();
                    form.resetFields();
                    getListBoPhan();
                }
            })
            .catch((err) => {
                if (err.data.error) {
                    setLoadingAddNew(false);
                    openNotificationWithIcon("error", err.data.error);
                }
            });
    };

    const onFinish = (values) => {
        if (boPhan.map((item) => item.name).includes(values.bo_phan)) {
            postTeam(values);
        } else if (data.map((item) => item.name).includes(values.name)) {
            openNotificationWithIcon("error", "Thất bại", "Team này dã tồn tại");
        } else {
            if (values.bo_phan.trim() != "") {
                const newBoPhan = {
                    name: values.bo_phan.trim(),
                    description: "",
                };
                PostBoPhan(newBoPhan)
                    .then((res) => {
                        if (res.data.error) {
                            openNotificationWithIcon("error", res.data.error);
                        } else {
                            PostTeam({
                                name: values.name,
                                description: "",
                                bo_phan: res.data.idBPNew,
                            })
                                .then((res) => {
                                    if (res.data.error) {
                                        openNotificationWithIcon("error", res.data.error);
                                    } else {
                                        openNotificationWithIcon(
                                            "success",
                                            "Thành công",
                                            "Thêm mới team thành công"
                                        );
                                        fetchData({
                                            page: pager.current,
                                            page_size: pager.pageSize,
                                        });
                                        onCloseModal();
                                        form.resetFields();
                                        getListBoPhan();
                                    }
                                })
                                .catch((err) => {
                                    if (err.data.error) {
                                        openNotificationWithIcon("error", err.data.error);
                                    }
                                });
                        }
                    })
                    .catch((err) => {
                        if (err.data.error) {
                            openNotificationWithIcon("error", err.data.error);
                        }
                    });
            }
        }
    };

    const onFinishFailed = (errorInfo) => {
        openNotificationWithIcon("error", "Lỗi");
    };

    const onCloseModal = () => {
        form.resetFields();
        onCancel();
    };

    const disabledDate = (current) => {
        return current && current > moment().endOf("day");
    };

    const onChangeFields = (event, field, intChoose) => {
        let valueTemp = event.target.value;
        switch (intChoose) {
            case 1:
                if (
                    event.target.value.charAt(event.target.value.length - 1) === "." ||
                    event.target.value === "-"
                ) {
                    valueTemp = event.target.value.slice(0, -1);
                }
                let newValue = toSlug(event.target.value);
                form.setFieldsValue({
                    [field]: newValue,
                    // username: valueTemp.replace(/\s+/, ''),
                });
                break;
            case 2:
                break;
            default:
            // code block
        }
    };

    return (
        <Modal
            title="Thêm Team mới"
            visible={open}
            onCancel={onCloseModal}
            className="modalAddUser"
            width={screen.width > 768 ? 800 : "80%"}
            footer={false}
            bodyStyle={
                screen.width > 768
                    ? null
                    : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
                loading={loading}
                initialValues={{
                    remember: true,
                }}
                key="form_team"
            >
                {screen.width > 768 ? (
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                name="name"
                                label="Tên team"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: new RegExp("^[a-zA-Z0-9$&+=?@#|<>.^*()%!-_ ]*$"),
                                        message:
                                            "Không được nhập khoảng cách hoặc tiếng Việt có dấu",
                                    },
                                ]}
                                key="name_team"
                            >
                                <Input placeholder="Nhập tên team" maxLength={100}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={2}>
                            <Form.Item
                                name="bo_phan"
                                label="Bộ phận"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: new RegExp(
                                            "^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]*$"
                                        ),
                                        message:
                                            "Không được nhập khoảng cách hoặc tiếng Việt có dấu",
                                    },
                                ]}
                                key="bo_phan"
                            >
                                <Input.Group compact key="add_team">
                                    <AutoComplete
                                        style={{
                                            width: "100%",
                                        }}
                                        placeholder="Nhập hoặc chọn tên bộ phận"
                                        onChange={(value) => {
                                            form.setFieldsValue({ bo_phan: value });
                                        }}
                                        key="auto"

                                    >
                                        {boPhan.map((item) => (
                                            <AutoComplete.Option key={item.id} value={item.name}>
                                                {item.name}
                                            </AutoComplete.Option>
                                        ))}
                                    </AutoComplete>
                                </Input.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Tên team"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: new RegExp(
                                            "^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]*$"
                                        ),
                                        message:
                                            "Không được nhập khoảng cách hoặc tiếng Việt có dấu",
                                    },
                                ]}
                                key="name_team"
                            >
                                <Input placeholder="Nhập tên team" maxLength={100}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="bo_phan"
                                label="Bộ phận"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: new RegExp(
                                            "^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]*$"
                                        ),
                                        message:
                                            "Không được nhập khoảng cách hoặc tiếng Việt có dấu",
                                    },
                                ]}
                                key="bo_phan"
                            >
                                <Input.Group compact key="add_team">
                                    <AutoComplete
                                        style={{
                                            width: "100%",
                                        }}
                                        placeholder="Nhập hoặc chọn tên bộ phận"
                                        onChange={(value) => {
                                            form.setFieldsValue({ bo_phan: value });
                                        }}
                                        key="auto"
                                    >
                                        {boPhan.map((item) => (
                                            <AutoComplete.Option key={item.id} value={item.name}>
                                                {item.name}
                                            </AutoComplete.Option>
                                        ))}
                                    </AutoComplete>
                                </Input.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Form.Item
                    style={{ marginTop: "20px", textAlign: "center" }}
                    key="btn_team"
                >
                    <LoadingButton
                        type="submit"
                        className={"m-2"}
                        style={{ marginRight: "20px" }}
                        variant="contained"
                        loading={loadingAddNew}
                    >
                        Đăng ký
                    </LoadingButton>
                    <Button
                        onClick={onCloseModal}
                        color="error"
                        variant="contained"
                        disabled={loadingAddNew}
                    >
                        Hủy
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAddTeam
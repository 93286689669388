import {localhost} from "./server";
const apiURL = "/api";

export const endpoint = `${localhost}${apiURL}`;
// export const endpoints = `${localhost}${apiURLS}`;

export const loginURL = `${endpoint}/token/`;
export const authURL = `${endpoint}/verify_token_login/`;
// export const authURL = `${endpoint}/token/verify/`;
export const token_refresh_URL = `${endpoint}/token/refresh/`;
export const logoutURL = `${endpoint}/logout/`;

export const userInfoURL = `${endpoint}/infor-user/`;
export const userEditURL =  id => `${endpoint}/infor-user-detail/${id}`;
export const listChucVuURL = `${endpoint}/group-role/`;
export const lockUserURL =  id => `${endpoint}/lock-user/${id}`;

export const manageUploadURL = `${endpoint}/manage-upload/`;

export const manageUploadAll = `${endpoint}/document-all/`;
export const getManageUploadAll = `${endpoint}/get-document-all/`;

export const manageUploadPersonal = `${endpoint}/document-user/`;
export const managetypeDocument = `${endpoint}/type-document/`;
export const deleteFileDocument = `${endpoint}/delete_file_document/`;


// export const exportFormTemplate = id => `${endpoint}/export-form-template/${id}`;
export const exportFormTemplate = `${endpoint}/export-form-template/`;

export const manageBoPhan = `${endpoint}/manage-bo-phan/`;
export const manageCustomer = `${endpoint}/manage-customer/`;
export const manageTeam = `${endpoint}/manage-team/`;
export const manageUser = `${endpoint}/manage-user/`;
export const documentMail = `${endpoint}/document-mail/`;
export const totalContractExpire = `${endpoint}/total-contract-expire/`;
export const totalContractYear = id => `${endpoint}/total-contract-year/${id}`;
export const manageDetailUpload = year => `${endpoint}/detail-upload/${year}`;
export const manageRole = `${endpoint}/role/`;
export const manageDetailUser = id => `${endpoint}/infor-user-detail/${id}`;
export const manageDetailCustomer = id => `${endpoint}/infor-customer/${id}`;
export const manageDetailTeam = id => `${endpoint}/infor-team/${id}`;
export const manageDetailDepartment = id => `${endpoint}/infor-bo-phan/${id}`;
export const manageDetailTypeDocument = id => `${endpoint}/detail-type-document/${id}`;

export const downloadFile = id => `${endpoint}/download-document/${id}`;

export const exportCustomer = `${endpoint}/export_customer/`;
export const exportUser = `${endpoint}/export_user/`;
export const exportDocument = `${endpoint}/export_document_user/`;

export const importCustomer = `${endpoint}/import_excel_customer/`;
export const importContract = `${endpoint}/import_excel_document/`;

export const templateUser = `${endpoint}/download_temp_excel_user/`;
export const templateCustomer = `${endpoint}/download_temp_excel_customer/`;
export const templateContract = `${endpoint}/download_temp_excel_document/`;

export const logContract = `${endpoint}/document_history/`;

export const notificationAll = `${endpoint}/list_notification/`;

export const viewNotification = `${endpoint}/notification_is_view/`;

export const changePassword = `${endpoint}/change-password/`;

export const manageLegalEntity = `${endpoint}/legal-entity-signed/`;
export const manageDetailLegalEntity = id => `${endpoint}/detail-legal-entity-signed/${id}`;
export const ruleTypeDocument = `${endpoint}/rule-type-document/`;

export const ruleTypeDocumentAll = `${endpoint}/rule-type-document-all/`;

export const typeDocumentAll = `${endpoint}/type-document-all/`;

export const downloadTypeDocument = `${endpoint}/download-type-document/`
export const importDocument = `${endpoint}/import_type_document/`

export const formTemplate = `${endpoint}/form-template/`

export const legalEntityAll = `${endpoint}/legal-entity-signed-all/`

export const exportContractYear = `${endpoint}/export-contract-year/`



















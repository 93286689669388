import React, { useEffect, useState } from "react";
import {
  Row,
  Table,
} from "antd";
import {
  errorHandle,
} from "../../../Function";
import {
  GetDocumentMail,
} from "../../../../api/usersApi";
import moment from "moment";
import "./QuanLyMail.css";

function QuanLyMail() {
  const [pageSize, setPageSize] = useState(10);
  const [pager, setPager] = useState({
    pageSize: pageSize,
    count: 0,
    current: 1,
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const fetchData = (params = {}) => {
    setLoading(true);
    GetDocumentMail(params)
      .then((res) => {
        setData(res.data.results);
        setPager({
          current: params.page,
          pageSize: params.page_size,
          count: res.data.count,

        });
        setLoading(false);
      })
      .catch((err) => {
        errorHandle(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData({ page: pager.current, page_size: pager.pageSize, search });
  }, []);

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
      width: "5%"
    },
    {
      title: "Số hiệu",
      dataIndex: "code_number",
      align: "left",
      key: "code_number",
      ellipsis: true,
      render: (value, item, index) => value,
    },
    {
      title: "Loại mail",
      key: "type_mail",
      align: "center",
      dataIndex: "type_mail",
      ellipsis: true,
    },

    {
      title: "Ngày gửi",
      key: "date_send",
      align: "center",
      dataIndex: "date_send",
      ellipsis: true,
      render: (value, index, item) => moment(value).format("DD-MM-YYYY"),
    },
  ];

  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = pagination.pageSize;
    setPager({ ...currentPager });
    fetchData({
      status: currentPager.status,
      page_size: pagination.pageSize,
      page: pagination.current,
      search,
    });
  };

  const expandedRowRender = (record) => {
    const columnsReport = [
      {
        title: "STT",
        dataIndex: "id",
        key: "id",
        align: "center",
        width: "10%",
        render: (value, item, index) =>
          index + 1
      },

      {
        title: "Email nhận",
        key: "email",
        align: "center",
        dataIndex: "email",
        ellipsis: true,
        render: (value) => value,
      },
      {
        title: "Trạng thái gửi",
        key: "status_send",
        align: "center",
        dataIndex: "status_send",
        ellipsis: true,
        render: (value) =>
          value === "Thành công" ? (
            <span style={{ color: "green" }}>{value}</span>
          ) : (
            <span style={{ color: "red" }}>{value}</span>
          )
      },
      {
        title: "Ghi chú",
        key: "note",
        align: "center",
        dataIndex: "note",
        ellipsis: true,
        render: (value) => value,
      }
    ];
    const dataReport = record.user_receiver;

    return (
      <Table
        columns={columnsReport}
        dataSource={dataReport}
        // onChange={handleChange}
        pagination={false}
        style={{ borderLeft: '1px solid gray' }}
        locale={{ emptyText: "Không có dữ liệu" }}
      />
    );
  };

  return (
    <div className="FormHomeTable">
      <Row>
        <Table
          columns={columns}
          rowKey="id"
          rowClassName={"styleMail"}
          loading={loading}
          expandable={{
            expandedRowRender,
            defaultExpandedRowKeys: ["0"],
          }}
          dataSource={data}
          scroll={{
            y: "70vh",
            // x: 1000
          }}

          // style={{ width: "100%", position: "absolute" }}
          onChange={handleChange}
          locale={{ emptyText: "Không có dữ liệu" }}
          pagination={{
            current: pager.current,
            pageSize: pager.pageSize,
            // showSizeChanger: true,
            // showQuickJumper: true,
            total: pager.count,
            // pageSizeOptions: ["10", "15", "25", "50"],
            // locale: {
            //   jump_to: "Đến trang: ",
            //   page: ""
            // },
            showSizeChanger: true,
          }}
          size="middle"
        />
      </Row>
    </div>
  );
}

export default QuanLyMail;

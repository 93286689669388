import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/vi";
import "antd/dist/antd.css";
import {
  Avatar,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Layout,
  List,
  Menu,
  Modal,
  Popover,
  Row,
  Select,
  Table,
  Tabs,
  Tag,
} from "antd";
import { Link } from "react-router-dom";
import {
  UserOutlined,
  CalendarFilled,
  FolderOpenOutlined,
  FileOutlined,
  UsergroupAddOutlined,
  MailOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  FileTextOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import { Button } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import BarChartIcon from "@mui/icons-material/BarChart";
import LogoutIcon from "@mui/icons-material/Logout";
import Cookies from "universal-cookie";
import { authAxios } from "../utils";
import { logout } from "../app/Actions/auth";
import { logoutURL } from "../constants";
import "./Layout.css";
import { useSelector } from "react-redux";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  NotificationAll,
  ViewNotification,
  ChangePassword,
} from "../api/usersApi";
import { openNotificationWithIcon } from "./Function";
import { useStepsForm } from "sunflower-antd";
import { localhost } from "../server";
import { LoadingButton } from "@mui/lab";
import { GetInforUpload, LogContract } from "../api/manageContract";
import Logo from "./LogoRainScaleLogin.svg"
// import CaNhan from "./component/qlyHoSo/caNhan/CaNhan";
// import Item from "antd/lib/list/Item";
const { SubMenu } = Menu;

moment.locale("vi");
const cookies = new Cookies();
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const dateFormat = "DD-MM-YYYY";
const { Option } = Select;

const ModalDetailContract = ({ open, onCancel, dataDetail, legalEntity }) => {
  // const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const BoxNewSchedule_pro = () => {
    const {
      form,
      current,
      gotoStep,
      stepsProps,
      formProps,
      submit,
      formLoading,
    } = useStepsForm({
      async submit(values) {
        await new Promise((r) => setTimeout(r, 500));
        return "ok";
      },
      total: 2,
      isBackValidate: false,
    });

    var arrUserUpdate = dataDetail.user_update.map(
      (item, index) => dataDetail.user_update[index].username
    );
    var arrTagTeam = dataDetail.tag_team.map(
      (item, index) => dataDetail.tag_team[index].name
    );

    var arrTagLegalEntity = dataDetail.legal_entity_signed.map(
      (item, index) => dataDetail.legal_entity_signed[index].code_signer
    );

    useEffect(() => {
      form.resetFields();
      if (open) {

        form.setFieldsValue({
          customer: dataDetail.customer[0].full_name,
          auto_extend: dataDetail.auto_extend,
          content: dataDetail.content,
          day_expired: dataDetail.day_expired,
          day_sign: dataDetail.day_sign,
          tag_team: arrTagTeam,
          type_document: dataDetail.type_document.length > 0 ? dataDetail.type_document[0].name : undefined,
          rule_type_document: dataDetail.rule_type_document.length > 0 ? dataDetail.rule_type_document[0].name : undefined,
          user_create: dataDetail.user_create[0].username,
          user_update: arrUserUpdate,
          project: dataDetail.project,
          code_number: dataDetail.code_number,
          legal_entity_signed: arrTagLegalEntity,
          file_document: dataDetail.file_document.map((item, index) => {
            return item.name_file;
          }),
        });
      }
    }, [open]);

    const FirstStep = () => {
      console.log(dataDetail)

      const formatDate = (current) => {
        return moment(current).format(dateFormat);
      };

      return (
        <>
          {screen.width > 768 ? (
            <Row className="RowThuNhan" key={1}>
              <Col span={12}>
                <Form.Item
                  name="customer"
                  label="Tên khách hàng"
                  rules={[{ required: true }]}
                  key="customer"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="user_create"
                  label="Người tạo"
                  rules={[{ required: true }]}
                  key="user_create"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Row>
                  <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginLeft: "16.7%" }}>
                    <Col span={12}>
                      <Form.Item
                        name="rule_type_document"
                        label="Tài liệu"
                        rules={[{ required: true, message: "Không được để trống" }]}
                        key="rule_type_document"
                        style={{ width: "100%" }}
                      >
                        <Input readOnly maxLength={100} style={{ width: "95%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ width: "400px" }}>
                      <Form.Item
                        name="type_document"
                        key="type_document"
                        style={{ width: "105%" }}
                      // style={{ marginLeft: 35 }}
                      >
                        <Input readOnly maxLength={100} />
                      </Form.Item>
                    </Col>
                  </div>

                </Row>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="project"
                  label="Tên dự án"
                  key="project"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="tag_team" label="Team" key="tag_team" rules={[{ required: true }]}>
                  <Select
                    placeholder="Chọn người được chia sẻ"
                    showSearch={false}
                    optionFilterProp="children"
                    mode="multiple"
                    maxTagCount="responsive"
                    open={false}
                    disabled
                    key="tag_team1"
                  >
                    {dataDetail.tag_team.map((item, index) => (
                      <Option key={item.name} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

              </Col>
              <Col span={12}>
                <Form.Item
                  name="code_number"
                  label="Số hiệu"
                  key="code_number"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="user_update"
                  label="Người được chia sẻ"
                  key="user_update"
                >
                  <Select
                    showSearch={false}
                    optionFilterProp="children"
                    mode="multiple"
                    maxTagCount="responsive"
                    open={false}
                    disabled
                    key="user_update1"
                  >
                    {dataDetail.user_update.map((item, index) => (
                      <Option key={item.username} value={item.id}>
                        {item.username}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="auto_extend"
                  label="Tự động tái tục"
                  key="auto_extend"
                >
                  <Checkbox checked={dataDetail.auto_extend}></Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="day_sign"
                  label="Ngày kí kết"
                  rules={[{ required: true }]}
                  key="day_sign"
                >
                  <DatePicker
                    disabled
                    style={{ width: "100%" }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="day_expired"
                  label="Ngày hết hạn"
                  key="day_expired"
                >
                  <DatePicker
                    disabled
                    style={{ width: "100%" }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="content" label="Nội dung" key="content" rules={[{ required: true }]}>
                  <Input.TextArea readOnly></Input.TextArea>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="legal_entity_signed"
                  label="Pháp nhân ký"
                  key="legal_entity_signed"
                >
                  <Select
                    showSearch={false}
                    optionFilterProp="children"
                    maxTagCount="responsive"
                    open={false}
                    aria-readonly
                    menuItemSelectedIcon={false}
                    suffixIcon={false}
                    key="legal_entity_signed"
                    mode="multiple"
                    disabled
                  >
                    {dataDetail.legal_entity_signed.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.code_signer}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

              </Col>

              <Col span={12}>
                <Form.Item
                  name="file_document"
                  label="File Upload"
                  key="file_document"
                >
                  <div style={{ overflowY: "auto", height: 100 }}>
                    {dataDetail.file_document.map((item, index) => (
                      <Row
                        style={{ marginBottom: "5px", width: "100%" }}
                        key="detail"
                      >
                        <Col>
                          <Tag
                            style={{ fontSize: "12px" }}
                            color="blue"
                            value={item.name_file}
                            key={item.name_file}
                            className="detail-contract"
                          >
                            <a
                              href={localhost + "/media/" + item.file_contract}
                              // href={"/media/" + item.file_contract}
                              target="_blank"
                            >
                              {item.name_file}
                            </a>
                          </Tag>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Form.Item>
              </Col>

            </Row>
          ) : (
            <Row className="RowThuNhan" key={1}>
              <Col span={24}>
                <Form.Item
                  name="customer"
                  label="Tên khách hàng"
                  rules={[{ required: true }]}
                  key="customer"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="user_create"
                  label="Người tạo"
                  rules={[{ required: true }]}
                  key="user_create"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="type_document"
                  label="Phân loại tài liệu"
                  rules={[{ required: true }]}
                  key="type_document"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="project"
                  label="Tên dự án"
                  rules={[{ required: true }]}
                  key="project"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="user_update"
                  label="Người được chia sẻ"
                  rules={[{ required: true }]}
                  key="user_update"
                >
                  <Select
                    placeholder="Chọn người được chia sẻ"
                    showSearch={false}
                    optionFilterProp="children"
                    mode="multiple"
                    maxTagCount="responsive"
                    open={false}
                    disabled
                    key="user_update1"
                  >
                    {dataDetail.user_update.map((item, index) => (
                      <Option key={item.username} value={item.id}>
                        {item.username}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="code_number"
                  label="Số hiệu"
                  rules={[{ required: true }]}
                  key="code_number"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="tag_team" label="Team" key="tag_team">
                  <Select
                    placeholder="Chọn người được chia sẻ"
                    showSearch={false}
                    optionFilterProp="children"
                    mode="multiple"
                    maxTagCount="responsive"
                    open={false}
                    disabled
                    key="tag_team1"
                  >
                    {dataDetail.tag_team.map((item, index) => (
                      <Option key={item.name} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="auto_extend"
                  label="Tự động tái tục"
                  key="auto_extend"
                >
                  <Checkbox checked={dataDetail.auto_extend}></Checkbox>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="day_sign"
                  label="Ngày kí kết"
                  rules={[{ required: true }]}
                  key="day_sign"
                >
                  <DatePicker
                    disabled
                    style={{ width: "100%" }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="day_expired"
                  label="Ngày hết hạn"
                  key="day_expired"
                >
                  <DatePicker
                    disabled
                    style={{ width: "100%" }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="content" label="Nội dung" key="content">
                  <Input.TextArea readOnly></Input.TextArea>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="file_document"
                  label="File Upload"
                  key="file_document"
                >
                  <div style={{ overflowY: "auto", height: 100 }}>
                    {dataDetail.file_document.map((item, index) => (
                      <Row
                        style={{ marginBottom: "5px", width: "100%" }}
                        key="file"
                      >
                        <Col>
                          <Tag
                            style={{ fontSize: "12px" }}
                            color="blue"
                            value={item.name_file}
                            key={item.name_file}
                          >
                            <a
                              href={localhost + "/media/" + item.file_contract}
                              // href={"/media/" + item.file_contract}
                              target="_blank"
                            >
                              {item.name_file}
                            </a>
                          </Tag>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      );
    };

    const formList = [<FirstStep />];

    return (
      <Form {...layout} {...formProps} style={{ width: "100%" }} key="form">
        {formList[current]}
      </Form>
    );
  };

  return (
    <div>
      <BoxNewSchedule_pro />
    </div>
  );
};

const LogDocument = ({ dataLog, idLogContract }) => {
  const [pageSize, setPageSize] = useState(10);
  const [pager, setPager] = useState({
    pageSize: pageSize,
    count: 0,
    current: 1,
  });
  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
      width: "3%",
    },

    {
      title: "Nội dung thao tác",
      key: "content",
      align: "center",
      dataIndex: "content",
      ellipsis: true,
      width: "15%",
      render: (text, record, index) => record.content,
    },

    {
      title: "Thời gian thao tác",
      key: "updated",
      align: "center",
      dataIndex: "updated",
      ellipsis: true,
      width: "15%",
      render: (text, record, index) =>
        moment(record.updated).format("DD-MM-YYYY, hh:mm:ss"),
    },
    {
      title: "User thao tác",
      key: "user_log",
      align: "center",
      dataIndex: "user_log",
      ellipsis: true,
      width: "6%",
      render: (text, record, index) => record.user_log[0].username,
    },
  ];

  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = pagination.pageSize;
    setPager({ ...currentPager });
  };

  useEffect(() => {
    setPager({
      pageSize: pageSize,
      count: 0,
      current: 1,
    });
  }, [idLogContract]);

  useEffect(() => {
    setPager({
      pageSize: pageSize,
      count: 0,
      current: 1,
    });
  }, [dataLog]);

  return (
    <Table
      columns={columns}
      dataSource={dataLog}
      onChange={handleChange}
      rowClassName={"styleTableLeTet"}
      locale={{ emptyText: "Không có dữ liệu" }}
      pagination={{
        current: pager.current,
        pageSize: pageSize,
        // showSizeChanger: true,
        // showQuickJumper: true,
        // pageSizeOptions: ["10", "15", "25", "50"],
        // locale: {
        //   jump_to: "Đến trang: ",
        //   page: "",
        // },
      }}
      size="middle"
    />
  );
};

const ModalTable = ({
  isDetail,
  setIsDetail,
  dataDetail,
  dataLog,
  keyTab,
  setKeyTab,
  idLogContract,
}) => {
  useEffect(() => {
    setKeyTab("1");
  }, [isDetail]);

  const onKeyChange = (key) => setKeyTab(key);
  return (
    <Tabs
      defaultActiveKey="1"
      key="def"
      onChange={onKeyChange}
      activeKey={keyTab}
    >
      <Tabs.TabPane tab="Chi tiết hồ sơ" key="1">
        <ModalDetailContract
          open={isDetail}
          onCancel={() => setIsDetail(false)}
          dataDetail={dataDetail}
        // pager={pager}
        // lsRole={lsRole}
        // loading={loading}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Lịch sử thao tác" key="2">
        <LogDocument dataLog={dataLog} idLogContract={idLogContract} />
      </Tabs.TabPane>
    </Tabs>
  );
};

const ListNotification = ({ data, NotificationOfContract }) => {
  const [isDetail, setIsDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState();
  const [isModalTable, setIsModalTable] = useState(false);
  const onShowModalDetail = (record) => {
    GetInforUpload(record.document_rel[0].id)
      .then((res) => {
        setDataDetail(res.data);
        setIsDetail(true);
        setIsModalTable(true);
      })
      .catch((err) => {
        if (err) {
          openNotificationWithIcon("error", err);
        }
      });
  };

  const [dataLog, setDataLog] = useState([]);
  const onLogContract = (record) => {
    LogContract({ id_document_log: record.document_rel[0].id })
      .then((res) => {
        setDataLog(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isViewNotification = (record) => {
    ViewNotification({ notification_id: record.id })
      .then((res) => {
        NotificationOfContract();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [dropDown, setDropDown] = useState(false);

  const [idLogContract, setIdLogContract] = useState();

  const [keyTab, setKeyTab] = useState("1");

  const onCloseModalTable = () => {
    setKeyTab("1");
    setIsModalTable(false);
    setDropDown(false);
  };
  return (
    <>
      {dropDown === false ? (
        <List
          className="custom-list-notification"
          bordered
          size="small"
          dataSource={data}
          style={{ width: 350 }}
          renderItem={(item, index) => (
            <Row style={{ height: 50 }}>
              <Col span={1}></Col>
              <Col span={5} className="custom-date">
                <span className="text-date">
                  {moment(item.created).format("DD/MM/YYYY")}
                </span>
              </Col>
              <Col span={17} className="custom-row">
                <List.Item>
                  <a
                    onClick={(e) => {
                      onShowModalDetail(item);
                      onLogContract(item);
                      setDropDown(true);
                      setIdLogContract(item.id);
                      isViewNotification(item);
                    }}
                    style={{ fontSize: 12 }}
                  >
                    {/* <Typography.Text style={{color: "#1976d2"}}> */}
                    {item.user[0].username} đã {item.content}
                    <br />
                    Mã số: {item.document_rel[0].id}_
                    {item.document_rel[0].code_number}
                    {/* </Typography.Text> */}
                  </a>
                </List.Item>
              </Col>
              <Col sm={1} style={{ display: "flex" }}>
                {item.is_view === false ? (
                  <FiberManualRecordIcon
                    style={{ fontSize: "11px" }}
                    className="fiber-icon"
                  />
                ) : null}
              </Col>
            </Row>
          )}
        />
      ) : null}

      <Modal
        visible={isModalTable}
        onCancel={onCloseModalTable}
        footer={false}
        width={screen.width > 768 ? 1000 : "80%"}
      >
        <ModalTable
          isDetail={isDetail}
          dataDetail={dataDetail}
          setIsDetail={setIsDetail}
          dataLog={dataLog}
          keyTab={keyTab}
          setKeyTab={setKeyTab}
          idLogContract={idLogContract}
        />
      </Modal>
    </>
  );
};

const CustomLayoutFC = ({ children }) => {
  // const [collapsed, setCollapsed] = useState(screen.width > 768 ? false : true);
  const [collapsed, setCollapsed] = useState(false);
  const [collapsedMobile, setCollapsedMobile] = useState(true);
  const [current, setCurrent] = useState("");
  const [isOpenChangePassword, setIsOpenChangePassword] = useState(false);

  const userInfo = useSelector((state) => state.getUserInfo.userInfo);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const toggleCollapsedMobile = () => {
    setCollapsedMobile(!collapsedMobile);
  };

  useEffect(() => {
    if (location.href.endsWith("/manage_mail")) {
      setCurrent("6");
    }
    if (location.href.endsWith("/manage_customer")) {
      setCurrent("3");
    }
    if (location.href.endsWith("/")) {
      setCurrent("2");
    }
    if (location.href.endsWith("/manage_user")) {
      setCurrent("1");
    }
    if (location.href.endsWith("/statistic")) {
      setCurrent("4");
    }
    if (location.href.endsWith("/type_document")) {
      setCurrent("7");
    }
    if (location.href.endsWith("/form")) {
      setCurrent("5");
    }
    if (location.href.endsWith("/legal_entity_signatory")) {
      setCurrent("8");
    }
  }, [location.href]);

  const onClick = (e) => {
    setCurrent(e.key);
    setCollapsedMobile(!collapsedMobile);
  };

  const deleteToken = (e) => {
    authAxios()
      .get(logoutURL)
      .then((res) => {
        cookies.remove("token_contract_v2");
        cookies.remove("refresh_contract_v2");
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + "; domain=.rainscales.com.vn; path=/");
        });
        document.cookie = 'token_contract_v2=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
        document.cookie = 'refresh_contract_v2=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

        window.location = "/login";
      });
    logout();
  };

  const itemsNhanVien = [
    getItem("Quản lý hồ sơ", "qly_ho_so", <CalendarFilled />, [
      getItem("Lưu trữ", "luu_tru", <CalendarFilled />, [
        getItem(
          "Tài liệu",
          "2",
          <Link to="/">
            <FileOutlined />
          </Link>
        ),
      ]),
    ]),
  ];

  const itemsAdmin = [
    getItem("Quản lý hồ sơ", "qly_ho_so", <CalendarFilled />, [
      getItem("Lưu trữ", "luu_tru", <CalendarFilled />, [
        getItem(
          "Tài liệu",
          "2",
          <Link to="/">
            <FileOutlined />
          </Link>
        ),
        getItem(
          "Biểu mẫu",
          "5",
          <Link to="/form">
            <FolderOpenOutlined />
          </Link>
        ),
      ]),

      getItem("Khai báo", "khai_bao", <CalendarFilled />, [
        getItem(
          "Tài liệu",
          "7",
          <Link to="/type_document">
            <FolderOpenOutlined />
          </Link>
        ),
        getItem(
          "Hồ sơ khách hàng",
          "3",
          <Link to="/manage_customer">
            <UsergroupAddOutlined />
          </Link>
        ),
        getItem(
          "Pháp nhân ký",
          "8",
          <Link to="/legal_entity_signatory">
            <UsergroupAddOutlined />
          </Link>
        ),
      ]),
    ]),

    getItem("Quản lý hệ thống", "khai_bao", <CalendarFilled />, [
      getItem(
        "Tài khoản",
        "1",
        <Link to="/manage_user">
          <UserOutlined />
        </Link>
      ),
      getItem(
        "Thông báo",
        "6",
        <Link to="/manage_mail">
          <MailOutlined />
        </Link>
      ),
    ]),

    getItem(
      "Thống kê",
      "4",
      <Link to="/statistic">
        <BarChartIcon />
      </Link>
    ),
  ];

  const itemsManageAdmin = [
    getItem("Quản lý hồ sơ", "qly_ho_so", <CalendarFilled />, [
      getItem("Lưu trữ", "luu_tru", <CalendarFilled />, [
        getItem(
          "Tài liệu",
          "2",
          <Link to="/">
            <FileOutlined />
          </Link>
        ),
        getItem(
          "Biểu mẫu",
          "5",
          <Link to="/form">
            <FolderOpenOutlined />
          </Link>
        ),
      ]),

      getItem("Khai báo", "khai_bao", <CalendarFilled />, [
        getItem(
          "Tài liệu",
          "7",
          <Link to="/type_document">
            <FolderOpenOutlined />
          </Link>
        ),
        getItem(
          "Hồ sơ khách hàng",
          "3",
          <Link to="/manage_customer">
            <UsergroupAddOutlined />
          </Link>
        ),
        getItem(
          "Pháp nhân ký",
          "8",
          <Link to="/legal_entity_signatory">
            <UsergroupAddOutlined />
          </Link>
        ),
      ]),
    ]),

    getItem("Quản lý hệ thống", "khai_bao", <CalendarFilled />, [
      getItem(
        "Tài khoản",
        "1",
        <Link to="/manage_user">
          <UserOutlined />
        </Link>
      ),
      getItem(
        "Thông báo",
        "6",
        <Link to="/manage_mail">
          <MailOutlined />
        </Link>
      ),
    ]),

    getItem(
      "Thống kê",
      "4",
      <Link to="/statistic">
        <BarChartIcon />
      </Link>
    ),
  ];

  const content = (
    <div>
      <h3 style={{ textAlign: "center" }}>{userInfo.username}</h3>
      <hr></hr>
      <Button className="logOut" onClick={(e) => deleteToken()}>
        Đăng xuất
      </Button>
    </div>
  );

  const [data, setData] = useState([]);

  const NotificationOfContract = () => {
    NotificationAll()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    NotificationOfContract();
  }, []);

  const onShowModalChangePassword = () => {
    setIsOpenChangePassword(true);
  };

  const menu = (
    <Menu className="custom-menu">
      <Menu.Item style={{ textAlign: "center" }} key="1">
        {userInfo.username}
      </Menu.Item>
      <hr></hr>
      <Menu.Item
        style={{ color: "darkcyan" }}
        onClick={onShowModalChangePassword}
        key="3"
      >
        <KeyOutlined style={{ fontSize: 13 }} /> Đổi mật khẩu
      </Menu.Item>
      <Menu.Item
        onClick={(e) => deleteToken()}
        style={{ color: "red" }}
        key="2"
      >
        <LogoutIcon style={{ fontSize: 13 }} /> Đăng xuất{" "}
      </Menu.Item>
    </Menu>
  );

  const backToHomePage = () => {
    window.location.href = "https://homepage.rainscales.com.vn/"
  }

  return (
    <>
      {screen.width > 768 ? (
        <>
          <Layout
            style={{
              minHeight: "100vh",
            }}
          >
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                background: "#fff",
              }}
            >
              <Row>
                <Col
                  span={3}
                  style={{ marginLeft: 30 }}
                  className="logoSize"
                >
                  <img src={Logo} style={{ cursor: "pointer" }} onClick={backToHomePage} alt=""></img>
                </Col>
                <Col span={15}></Col>
                <Col span={5}>
                  <Row style={{ marginTop: "5%" }}>
                    <Col span={16}></Col>
                    <Col span={3}>
                      {userInfo.role_name === "Nhân viên" ? null : (
                        <>
                          <Dropdown
                            overlay={
                              <ListNotification
                                data={data}
                                NotificationOfContract={NotificationOfContract}
                              />
                            }
                            placement="bottom"
                            trigger={["click"]}
                          >
                            <NotificationsActiveOutlinedIcon
                              onClick={NotificationOfContract}
                              style={{ fontSize: 34, cursor: "pointer" }}
                            />
                          </Dropdown>
                          <span
                            className="badge"
                            style={{ color: "red" }}
                            value={data.length > 10 ? "10+" : data.length}
                          ></span>
                        </>
                      )}
                    </Col>
                    <Col span={2}></Col>
                    <Col span={3}>
                      <Dropdown overlay={menu} placement="bottomRight">
                        <Avatar size={36} icon={<UserOutlined />} />
                      </Dropdown>
                    </Col>
                    {/* <Col span={4}>
                      <Tooltip title="Đăng xuất" placement="top">
                        <Button
                          style={{ border: "none" }}
                          onClick={(e) => deleteToken()}
                        >
                          <LogoutIcon />
                        </Button>
                      </Tooltip>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
            </Header>
            <Layout className="site-layout">
              <Sider
                // trigger={null}
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                className={collapsed === false ? "SiderLayout" : null}
              >
                <Menu
                  theme="dark"
                  mode="inline"
                  selectedKeys={[current]}
                  onClick={onClick}
                  defaultOpenKeys={["qly_ho_so", "luu_tru", "khai_bao", "qly_he_thong"]}
                // openKeys={[current]}
                // onSelect={handleClick}
                // onOpenChange={onOpenChange}
                >
                  {userInfo.role_name === "Nhân viên" ? (
                    <SubMenu
                      key="qly_ho_so"
                      icon={<CalendarFilled />}
                      title="Quản lý hồ sơ"
                    >
                      <SubMenu
                        key="luu_tru"
                        icon={<CalendarFilled />}
                        title="Lưu trữ"
                      >
                        <Menu.Item key="2" icon={<FileOutlined />}>
                          <Link to="/">Tài liệu</Link>
                        </Menu.Item>
                      </SubMenu>
                    </SubMenu>
                  ) : null}

                  {userInfo.role_name === "Admin" ? (
                    <>
                      <Menu.Item key="4" icon={<BarChartIcon />}>
                        <Link to="/statistic">Thống kê</Link>
                      </Menu.Item>
                      <SubMenu
                        key="qly_ho_so"
                        icon={<CalendarFilled />}
                        title="Quản lý hồ sơ"
                      >


                        <SubMenu
                          key="khai_bao"
                          icon={<CalendarFilled />}
                          title="Khai báo"
                        >
                          <Menu.Item key="7" icon={<FileTextOutlined />}>
                            <Link to="/type_document">Danh mục tài liệu</Link>
                          </Menu.Item>
                          <Menu.Item key="3" icon={<UsergroupAddOutlined />}>
                            <Link to="/manage_customer">Hồ sơ khách hàng</Link>
                          </Menu.Item>
                          <Menu.Item key="8" icon={<FileTextOutlined />}>
                            <Link to="/legal_entity_signatory">Pháp nhân ký</Link>
                          </Menu.Item>
                        </SubMenu>
                        <SubMenu
                          key="luu_tru"
                          icon={<CalendarFilled />}
                          title="Lưu trữ"
                        >
                          <Menu.Item key="2" icon={<FileOutlined />}>
                            <Link to="/">Tài liệu</Link>
                          </Menu.Item>
                          <Menu.Item key="5" icon={<FolderOpenOutlined />}>
                            <Link to="/form">Biểu mẫu</Link>
                          </Menu.Item>
                        </SubMenu>
                      </SubMenu>

                      <SubMenu
                        key="qly_he_thong"
                        icon={<CalendarFilled />}
                        title="Quản lý hệ thống"
                      >
                        <Menu.Item key="1" icon={<UserOutlined />}>
                          <Link to="/manage_user">Tài khoản</Link>
                        </Menu.Item>

                        <Menu.Item key="6" icon={<MailOutlined />}>
                          <Link to="/manage_mail">Thông báo</Link>
                        </Menu.Item>
                      </SubMenu>
                    </>
                  ) : null}

                  {userInfo.role_name === "Manage_Admin" ? (
                    <>
                      <Menu.Item key="4" icon={<BarChartIcon />}>
                        <Link to="/statistic">Thống kê</Link>
                      </Menu.Item>
                      <SubMenu
                        key="qly_ho_so"
                        icon={<CalendarFilled />}
                        title="Quản lý hồ sơ"
                      >

                        <SubMenu
                          key="khai_bao"
                          icon={<CalendarFilled />}
                          title="Khai báo"
                        >
                          <Menu.Item key="7" icon={<FileTextOutlined />}>
                            <Link to="/type_document">Danh mục tài liệu</Link>
                          </Menu.Item>
                          <Menu.Item key="3" icon={<UsergroupAddOutlined />}>
                            <Link to="/manage_customer">Hồ sơ khách hàng</Link>
                          </Menu.Item>
                          <Menu.Item key="8" icon={<FileTextOutlined />}>
                            <Link to="/legal_entity_signatory">Pháp nhân ký</Link>
                          </Menu.Item>
                        </SubMenu>
                        <SubMenu
                          key="luu_tru"
                          icon={<CalendarFilled />}
                          title="Lưu trữ"
                        >
                          <Menu.Item key="2" icon={<FileOutlined />}>
                            <Link to="/">Tài liệu</Link>
                          </Menu.Item>
                          <Menu.Item key="5" icon={<FolderOpenOutlined />}>
                            <Link to="/form">Biểu mẫu</Link>
                          </Menu.Item>
                        </SubMenu>
                      </SubMenu>




                      <SubMenu
                        key="qly_he_thong"
                        icon={<CalendarFilled />}
                        title="Quản lý hệ thống"
                      >
                        <Menu.Item key="1" icon={<UserOutlined />}>
                          <Link to="/manage_user">Tài khoản</Link>
                        </Menu.Item>

                        <Menu.Item key="6" icon={<MailOutlined />}>
                          <Link to="/manage_mail">Thông báo</Link>
                        </Menu.Item>
                      </SubMenu>
                    </>
                  ) : null}
                </Menu>
              </Sider>
              <div className="ContentUser">
                <Content
                  style={{
                    // padding: "7px 16px 0px 16px",
                    padding: "0.5%",
                  }}
                >
                  <div className="main-content">
                    <div className="page-content">
                      <div
                        className="container-fluid"
                        style={{ maxWidth: "100%" }}
                      >
                        {children}
                      </div>
                    </div>
                  </div>
                </Content>
                <Footer
                  style={{
                    textAlign: "center",
                    height: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 5,
                  }}
                >
                  <p> Rainscales ©{new Date().getFullYear()} Made by DRI Team</p>
                </Footer>
              </div>
            </Layout>
          </Layout>
        </>
      ) : (
        // Màn hình điện thoại
        <Layout
          style={{
            minHeight: "100vh",
          }}
        >
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
              background: "#fff",
            }}
          >
            <div style={{ display: "flex" }}>
              <Button
                href="https://homepage.vbpo.vn/"
                style={{ width: "33%", border: "none" }}
              >
                <div style={{ width: "100%" }} className="logo"></div>
              </Button>
              <div className="col-2" style={{ marginLeft: "40%" }}>
                <Popover placement="bottom" content={content} trigger="click">
                  <Avatar size={36} icon={<UserOutlined />} />
                </Popover>
              </div>
              <div style={{ marginTop: 10 }}>
                <Button
                  onClick={toggleCollapsedMobile}
                  style={{
                    marginBottom: 16,
                    border: "none",
                  }}
                >
                  {collapsedMobile ? (
                    <MenuUnfoldOutlined style={{ fontSize: 30 }} />
                  ) : (
                    <MenuFoldOutlined style={{ fontSize: 30 }} />
                  )}
                </Button>
              </div>
            </div>
          </Header>
          <Layout className="site-layout">
            <div
              style={{
                width: "100%",
                position: "relative",
                zIndex: 99,
              }}
            >
              <Menu
                theme="dark"
                mode="inline"
                selectedKeys={[current]}
                onClick={onClick}
                defaultOpenKeys={["qly_ho_so"]}
                className="menuLayout"
                // openKeys={[current]}
                // onSelect={handleClick}
                // onOpenChange={onOpenChange}

                inlineCollapsed={collapsedMobile}
                items={
                  collapsedMobile
                    ? []
                    : userInfo.role_name === "Nhân viên"
                      ? itemsNhanVien
                      : userInfo.role_name === "Admin"
                        ? itemsAdmin
                        : itemsManageAdmin
                }
              />
            </div>
            <div className="ContentUser" style={{ position: "absolute" }}>
              <Content
                style={{
                  margin: "16px 16px 0px 16px ",
                }}
              >
                <div className="main-content">
                  <div className="page-content">
                    <div
                      className="container-fluid"
                      style={{ maxWidth: "100%" }}
                    >
                      {children}
                    </div>
                  </div>
                </div>
              </Content>
            </div>
          </Layout>
          <Footer
            style={{
              textAlign: "center",
              height: "20px",
            }}
          >
            Rainscales ©{new Date().getFullYear()} Made by DRI Team
          </Footer>
        </Layout>
      )}
      <ModalChangePassword
        isOpenChangePassword={isOpenChangePassword}
        setIsOpenChangePassword={setIsOpenChangePassword}
      />
    </>
  );
};

const ModalChangePassword = ({
  isOpenChangePassword,
  setIsOpenChangePassword,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const handleCancel = () => {
    setIsOpenChangePassword(false);
  };
  const onCloseModalChangePassword = () => {
    setIsOpenChangePassword(false);
  };

  useEffect(() => {
    form.resetFields();
    setValueOldPass("");
    setValueNewPass("");
    setValueCheckNewPass("");
  }, [isOpenChangePassword]);

  const onFinish = (values) => {
    ChangePassword({
      old_password: values.old_password,
      new_password: values.new_password,
    })
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Thành công",
          "Thay đổi mật khẩu thành công"
        );
        setIsOpenChangePassword(false);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Thất bại",
          "Mật khẩu cũ không chính xác"
        );
        setIsOpenChangePassword(true);
      });
  };

  const [valueOldPass, setValueOldPass] = useState("");
  const [valueNewPass, setValueNewPass] = useState("");
  const [valueCheckNewPass, setValueCheckNewPass] = useState("");

  function handleChangeOldPass(e) {
    setValueOldPass(e.target.value);
  }
  function handleChangeNewPass(e) {
    setValueNewPass(e.target.value);
  }
  function handleChangeCheckNewPass(e) {
    setValueCheckNewPass(e.target.value);
  }

  return (
    <>
      <Modal
        visible={isOpenChangePassword}
        onCancel={handleCancel}
        width="550px"
        footer={false}
        style={{ marginTop: "4%" }}
      >
        <Form
          layout={"vertical"}
          form={form}
          loading={loading}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <br></br>
          <Row>
            {/* <Col span={3}></Col> */}
            <Col span={24}>
              <div className="input-container">
                <Form.Item
                  name="old_password"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập mật khẩu cũ",
                    },
                  ]}
                  key="old_password"
                >
                  <Input.Password
                    // iconRender={(visible) =>
                    //   visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    // }
                    style={{ borderRadius: 10, height: 45, fontSize: 20 }}
                    id="input-pw"
                    onChange={handleChangeOldPass}
                  />
                </Form.Item>
                <label className={valueOldPass && "filled"}>Mật khẩu cũ</label>
              </div>

              <div className="input-container">
                <Form.Item
                  name="new_password"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập mật khẩu mới",
                    },
                    {
                      pattern:
                        /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{9,}/,
                      message: "Chưa đúng định dạng",
                    },
                  ]}
                  key="new_password"
                  className="input-container"
                >
                  <Input.Password
                    // iconRender={(visible) =>
                    //   visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    // }
                    style={{ borderRadius: 10, height: 45, fontSize: 20 }}
                    id="input-pw"
                    onChange={handleChangeNewPass}
                  />
                </Form.Item>
                <label className={valueNewPass && "filled"}>Mật khẩu mới</label>
              </div>

              <div className="input-container">
                <Form.Item
                  name="check_new_password"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng xác nhận mật khẩu mới",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("new_password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Mật khẩu không khớp"));
                      },
                    }),
                  ]}
                  key="check_new_password"
                  className="input-container"
                  hasFeedback
                // validateStatus="success"
                >
                  <Input.Password
                    // iconRender={(visible) =>
                    //   visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    // }
                    style={{ borderRadius: 10, height: 45, fontSize: 20 }}
                    id="input-pw"
                    onChange={handleChangeCheckNewPass}
                  />
                </Form.Item>
                <label className={valueCheckNewPass && "filled"}>
                  Xác nhận mật khẩu mới
                </label>
              </div>
            </Col>
            {/* <Col span={3}></Col> */}
          </Row>
          <Row className="background-warning">
            <Col span={2}>
              <ErrorOutlineIcon
                style={{ marginLeft: "20%", color: "#faad14", fontSize: 28 }}
              />{" "}
            </Col>
            <Col span={22}>
              <i style={{ fontSize: 15 }}>Lưu ý:</i>
              <br></br>
              <i>
                Mật khẩu phải có ít nhất 9 ký tự bao gồm chữ hoa, chữ thường, số
                và ký tự đặc biệt.
              </i>
            </Col>
          </Row>
          <br></br>
          <Form.Item style={{ textAlign: "center" }}>
            <LoadingButton
              type="submit"
              className={"m-2"}
              style={{ marginRight: "20px" }}
              variant="contained"
              loading={loading}
            >
              Đổi mật khẩu
            </LoadingButton>
            <Button
              onClick={onCloseModalChangePassword}
              color="error"
              variant="contained"
              disabled={loading}
            >
              Hủy
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export const CustomLayout = ({ isLogged, children }) => {
  const onUnAuth = () => {
    // localStorage.setItem('loginRedirect',this.props.location.pathname)
    return "Chưa đăng nhập";
  };

  return isLogged ? <CustomLayoutFC children={children} /> : onUnAuth();
};

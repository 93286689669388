import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Drawer,
} from "antd";

import {
  openNotificationWithIcon,
  QuiTacDatTenKH
} from "../../../Function";
import {Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  BulbOutlined,
} from "@ant-design/icons";

import {
  PostManageCustomer,
} from "../../../../api/manageCustomer.js";
import "../quanLyKhachHang/QuanLyKH"

const validateMessages = {
  required: "Vui lòng nhập ${label} !",
  types: {
    email: "${label} sai định dạng!",
    number: "${label} phải là số!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
const ModalAddCustomer = ({ open, onCancel, lsRole, fetchData, pager }) => {
    const [form] = Form.useForm();
    const [loadingAddNew, setLoadingAddNew] = useState(false);
    useEffect(() => {
        form.resetFields();
    }, []);
    const onFinish = (values) => {
        let check = true;
        for (let i = 0; i < values.full_name.length; i++) {
            if (values.full_name[i] === " " && values.full_name[i + 1] === " ") {
                openNotificationWithIcon(
                    "error",
                    "Thất bại",
                    "Không được có quá nhiều khoảng cách giữa 2 từ"
                );
                check = true;
                return;
            } else {
                check = false;
            }
        }
        if (check === false) {
            setLoadingAddNew(true);
            PostManageCustomer({
                full_name: values.full_name.trim().toUpperCase(),
                name_organization: values.name_organization.trim(),
                code_organization: values.code_organization.trim(),
                // numberphone: values.numberphone,
                // email: values.email,
                // profession: values.profession || "",
                // position: values.position || "",
                address: values.address || "",
                tax_code: values.tax_code || "",
            })
                .then((res) => {
                    if (res.data.error) {
                        openNotificationWithIcon("error", res.data.error);
                    } else {
                        setLoadingAddNew(false);
                        openNotificationWithIcon(
                            "success",
                            "Thành công",
                            "Thêm mới khách hàng thành công"
                        );
                        fetchData({ page: pager.current, page_size: pager.pageSize });
                        onCloseModal();
                        form.resetFields();
                    }
                })
                .catch((err) => {
                    if (err.data.error) {
                        setLoadingAddNew(false);
                        openNotificationWithIcon("error", err.data.error);
                    }
                });
        }
    };

    const onCloseModal = () => {
        form.resetFields();
        onCancel();
    };

    const [openDraw, setOpenDraw] = useState(false);
    const showDrawer = () => {
        setOpenDraw(true);
    };
    const onClose = () => {
        setOpenDraw(false);
    };

    return (
        <>
            <Modal
                title="Thêm khách hàng"
                visible={open}
                onCancel={onCloseModal}
                className="modalAddUser site-drawer-render-in-current-wrapper"
                width={screen.width > 768 ? 800 : "80%"}
                footer={false}
                bodyStyle={
                    screen.width > 768
                        ? null
                        : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
                }
            >
                <Drawer
                    title={
                        <h3 style={{ textAlign: "center" }}>
                            Quy định trong khai báo thông tin khách hàng
                        </h3>
                    }
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    key="right"
                    visible={openDraw}
                    style={{ position: "absolute" }}
                    getContainer={false}
                    width="70%"
                >
                    {/* <QuiDinh /> */}
                    <QuiTacDatTenKH />
                </Drawer>
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    validateMessages={validateMessages}
                    initialValues={{
                        remember: true,
                    }}
                    key="form"
                >
                    <Row>
                        <Col span={10}>
                            <Form.Item
                                name="full_name"
                                label="Tên khách hàng"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    // {
                                    //   pattern: new RegExp(
                                    //     "^[0-9a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$"
                                    //   ),
                                    //   message: "Không được nhập kí tự đặc biệt",
                                    // },
                                ]}
                                key="full_name"
                            >
                                <Input
                                    placeholder="Nhập tên khách hàng"
                                    onBlur={(e) => {
                                        form.setFieldsValue({
                                            full_name: e.target.value.trim().toUpperCase(),
                                        });
                                    }}
                                    maxLength={30}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={1}>
                            <BulbOutlined
                                onClick={showDrawer}
                                className="customIconDrawer"
                            />
                            {/* <ExclamationOutlined onClick={showDrawer} className="customIconDrawer"/> */}
                        </Col>
                        <Col span={11} offset={2}>
                            <Form.Item
                                name="name_organization"
                                label="Tên theo hợp đồng"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    // {
                                    //   pattern: new RegExp(
                                    //     "^[0-9a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$"
                                    //   ),
                                    //   message: "Không được nhập kí tự đặc biệt",
                                    // },
                                ]}
                                key="name_organization"
                            >
                                <Input
                                    placeholder="Nhập tên theo hợp đồng"
                                    onBlur={(e) => {
                                        form.setFieldsValue({
                                            name_organization: e.target.value.trim(),
                                        });
                                    }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="code_organization"
                                label="Mã khách hàng"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="code_organization"
                            >
                                <Input
                                    placeholder="Nhập mã khách hàng"
                                    maxLength={100}
                                    onBlur={(e) => {
                                        form.setFieldsValue({
                                            last_name: e.target.value.trim(),
                                        });
                                    }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={2}>
                            <Form.Item
                                name="address"
                                label="Địa chỉ"
                                key="address"
                            >
                                <Input
                                    placeholder="Nhập địa chỉ"
                                    onBlur={(e) => {
                                        form.setFieldsValue({ address: e.target.value.trim() });
                                    }}
                                    maxLength={100}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="tax_code"
                                label="Mã số thuế"
                                // rules={[
                                //   {
                                //     required: true,
                                //   },
                                // ]}
                                key="tax_code"
                            >
                                <Input
                                    placeholder="Nhập mã số thuế"
                                    onBlur={(e) => {
                                        form.setFieldsValue({
                                            tax_code: e.target.value.trim(),
                                        });
                                    }}
                                    maxLength={100}
                                ></Input>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        style={{ marginTop: "20px", textAlign: "center" }}
                        key="button"
                    >
                        <LoadingButton
                            type="submit"
                            className={"m-2"}
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            loading={loadingAddNew}
                        >
                            Đăng ký
                        </LoadingButton>
                        <Button
                            onClick={onCloseModal}
                            color="error"
                            variant="contained"
                            disabled={loadingAddNew}
                        >
                            Hủy
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ModalAddCustomer
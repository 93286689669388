import React, { useEffect, useState } from "react";
import {
    Input,
    Row,
    Col,
    Popconfirm,
    Table,
} from "antd";
import { errorHandle, openNotificationWithIcon } from "../../../Function";
import moment from "moment";
import {
    EditOutlined,
    LockOutlined,
    UnlockOutlined,
    CheckOutlined,
    DownloadOutlined,
    CloseOutlined,
    FilterOutlined,
} from "@ant-design/icons";
import { Grid, Box, Button } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import "../qlyNguoiDung.css";
import { useSelector } from "react-redux";
import ModalAddUser from "./ModalAddUser";
import ModalEditUser from "./ModalEditUser";
import FilterUser from "./FilterUser";
import ModalDetailUser from "./ModalDetailUser";
import { ExportUser, GetDetailUser, GetManageUser, GetRole, PutUserApi, TemplateUser } from "../../../../api/manageUser";
import { GetManageTeam } from "../../../../api/manageTeam";
const { Search } = Input;

const validateMessages = {
    required: "Không được để trống ô ${label} !",
    types: {
        email: "${label} đang sai định dạng!",
        number: "${label} not numbers!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const TableUser = () => {

    const [pageSize, setPageSize] = useState(10);
    const [pager, setPager] = useState({
        pageSize: pageSize,
        count: 0,
        current: 1,
    });
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [lsRole, setLsRole] = useState([]);
    const [dataInforUser, setDataInforUser] = useState([]);
    const [lsTeam, setLsTeam] = useState([]);
    const [dataDetail, setDataDetail] = useState();
    const userInfo = useSelector((state) => state.getUserInfo.userInfo);

    const [openInputSearch, setOpenInputSearch] = useState(false);
    const [dataFilter, setDataFilter] = useState({
        username: undefined,
        team: undefined,
    });


    const checkRoleManageAdmin = userInfo.role_name.toLowerCase() === "manage_admin"

    const showModalAddUser = () => {
        setIsModalVisible(true);
    };

    const getListRole = (params = {}) => {
        GetRole(params)
            .then((res) => {
                setLsRole(res.data);
            })
            .catch((err) => {
                errorHandle(err);
                setLoading(false);
            });
    };

    const getListTeam = (params = {}) => {
        GetManageTeam(params)
            .then((res) => {
                setLsTeam(res.data.results);
            })
            .catch((err) => {
                errorHandle(err);
                setLoading(false);
            });
    };

    const fetchData = (params = {}) => {
        setLoading(true);
        GetManageUser(params)
            .then((res) => {
                setData(res.data.results);
                setPager({
                    current: params.page,
                    pageSize: params.page_size,
                    count: res.data.count,
                });
                setLoading(false);
            })
            .catch((err) => {
                errorHandle(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        getListRole();
        getListTeam();
    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            fetchData({ page: 1, page_size: pager.pageSize, search });
            clearInterval(id);
        }, 300);
        return () => clearInterval(id);
    }, [search]);

    const onShowModalDetail = (record) => {
        GetDetailUser(record.id)
            .then((res) => {
                setDataDetail(res.data);
                setIsDetail(true);
            })
            .catch((err) => {
                if (err) {
                    openNotificationWithIcon("error", err);
                }
            });
    };

    const onShowModalEdit = (record) => {
        GetDetailUser(record.id)
            .then((re) => {
                setDataInforUser(re.data);
                setIsEditing(true);
            })
            .catch((err) => { });
    };

    const onLock_Unlock = (record) => {
        const lock = {
            userId: record.id,
            block: "true",
            is_lock: !record.is_active,
        };
        PutUserApi(lock)
            .then((r) => {
                fetchData({ page: pager.current, page_size: pager.pageSize, search });
            })
            .catch((err) => {
                if (err.data.error) {
                    openNotificationWithIcon("error", err.data.error);
                }
            });
    };

    const renderDetailsButton = (record) => {
        return (
            <Box sx={{ width: "100%" }}>
                <Grid item container xs={12}>
                    <Grid
                        xs={3}
                        item
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    ></Grid>
                    <Grid
                        xs={3}
                        item
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <span
                            title="Chỉnh sửa"
                            className={"col-6"}
                            style={{ color: "#4290d3", cursor: "pointer", marginRight: 10 }}
                            onClick={() => onShowModalEdit(record)}
                        >
                            <EditOutlined />
                        </span>
                    </Grid>
                    <Grid
                        xs={3}
                        item
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {record.is_active !== true ? (
                            <span
                                title={"User đang khóa"}
                                className={"col-6"}
                                style={{ color: "red", cursor: "pointer" }}
                            >
                                <Popconfirm
                                    title="Xác nhận mở khóa user"
                                    onConfirm={() => onLock_Unlock(record)}
                                    okText="Mở khóa"
                                    cancelText="Hủy"
                                >
                                    <LockOutlined style={{ color: "#ff4d4f" }} />
                                </Popconfirm>
                            </span>
                        ) : (
                            <span
                                title={"User đang mở"}
                                className={"col-6"}
                                style={{ color: "#008000", cursor: "pointer" }}
                            >
                                <Popconfirm
                                    title="Xác nhận khóa user"
                                    onConfirm={() => onLock_Unlock(record)}
                                    okText="Khóa"
                                    cancelText="Hủy"
                                >
                                    <UnlockOutlined style={{ color: "#008000" }} />
                                </Popconfirm>
                            </span>
                        )}
                    </Grid>
                </Grid>
            </Box>
        );
    };

    const columns = [
        {
            title: "STT",
            dataIndex: "id",
            key: "id",
            align: "center",
            render: (value, item, index) =>
                index + 1 + (pager.current - 1) * pager.pageSize,
            width: "5%",
        },

        {
            title: "MSNV",
            key: "msnv",
            align: "center",
            dataIndex: "msnv",
            ellipsis: true,
            width: "17%",
            render: (text, record, index) => record.msnv,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        onShowModalDetail(record);
                    },
                };
            },
        },

        {
            title: "User name",
            key: "username",
            align: "center",
            dataIndex: "username",
            ellipsis: true,
            width: "17%",

            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        onShowModalDetail(record);
                    },
                };
            },
            render: (text, record, index) => record.username,
        },

        {
            title: "Email",
            key: "email",
            align: "center",
            dataIndex: "email",
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        onShowModalDetail(record);
                    },
                };
            },
            render: (text, record, index) => record.email,
            width: "17%",
        },

        {
            title: "Phân quyền",
            key: "role_user",
            align: "center",
            dataIndex: "role_user",
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        onShowModalDetail(record);
                    },
                };
            },
            render: (text, record, index) => record.role_user[0].name,
            // record.role_user.map((item) => item.name),
            width: "10%",
        },

        {
            title: "Team",
            key: "team",
            align: "center",
            dataIndex: "team",
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        onShowModalDetail(record);
                    },
                };
            },
            width: "8%",

            render: (text, record, index) => record.team.length > 0 ? record.team[0].name : null,
        },

        {
            title: "Thời gian tạo",
            key: "date_joined",
            align: "center",
            dataIndex: "date_joined",
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        onShowModalDetail(record);
                    },
                };
            },
            width: "10%",

            render: (text, record, index) =>
                record.date_joined
                    ? moment(record.date_joined).format("DD-MM-YYYY")
                    : "",
        },

        {
            title: "Trạng thái",
            key: "is_active",
            align: "center",
            dataIndex: "is_active",
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        onShowModalDetail(record);
                    },
                };
            },
            width: "8%",

            render: (text, record, index) =>
                record.is_active === false ? (
                    <CloseOutlined style={{ color: "red" }} />
                ) : (
                    <CheckOutlined style={{ color: "green" }} />
                ),
        },

        {
            title: "Thao tác",
            align: "center",
            render: renderDetailsButton,
            width: "8%",
        }
    ];

    const columnsManageAdmin = [
        {
            title: "STT",
            dataIndex: "id",
            key: "id",
            align: "center",
            render: (value, item, index) =>
                index + 1 + (pager.current - 1) * pager.pageSize,
            width: "5%",
        },

        {
            title: "MSNV",
            key: "msnv",
            align: "center",
            dataIndex: "msnv",
            ellipsis: true,
            width: "17%",
            render: (text, record, index) => record.msnv,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        onShowModalDetail(record);
                    },
                };
            },
        },

        {
            title: "User name",
            key: "username",
            align: "center",
            dataIndex: "username",
            ellipsis: true,
            width: "17%",

            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        onShowModalDetail(record);
                    },
                };
            },
            render: (text, record, index) => record.username,
        },

        {
            title: "Email",
            key: "email",
            align: "center",
            dataIndex: "email",
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        onShowModalDetail(record);
                    },
                };
            },
            render: (text, record, index) => record.email,
            width: "17%",
        },

        {
            title: "Phân quyền",
            key: "role_user",
            align: "center",
            dataIndex: "role_user",
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        onShowModalDetail(record);
                    },
                };
            },
            render: (text, record, index) => record.role_user[0].name,
            // record.role_user.map((item) => item.name),
            width: "10%",
        },

        {
            title: "Team",
            key: "team",
            align: "center",
            dataIndex: "team",
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        onShowModalDetail(record);
                    },
                };
            },
            width: "8%",

            render: (text, record, index) => record.team.length > 0 ? record.team[0].name : null,
        },

        {
            title: "Thời gian tạo",
            key: "date_joined",
            align: "center",
            dataIndex: "date_joined",
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        onShowModalDetail(record);
                    },
                };
            },
            width: "10%",

            render: (text, record, index) =>
                record.date_joined
                    ? moment(record.date_joined).format("DD-MM-YYYY")
                    : "",
        },

        {
            title: "Trạng thái",
            key: "is_active",
            align: "center",
            dataIndex: "is_active",
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => {
                        onShowModalDetail(record);
                    },
                };
            },
            width: "8%",

            render: (text, record, index) =>
                record.is_active === false ? (
                    <CloseOutlined style={{ color: "red" }} />
                ) : (
                    <CheckOutlined style={{ color: "green" }} />
                ),
        },
    ];

    const onSearch = (e) => {
        setSearch(e);
    };

    const handleChange = (pagination) => {
        const currentPager = { ...pager };
        currentPager.current = pagination.current;
        currentPager.pageSize = pagination.pageSize;
        setPager({ ...currentPager });
        fetchData({
            status: currentPager.status,
            page_size: pagination.pageSize,
            page: pagination.current,
            search,
        });
    };

    const fileDownload_User = () => {
        const fnFileDownload = require("js-file-download");
        const filename =
            "DanhSachUser_" + moment(new Date()).format("DDMMYYYY") + ".xlsx";
        ExportUser({ responseType: "blob" })
            .then((res) => {
                fnFileDownload(res.data, filename);
            })
            .catch((err) => {
                openNotificationWithIcon(
                    "error",
                    "Không tải được danh sách hồ sơ. Bạn vui lòng thử lại."
                );
            });
    };

    const DownLoadTemplate_User = () => {
        // setLoading_page(true);
        const fnFileDownload = require("js-file-download");
        const filename =
            "FileMauUser_" + moment(new Date()).format("DDMMYYYY") + ".xlsx";
        TemplateUser({ responseType: "blob" })
            .then((res) => {
                fnFileDownload(res.data, filename);
            })
            .catch((err) => {
                openNotificationWithIcon(
                    "error",
                    "Không tải được danh sách hồ sơ. Bạn vui lòng thử lại."
                );
            });
    };
    const openSearch = () => {
        setOpenInputSearch(!openInputSearch);
    };



    return (
        <div className="FormHomeTable">
            <div className="HeaderContentUser">
                <Row style={{ width: "100%", marginBottom: "10px" }}>
                    {screen.width > 768 ? (
                        <>

                            <Col span={16}>

                                <Button
                                    className="btnAddUser"
                                    onClick={showModalAddUser}
                                    style={{ float: "left" }}
                                >
                                    <ControlPointIcon
                                        style={{ fontSize: 20, marginRight: "5px" }}
                                    />{" "}
                                    Thêm mới
                                </Button>
                                <Button
                                    onClick={fileDownload_User}
                                    className="btnAddUser"
                                    style={{
                                        float: "left",
                                        marginLeft: 15,
                                        backgroundColor: "green",
                                    }}
                                >
                                    <DownloadOutlined
                                        style={{
                                            fontSize: 16,
                                            marginRight: 5,
                                        }}
                                    />
                                    Tải xuống
                                </Button>

                                <Button
                                    onClick={openSearch}
                                    className="btnAddUser"
                                    style={{
                                        float: "right",
                                        marginRight: 15,
                                        backgroundColor: "#1890ff",
                                    }}
                                >
                                    <FilterOutlined
                                        style={{
                                            fontSize: 16,
                                            marginRight: 5,
                                        }}
                                    />
                                    Lọc
                                </Button>
                            </Col>

                            <Col span={8}>
                                <Search
                                    placeholder="Nhập thông tin cần Tìm kiếm"
                                    allowClear
                                    enterButton="Tìm kiếm"
                                    size="middle"
                                    onSearch={onSearch}
                                    loading={false}
                                />
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col span={8}>
                                <Button
                                    className="btnAddUser"
                                    onClick={showModalAddUser}
                                    style={{ float: "left", height: 32 }}
                                >
                                    <ControlPointIcon style={{ marginRight: "5px" }} /> Thêm mới
                                </Button>
                            </Col>
                            <Col span={16}>
                                <Search
                                    placeholder="Nhập thông tin cần Tìm kiếm"
                                    allowClear
                                    enterButton
                                    size="middle"
                                    onSearch={onSearch}
                                    loading={false}
                                />
                            </Col>
                        </>
                    )}
                </Row>
            </div>

            {openInputSearch === true ? <FilterUser fetchData={fetchData} pager={pager} setDataFilter={setDataFilter} dataFilter={dataFilter} data={data} search={search} lsTeam={lsTeam} /> : null}

            <Row>
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={data}
                        onChange={handleChange}
                        locale={{ emptyText: "Không có dữ liệu" }}
                        rowClassName={"styleTableLeTet"}
                        style={{ position: "absolute" }}
                        scroll={{
                            y: openInputSearch === true ? "50vh" : "60vh",
                        }}
                        pagination={{
                            current: pager.current,
                            pageSize: pager.pageSize,
                            // showQuickJumper: true,
                            total: pager.count,
                            // locale: {
                            //   jump_to: "Đến trang: ",
                            //   page: "",
                            // },
                            showSizeChanger: true,
                        }}
                        size="middle"
                    />
                </Col>
            </Row>
            <ModalAddUser
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                fetchData={fetchData}
                pager={pager}
                lsRole={lsRole}
                lsTeam={lsTeam}
            />
            <ModalEditUser
                open={isEditing}
                onCancel={() => setIsEditing(false)}
                dataInforUser={dataInforUser}
                pager={pager}
                fetchData={fetchData}
                lsRole={lsRole}
                lsTeam={lsTeam}
            />
            <ModalDetailUser
                open={isDetail}
                onCancel={() => setIsDetail(false)}
                dataInforUser={dataDetail}
            />
        </div>
    );
};

export default TableUser
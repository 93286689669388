import React from "react";
import {
    Form,
    Row,
    Col,
    Select,
    Input,
    DatePicker,
} from "antd";
import "../thuNhan/CaNhan.css"
import moment from "moment";
import { Button } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const validateMessages = {
    required: "Vui lòng nhập ${label} !",
    types: {
        email: "${label} sai định dạng!",
        number: "${label} phải là số!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const dateFormat = "DD-MM-YYYY";
const { Option } = Select;

const FilterContract = ({ typeDocumentFilter, data, setCheckFilter, openInputSearch, fetchData, pager, search, setDataFilter, datafilter, typeDocument, customer, legalEntity, dataAllNameDocs, typeDocsAll, setSearch }) => {
    const [form] = Form.useForm();
    const [dataAllNameDocsFilter, setDataAllNameDocsFilter] = useState([])
    const [ruleTypeDocument, setRuleTypeDocument] = useState([])

    var idName = 0
    const onIdName = (value) => {
        idName = value
    }

    const handleIdNameDoc = (value) => {
        onIdName(value)
    }
    const formatDate = (current) => {
        return moment(current).format(dateFormat);
    };

    const handleFilterNameDocs = (value) => {
        form.resetFields(["type_document"])
        const newData = dataAllNameDocs.filter(item => item.fore_rule_typedoc === value).filter(item => item.is_active === true).filter(item => item.name !== "" && item.name !== undefined && item.name !== null)
        setDataAllNameDocsFilter(newData)
    }

    useEffect(() => {

        if (openInputSearch) {
            const listActive = typeDocument.filter(item => item.is_active === true)
            const filters = listActive.reduce((acc, item) => {
                if (!acc.find((filter) => filter.name_rule === item.name_rule)) {
                    acc.push(item);
                }
                return acc;
            }, []);
            filters.sort((a, b) => {
                return a.name_rule.localeCompare(b.name_rule)
            });

            setRuleTypeDocument(filters)
        } else {
            form.resetFields()
        }
    }, [openInputSearch]);

    // useEffect(() => {
    //     console.log(data)
    // }, [data]);

    const onFinish = (values) => {
        setCheckFilter(true)
        setSearch()
        fetchData({
            page: 1,
            page_size: pager.pageSize,
            customer:
                values.customer === undefined
                    ? undefined
                    : values.customer.length !== 0
                        ? JSON.stringify(values.customer)
                        : undefined,
            rule_type_document:
                values.rule_type_document === undefined
                    ? undefined
                    : values.rule_type_document.length !== 0
                        ? JSON.stringify(values.rule_type_document)
                        : undefined,
            type_document:
                values.type_document === undefined
                    ? undefined
                    : values.type_document.length !== 0
                        ? JSON.stringify(values.type_document)
                        : undefined,
            project: values.project,
            auto_extend: values.auto_extend,
            user_update: values.user_update,
            day_sign: values.day_sign,
            day_expired: values.day_expired,
            legal_entity_signed: values.legal_entity_signed === undefined
                ? undefined
                : values.legal_entity_signed.length !== 0
                    ? JSON.stringify(values.legal_entity_signed)
                    : undefined,
        });
        setDataFilter({
            customer: values.customer === undefined
                ? undefined
                : values.customer.length !== 0
                    ? JSON.stringify(values.customer)
                    : undefined,
            rule_type_document: values.rule_type_document === undefined
                ? undefined
                : values.rule_type_document.length !== 0
                    ? JSON.stringify(values.rule_type_document)
                    : undefined,
            type_document: values.type_document === undefined
                ? undefined
                : values.type_document.length !== 0
                    ? JSON.stringify(values.type_document)
                    : undefined,
            project: values.project,
            auto_extend: values.auto_extend,
            user_update: values.user_update,
            day_sign: values.day_sign,
            day_expired: values.day_expired,
            legal_entity_signed: values.legal_entity_signed === undefined
                ? undefined
                : values.legal_entity_signed.length !== 0
                    ? JSON.stringify(values.legal_entity_signed)
                    : undefined,
        });
    };

    const onCloseFilter = () => {
        form.resetFields();
        setDataFilter({
            customer: undefined,
            rule_type_document: undefined,
            type_document: undefined,
            project: "",
            auto_extend: "",
            user_update: "",
            day_sign: "",
            day_expired: "",
            legal_entity_signed: "",
        });
        fetchData({ page: 1, page_size: pager.pageSize, search });
    };

    return (
        <Form
            form={form}
            layout="vertical"
            // autoComplete="off"
            onFinish={onFinish}
            // initialValues={{
            //     customer: datafilter.customer,
            //     rule_type_document: datafilter.rule_type_document,
            //     project: datafilter.project,
            //     auto_extend: datafilter.auto_extend,
            //     user_update: datafilter.user_update,
            //     day_sign: datafilter.day_sign,
            //     day_expired: datafilter.day_expired,
            // }}
            key="form"
        >
            <Row key={1} style={{ height: 70 }}>
                <Col span={4}>
                    <Form.Item name="customer" label="Tên khách hàng" key="customer">
                        <Select
                            placeholder="Chọn tên khách hàng"
                            optionFilterProp="children"
                            key="customer1"
                            mode="multiple"
                            allowClear
                            maxTagCount="responsive"
                        >
                            {customer.map((item, index) => (
                                <Option key={item.id} value={item.id}>
                                    {item.full_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                    <Form.Item
                        name="rule_type_document"
                        label="Loại tài liệu"
                        key="rule_type_document"
                    >
                        <Select
                            placeholder="Chọn loại tài liệu"
                            style={{
                                width: "100%",
                            }}
                            key="rule_type_document"
                            allowClear
                            showSearch={false}
                            maxTagCount="responsive"
                            optionFilterProp="children"
                            onChange={handleFilterNameDocs}
                        >
                            {typeDocumentFilter.map((item, index) => (
                                <Option key={index} value={item.id}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                    <Form.Item
                        name="type_document"
                        label="Tên tài liệu"
                        key="type_document"
                    >
                        <Select
                            placeholder="Chọn tên tài liệu"
                            style={{
                                width: "100%",
                            }}
                            key="type_document1"
                            allowClear
                            mode="multiple"
                            maxTagCount="responsive"
                            optionFilterProp="children"
                            onChange={(value) => handleIdNameDoc(value)}
                        >
                            {dataAllNameDocsFilter.map((item, index) => (
                                <Option key={item.name} value={item.id}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                    <Form.Item name="day_sign" label="Ngày ký kết" key="day_sign">
                        <DatePicker
                            //   onChange={(value) =>
                            //     setDateStart(moment(value).format(dateFormat))
                            //   }
                            placeholder="Chọn ngày"
                            style={{ width: "100%" }}
                            format={formatDate}
                        />
                    </Form.Item>
                </Col>

                <Col span={4} offset={1}>
                    <Form.Item
                        name="day_expired"
                        label="Ngày hết hạn"
                        key="day_expired"
                    >
                        <DatePicker
                            //   onChange={(value) =>
                            //     setDateStart(moment(value).format(dateFormat))
                            //   }
                            placeholder="Chọn ngày"
                            style={{ width: "100%" }}
                            format={formatDate}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row key={2}>
                <Col span={4}>
                    <Form.Item name="project" label="Tên dự án" key="project">
                        <Input placeholder="Nhập tên dự án" maxLength={100} />
                    </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                    <Form.Item
                        name="legal_entity_signed"
                        label="Pháp nhân ký"
                        key="legal_entity_signed"
                    >
                        <Select
                            placeholder="Chọn mã pháp nhân"
                            optionFilterProp="children"
                            key="customer1"
                            allowClear
                            maxTagCount="responsive"
                        >
                            {legalEntity.map((item, index) => (
                                <Option key={index} value={item.id}>
                                    {item.code_signer}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={4} offset={1}>
                    <Form.Item
                        name="auto_extend"
                        label="Tự động tái tục"
                        key="auto_extend"
                    >
                        <Select
                            placeholder="Chọn Có hoặc Không"
                            optionFilterProp="children"
                            key="auto_extend1"
                            allowClear
                        >
                            <Option key="co" value={"True"}>
                                Có
                            </Option>
                            <Option key="khong" value={"False"}>
                                Không
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={4} offset={1}>
                    <Form.Item
                        name="user_update"
                        label="Người tạo"
                        key="user_update"
                    >
                        <Input placeholder="Nhập người tạo" maxLength={100} />
                    </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                    <Form.Item key="btn_docs">
                        <Button
                            // onClick={onCloseModal}
                            type="submit"
                            color="info"
                            variant="contained"
                            style={{ height: 30, marginTop: 30 }}
                        // disabled={loadingAddNew}
                        >
                            Tìm kiếm
                        </Button>
                        <Button
                            // onClick={onCloseModal}
                            type="button"
                            color="info"
                            variant="contained"
                            style={{ height: 30, marginTop: 30, marginLeft: 20 }}
                            onClick={onCloseFilter}

                        >
                            Khôi phục
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default FilterContract
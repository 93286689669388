import React, { useEffect, useState } from "react";
import {
    Form,
    Row,
    Col,
    Select,
} from "antd";
import { errorHandle } from "../../../Function";

import {Button } from "@mui/material";

import "../qlyNguoiDung.css";
import { GetManageUser } from "../../../../api/manageUser";
import { GetManageTeam } from "../../../../api/manageTeam";
const { Option } = Select;

const validateMessages = {
    required: "Không được để trống ô ${label} !",
    types: {
        email: "${label} đang sai định dạng!",
        number: "${label} not numbers!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const FilterUser = ({ fetchData, pager, setDataFilter, dataFilter, data, search, lsTeam }) => {
    const [form] = Form.useForm();
    const [allUsername, setAllUsername] = useState([])
    const [allTeam, setAllTeam] = useState([])

    const onFinish = (values) => {
        fetchData({
            page: 1,
            page_size: pager.pageSize,
            username: JSON.stringify(values.username),
            team: JSON.stringify(values.team),
        });
        setDataFilter({
            username: values.username,
            team: values.team,
        });
    };

    const fetchUsernameFilter = (params = {}) => {
        // setLoading(true);
        GetManageUser(params)
            .then((res) => {
                setAllUsername(res.data.results);
                // setPager({
                //   current: params.page,
                //   pageSize: params.page_size,
                //   count: res.data.count,
                // });
                // setLoading(false);
            })
            .catch((err) => {
                errorHandle(err);
                // setLoading(false);
            });
    };

    const fetchTeamFilter = (params = {}) => {
        // setLoading(true);
        GetManageTeam(params)
            .then((res) => {
                setAllTeam(res.data.results);
                // setPager({
                //   current: params.page,
                //   pageSize: params.page_size,
                //   count: res.data.count,
                // });
                // setLoading(false);
            })
            .catch((err) => {
                errorHandle(err);
                // setLoading(false);
            });
    };

    const onCloseFilter = () => {
        form.resetFields();
        setDataFilter({
            username: undefined,
            team: undefined,
        });
        fetchData({ page: 1, page_size: pager.pageSize, search });
    };

    useEffect(() => {
        // UserName
        fetchUsernameFilter({ page: 1, page_size: 5000, search });

        // Team
        fetchTeamFilter({ page: 1, page_size: 5000, search });
    }, [search]);

    let sortData = allUsername.sort((a, b) => {
        return a.username.localeCompare(b.username)
    });

    let sortLsTeam = allTeam.sort((a, b) => {
        return a.name.localeCompare(b.name)
    });

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            // initialValues={{
            //   username: dataFilter.username,
            //   team: dataFilter.team,
            // }}
            key="form"
        >
            <Row key={1}>
                <Col span={5}>
                    <Form.Item name="username" label="User name" key="username">
                        <Select
                            placeholder="Chọn user"
                            optionFilterProp="children"
                            key="username1"
                            mode="multiple"
                            maxTagCount="responsive"
                            allowClear
                        >
                            {sortData.map((item, index) => (
                                <Option key={item.id} value={item.username}>
                                    {item.username}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col span={5}>
                    <Form.Item name="team" label="Team" key="team">
                        <Select
                            placeholder="Chọn Team"
                            optionFilterProp="children"
                            key="team1"
                            mode="multiple"
                            maxTagCount="responsive"
                            allowClear
                        >
                            {sortLsTeam.map((item, index) => (
                                <Option key={item.id} value={item.name}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col span={5}>
                    <Form.Item key="btn_docs">
                        <Button
                            type="submit"
                            color="info"
                            variant="contained"
                            style={{ height: 30, marginTop: 30 }}
                        >
                            Tìm kiếm
                        </Button>
                        <Button
                            type="button"
                            color="info"
                            variant="contained"
                            style={{ height: 30, marginTop: 30, marginLeft: 20 }}
                            onClick={onCloseFilter}
                        >
                            Khôi phục
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default FilterUser

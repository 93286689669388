import { authAxios } from "../api/axiosClient";
import { downloadTypeDocument, importDocument, manageDetailCustomer, manageDetailTypeDocument, managetypeDocument, ruleTypeDocument, ruleTypeDocumentAll, typeDocumentAll } from "../constants";

export const DownloadTypeDocument = (params = {}) => {
    const url = downloadTypeDocument;
    return authAxios().get(url, params);
};

export const GetDetailTypeDocument = (id, params = {}) => {
    const url = manageDetailTypeDocument;
    return authAxios().get(url(id), { params });
};

export const GetListTypeDocument = (params = {}) => {
    const url = managetypeDocument;
    return authAxios().get(url, { params });
};

export const GetRuleTypeDocumentAll = (params = {}) => {
    const url = ruleTypeDocumentAll;
    return authAxios().get(url, params);
};

export const PutTypeDocumentApi = (params = {}) => {
    const url = managetypeDocument;
    return authAxios().put(url, params);
};

export const PostRuleTypeDocument = (params = {}) => {
    const url = ruleTypeDocument;
    return authAxios().post(url, params);
};

export const PostTypeDocument = (params = {}) => {
    const url = managetypeDocument;
    return authAxios().post(url, params);
};

export const PutRuleTypeDocument = (params = {}) => {
    const url = ruleTypeDocument;
    return authAxios().put(url, params);
};

export const ImportDocument = (params = {}) => {
    const url = importDocument;
    return authAxios().post(url, params);
};

export const GetTypeDocumentAll = (params = {}) => {
    const url = typeDocumentAll;
    return authAxios().get(url, params);
};

export const PutTypeDocument = (id, params = {}) => {
    const url = manageDetailTypeDocument;
    return authAxios().put(url(id), params);
};

export const GetRuleTypeDocument = (params = {}) => {
    const url = ruleTypeDocument;
    return authAxios().get(url, params);
};

import { authAxios } from "../api/axiosClient";
import { exportCustomer, importCustomer, manageCustomer, manageDetailCustomer, templateCustomer } from "../constants";

export const GetManageCustomer = (params = {}) => {
    const url = manageCustomer;
    return authAxios().get(url, { params });
};

export const ExportCustomer = (params = {}) => {
    const url = exportCustomer;
    return authAxios().get(url, params);
};

export const PutCustomerApi = (params = {}) => {
    const url = manageCustomer;
    return authAxios().put(url, params);
}

export const PutLockCustomerApi = (params = {}) => {
    const url = manageCustomer;
    return authAxios().put(url, { params });
}

export const PostManageCustomer = (params = {}) => {
    const url = manageCustomer;
    return authAxios().post(url, params);
}

export const GetInforUserCustomer = (id, params = {}) => {
    const url = manageDetailCustomer;
    return authAxios().get(url(id), params);
}

export const TemplateCustomer = (params = {}) => {
    const url = templateCustomer;
    return authAxios().get(url, params);
};

export const ImportCustomer = (params = {}) => {
    const url = importCustomer;
    return authAxios().post(url, params);
};
import React, { useEffect, useState } from "react";
import {
    Form,
    Row,
    Col,
    Select,
    Input,
    Upload,
} from "antd";

import {
    errorHandle,
} from "../../../Function";
import { Button } from "@mui/material";

import {
    GetManageCustomer,
} from "../../../../api/manageCustomer";

import "../quanLyKhachHang/QuanLyKH"

const validateMessages = {
    required: "Vui lòng nhập ${label} !",
    types: {
        email: "${label} sai định dạng!",
        number: "${label} phải là số!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};
const { Option } = Select;
const { Search } = Input;
const { Dragger } = Upload;


const FilterCustomer = ({ fetchData, pager, search, setPager, setData, setSearch, setFullNameSearch, formFilter }) => {
    const [allCustomer, setAllCustomer] = useState([])
    const [selected, setSelected] = useState([])
    const [dataFilter, setDataFilter] = useState({
        full_name: undefined,
    });

    const onFinish = (values) => {
        fetchData({
            page: 1,
            page_size: pager.pageSize,
            search: search,
            full_name: JSON.stringify(values.full_name),
        });
        setDataFilter({
            full_name: values.full_name,
        });
        setFullNameSearch(JSON.stringify(values.full_name))
    };

    const onCloseFilter = () => {
        formFilter.resetFields();
        // setSelected([])
        setDataFilter({});
        fetchData({ page: 1, page_size: pager.pageSize, search });
        setFullNameSearch()
    };

    const fetchDataFilter = (params = {}) => {
        // setLoading(true);
        GetManageCustomer(params)
            .then((res) => {
                setAllCustomer(res.data.results);
                // setPager({
                //   current: params.page,
                //   pageSize: params.page_size,
                //   count: res.data.count,
                // });
                // setLoading(false);
            })
            .catch((err) => {
                errorHandle(err);
                // setLoading(false);
            });
    };

    useEffect(() => {
        // if (openInputSearch === true) {
        fetchDataFilter({ page: 1, page_size: 5000, search });
        // }
    }, [search]);

    let sortData = allCustomer.sort((a, b) => {
        // var keyA = a.full_name
        //   ,
        //   keyB = b.full_name
        //   ;
        // // Compare the 2 dates
        // if (keyA < keyB) return -1;
        // if (keyA > keyB) return 1;
        // return 0
        return a.full_name.localeCompare(b.full_name)
    });

    const HandleSelected = (value) => {
        setSelected(value)
    }

    return (
        <Form
            form={formFilter}
            layout="horizontal"
            onFinish={onFinish}
            // initialValues={{
            //   full_name: dataFilter.full_name,
            // }}
            key="form"
        >
            <Row key={1}>
                <Col span={7}>
                    <Form.Item name="full_name" label="Tên khách hàng" key="full_name">
                        <Select
                            placeholder="Chọn tên khách hàng"
                            // onSearch={onSearch}
                            optionFilterProp="children"
                            key="full_name1"
                            mode="multiple"
                            maxTagCount='responsive'
                            onChange={value => HandleSelected(value)}
                            value={selected}
                        >
                            {
                                sortData.map((item, index) => (
                                    <Option key={item.full_name} value={item.full_name}>
                                        {item.full_name}
                                    </Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col span={5}>
                    <Form.Item key="btn_docs">
                        <Button
                            // onClick={onCloseModal}
                            type="submit"
                            color="info"
                            variant="contained"
                            style={{ height: 30 }}
                        >
                            Tìm kiếm
                        </Button>
                        <Button
                            type="button"
                            color="info"
                            variant="contained"
                            style={{ height: 30, marginLeft: 20 }}
                            onClick={() => { onCloseFilter() }}
                        >
                            Khôi phục
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
export default FilterCustomer
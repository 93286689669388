import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import UserLayout from "../../containers/UserLayout";
import Login from "../Login";
import Cookies from "universal-cookie";
import NotPermission from "../../containers/notPermission";
import LoadingPage from "../../containers/loadingpage";
import NotFoundLayout from "../../containers/notfound";
import { NotValidTime } from "../../containers/notValidTime";
import { authSuccess } from "../../app/Actions/auth";

import { getUserInfo } from "../../app/Reducers/getUserInfo";

// import Forgot from "../containers/Forgot";
import { CustomLayout } from "../Layout";
import QlyNguoiDung from "../component/qlyNguoiDung/QlyNguoiDung";
import CaNhan from "../component/qlyHoSo/thuNhan/CaNhan";
import ThongKe from "../component/qlyHoSo/thongKe/ThongKe";
import ThuNhanTatCa from "../component/qlyHoSo/moiNguoi/ThuNhanTatCa";
import QuanLyMail from "../component/qlyHoSo/quanLyMail/QuanLyMail";
import TaiLieu from "../component/qlyHoSo/taiLieu/TaiLieu";
import QuanLyKH from "../component/qlyHoSo/quanLyKhachHang/QuanLyKH";
import BieuMau from "../component/qlyHoSo/bieu_mau/BieuMau";
import PhapNhanKy from "../component/qlyHoSo/phap_nhan_ky/PhapNhanKy";

const cookies = new Cookies();

function Main() {
  const dispatch = useDispatch();
  // const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const [isValid, setIsValid] = useState(true);
  const [isSuperA, setIsSuperA] = useState(false);
  const [lsPermissions, setLsPermissions] = useState([]);

  const token = cookies.get("token_contract_v2");
  if (token) {
    dispatch(authSuccess(token));
  }
  const auth = useSelector((state) => state.auth.token) !== null;
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  useEffect(() => {
    if (auth && token) {
      dispatch(getUserInfo());
    }
  }, [auth, token]);

  useEffect(() => {
    if (userInfo && Object.keys(userInfo).length != 0) {
      setIsSuperA(userInfo.is_superuser);
      setLsPermissions([userInfo.role_name]);
    }
  }, [userInfo]);

  return (
    <Router>
      <Switch>
        <ProtectLoginRoute
          exact
          path="/login"
          protect={auth}
          user_info={userInfo}
        >
          <UserLayout>
            <Login />
          </UserLayout>
        </ProtectLoginRoute>
        <RouteWithLayout
          component={QlyNguoiDung}
          exact
          layout={CustomLayout}
          path="/manage_user"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin", "CPO", "Manage_Admin"]}
          isLogged={auth}
          isValid={isValid}
        />
        <RouteWithLayout
          component={CaNhan}
          exact
          layout={CustomLayout}
          path="/"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin", "Nhân viên", "Manage_Admin"]}
          isLogged={auth}
          isValid={isValid}
        />
        <RouteWithLayout
          component={TaiLieu}
          exact
          layout={CustomLayout}
          path="/type_document"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin", "Manage_Admin"]}
          isLogged={auth}
          isValid={isValid}
        />
        {/* <RouteWithLayout
          component={ThuNhanTatCa}
          exact
          layout={CustomLayout}
          path="/"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin", "Nhân viên", "Manage_Admin"]}
          isLogged={auth}
          isValid={isValid}
        /> */}
        <RouteWithLayout
          component={ThongKe}
          exact
          layout={CustomLayout}
          path="/statistic"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin", "CPO", "Manage_Admin"]}
          isLogged={auth}
          isValid={isValid}
        />
        <RouteWithLayout
          component={QuanLyKH}
          exact
          layout={CustomLayout}
          path="/manage_customer"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin", "CPO", "Manage_Admin"]}
          isLogged={auth}
          isValid={isValid}
        />
        <RouteWithLayout
          component={BieuMau}
          exact
          layout={CustomLayout}
          path="/form"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin", "CPO", "Manage_Admin"]}
          isLogged={auth}
          isValid={isValid}
        />
        <RouteWithLayout
          component={PhapNhanKy}
          exact
          layout={CustomLayout}
          path="/legal_entity_signatory"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin", "CPO", "Manage_Admin"]}
          isLogged={auth}
          isValid={isValid}
        />
        <RouteWithLayout
          component={QuanLyMail}
          exact
          layout={CustomLayout}
          path="/manage_mail"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin", "CPO", "Manage_Admin"]}
          isLogged={auth}
          isValid={isValid}
        />
        <RouteWithLayout
          component={NotPermission}
          exact
          layout={CustomLayout}
          path="/notpermission"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={"403"}
          isLogged={auth}
          isValid={isValid}
          isSuperA={isSuperA}
        />
        <RouteWithLayout
          component={NotFoundLayout}
          layout={CustomLayout}
          path="/"
          lsPermissions={lsPermissions}
          isPrivate={true}
          isLogged={auth}
          permission={"404"}
          isValid={isValid}
          isSuperA={isSuperA}
        />
      </Switch>
    </Router>
  );
}

const RouteWithLayout = (props) => {
  const {
    layout: Layout,
    isLogged: isLogged,
    component: Component,
    isPrivate: isPrivate,
    isValid: isValid,
    lsPermissions: lsPermissions,
    permission: permission,
    path: path,
    ...rest
  } = props;
  const getRejectRoute = (type) => {
    if (type != "404" && path != "/") {
      type = "403";
    }
    switch (type) {
      case "403":
        return <NotPermission />;
      case "404":
        return <NotFoundLayout />;
      default:
        return <LoadingPage />;
      // return <NotPermission />;
    }
  };
  return (
    <Route
      {...rest}
      render={() =>
        isValid ? (
          isPrivate ? (
            isLogged ? (
              lsPermissions && lsPermissions.length > 0 ? (
                lsPermissions.some((r) => permission.includes(r)) ? (
                  <Layout isLogged={isLogged}>
                    <Component {...props} />
                  </Layout>
                ) : (
                  getRejectRoute(permission)
                )
              ) : (
                <span></span>
              )
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          ) : (
            <Layout isLogged={isLogged}>
              <Component {...props} />
            </Layout>
          )
        ) : (
          <NotValidTime isValid={isValid} />
        )
      }
    />
  );
};

const ProtectLoginRoute = ({ protect, children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => (!protect ? children : <Redirect to="/"></Redirect>)}
    />
  );
};

export default Main;

import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Select,
  Tag,
  Steps,
  Input,
  Result,
  Upload,
  DatePicker,
  Checkbox,
  Popconfirm,
  Popover,
  InputNumber,
  Spin,
  Table,
  Tabs,
} from "antd";
import { useStepsForm } from "sunflower-antd";
import { useSelector } from "react-redux";
import "../thuNhan/CaNhan.css"
import moment from "moment";
import { errorHandle, openNotificationWithIcon } from "../../../Function";
import { localhost } from "../../../../server";
import {
  DeleteOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  InboxOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { LoadingButton } from "@mui/lab";
import { Grid, Box, Button } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import { GetManageTeam } from "../../../../api/manageTeam";
import { GetManageUser } from "../../../../api/manageUser";
import { DeleteFileDocument, DeleteUploadApi, DownloadFile, GetInforUpload, GetManageUploadAll, LogContract, PutUploadFile } from "../../../../api/manageContract";
import { GetManageCustomer } from "../../../../api/manageCustomer";
import { GetListTypeDocument } from "../../../../api/manageDocs";

const { Step } = Steps;
const { Dragger } = Upload;
const { Search } = Input;

const dateFormat = "DD-MM-YYYY";

const ModalDetailContract = ({ open, onCancel, dataDetail }) => {
  const [form] = Form.useForm();
  const onCloseModal = () => {
    form.resetFields();
    onCancel();
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const BoxNewSchedule_pro = () => {
    const {
      form,
      current,
      gotoStep,
      stepsProps,
      formProps,
      submit,
      formLoading,
    } = useStepsForm({
      async submit(values) {
        const { username, email, address } = values;
        await new Promise((r) => setTimeout(r, 500));
        return "ok";
      },
      total: 2,
      isBackValidate: false,
    });

    var arrUserUpdate = dataDetail.user_update.map(
      (item, index) => dataDetail.user_update[index].username
    );
    var arrTagTeam = dataDetail.tag_team.map(
      (item, index) => dataDetail.tag_team[index].name
    );

    useEffect(() => {
      form.resetFields();
      if (open) {
        form.setFieldsValue({
          customer: dataDetail.customer[0].full_name,
          auto_extend: dataDetail.auto_extend,
          content: dataDetail.content,
          day_expired: dataDetail.day_expired,
          day_sign: dataDetail.day_sign,
          tag_team: arrTagTeam,
          type_document: dataDetail.type_document[0].name,
          user_create: dataDetail.user_create[0].username,
          user_update: arrUserUpdate,
          project: dataDetail.project,
          code_number: dataDetail.code_number,
          file_document: dataDetail.file_document.map((item, index) => {
            return item.name_file;
          }),
        });
      }
    }, [open]);

    const FirstStep = () => {
      const formatDate = (current) => {
        return moment(current).format(dateFormat);
      };

      return (
        <>
          {screen.width > 768 ? (
            <Row className="RowThuNhan" key={1}>
              <Col span={12}>
                <Form.Item
                  name="customer"
                  label="Tên khách hàng"
                  rules={[{ required: true }]}
                  key="customer"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="user_create"
                  label="Người cập nhật"
                  rules={[{ required: true }]}
                  key="user_create"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="type_document"
                  label="Phân loại tài liệu"
                  rules={[{ required: true }]}
                  key="type_document"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="project"
                  label="Tên dự án"
                  rules={[{ required: true }]}
                  key="project"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="user_update"
                  label="Người được chia sẻ"
                  rules={[{ required: true }]}
                  key="user_update"
                >
                  <Select
                    placeholder="Chọn người được chia sẻ"
                    showSearch={false}
                    optionFilterProp="children"
                    mode="multiple"
                    open={false}
                    maxTagCount="responsive"
                    disabled
                    key="user_update1"
                  >
                    {dataDetail.user_update.map((item, index) => (
                      <Option key={item.username} value={item.id}>
                        {item.username}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="code_number"
                  label="Số hiệu"
                  rules={[{ required: true }]}
                  key="code_number"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="tag_team" label="Team" key="tag_team">
                  <Select
                    placeholder="Chọn người được chia sẻ"
                    showSearch={false}
                    optionFilterProp="children"
                    mode="multiple"
                    maxTagCount="responsive"
                    open={false}
                    disabled
                    key="tag_team1"
                  >
                    {dataDetail.tag_team.map((item, index) => (
                      <Option key={item.name} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="auto_extend"
                  label="Tự động tái tục"
                  key="auto_extend"
                >
                  <Checkbox checked={dataDetail.auto_extend}></Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="day_sign"
                  label="Ngày kí kết"
                  rules={[{ required: true }]}
                  key="day_sign"
                >
                  <DatePicker
                    disabled
                    style={{ width: "100%" }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="day_expired"
                  label="Ngày hết hạn"
                  key="day_expired"
                >
                  <DatePicker
                    disabled
                    style={{ width: "100%" }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="content" label="Nội dung" key="content">
                  <Input.TextArea readOnly></Input.TextArea>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="file_document"
                  label="File Upload"
                  key="file_document"
                >
                  <div style={{ overflowY: "auto", height: 100 }}>
                    {dataDetail.file_document.map((item, index) => (
                      <Row style={{ marginBottom: "5px", width: "100%" }}>
                        <Col>
                          <Tag
                            style={{ fontSize: "12px" }}
                            color="blue"
                            value={item.name_file}
                            key={item.name_file}
                          >
                            <a
                              href={localhost + "/media/" + item.file_contract}
                              target="_blank"
                            >
                              {item.name_file}
                            </a>
                          </Tag>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <Row className="RowThuNhan" key={1}>
              <Col span={24}>
                <Form.Item
                  name="customer"
                  label="Tên khách hàng"
                  rules={[{ required: true }]}
                  key="customer"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="user_create"
                  label="Người cập nhật"
                  rules={[{ required: true }]}
                  key="user_create"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="type_document"
                  label="Phân loại tài liệu"
                  rules={[{ required: true }]}
                  key="type_document"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="project"
                  label="Tên dự án"
                  rules={[{ required: true }]}
                  key="project"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="user_update"
                  label="Người được chia sẻ"
                  rules={[{ required: true }]}
                  key="user_update"
                >
                  <Select
                    placeholder="Chọn người được chia sẻ"
                    showSearch={false}
                    optionFilterProp="children"
                    mode="multiple"
                    maxTagCount="responsive"
                    open={false}
                    disabled
                    key="user_update1"
                  >
                    {dataDetail.user_update.map((item, index) => (
                      <Option key={item.username} value={item.id}>
                        {item.username}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="code_number"
                  label="Số hiệu"
                  rules={[{ required: true }]}
                  key="code_number"
                >
                  <Input readOnly maxLength={100} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="tag_team" label="Team" key="tag_team">
                  <Select
                    placeholder="Chọn người được chia sẻ"
                    showSearch={false}
                    optionFilterProp="children"
                    mode="multiple"
                    maxTagCount="responsive"
                    open={false}
                    disabled
                    key="tag_team1"
                  >
                    {dataDetail.tag_team.map((item, index) => (
                      <Option key={item.name} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="auto_extend"
                  label="Tự động tái tục"
                  key="auto_extend"
                >
                  <Checkbox checked={dataDetail.auto_extend}></Checkbox>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="day_sign"
                  label="Ngày kí kết"
                  rules={[{ required: true }]}
                  key="day_sign"
                >
                  <DatePicker
                    disabled
                    style={{ width: "100%" }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="day_expired"
                  label="Ngày hết hạn"
                  key="day_expired"
                >
                  <DatePicker
                    disabled
                    style={{ width: "100%" }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="content" label="Nội dung" key="content">
                  <Input.TextArea readOnly></Input.TextArea>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="file_document"
                  label="File Upload"
                  key="file_document"
                >
                  <div style={{ overflowY: "auto", height: 100 }}>
                    {dataDetail.file_document.map((item, index) => (
                      <Row style={{ marginBottom: "5px", width: "100%" }}>
                        <Col>
                          <Tag
                            style={{ fontSize: "12px" }}
                            color="blue"
                            value={item.name_file}
                            key={item.name_file}
                          >
                            <a
                              href={localhost + "/media/" + item.file_contract}
                              target="_blank"
                            >
                              {item.name_file}
                            </a>
                          </Tag>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      );
    };

    const formList = [<FirstStep />];

    return (
      <Form {...layout} {...formProps} style={{ width: "100%" }} key="form">
        {formList[current]}
      </Form>
    );
  };

  return (
    <div>
      <BoxNewSchedule_pro />
    </div>
  );
};

const ModalEditContract = ({
  open,
  onCancel,
  dataInforUploadFile,
  pager,
  fetchData,
  loading,
}) => {
  const validateMessages = {
    required: "${label} không được để trống!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const onCloseModal = () => {
    onCancel();
  };

  const BoxNewSchedule_pro_edit = () => {
    const {
      form,
      current,
      gotoStep,
      stepsProps,
      formProps,
      submit,
      formLoading,
    } = useStepsForm({
      async submit(values) {
        var flag = false;
        if (dataInforUploadFile.file_document.length === 0) {
          onFinish(values);
          return "ok";
        }
        if (values.file_document.fileList === undefined) {
          onFinish(values);
        }
        for (let i = 0; i < dataInforUploadFile.file_document.length; i++) {
          for (let j = 0; j < values.file_document.fileList.length; j++) {
            if (
              dataInforUploadFile.file_document[i].name_file ===
              values.file_document.fileList[j].name
            ) {
              openNotificationWithIcon(
                "error",
                "Thất bại",
                `file ${values.file_document.fileList[j].name} đã có trong danh sách`
              );
              flag = true;
              return;
            } else {
              flag = false;
            }
          }
        }
        if (flag === false) {
          onFinish(values);
        }
        await new Promise((r) => setTimeout(r, 500));
        return "ok";
      },
      total: 2,
      isBackValidate: false,
    });

    const [team, setTeam] = useState([]);
    const [userShare, setUserShare] = useState([]);
    const [dateStart, setDateStart] = useState(dataInforUploadFile.day_sign);
    const [checkInfinite, setCheckInfinite] = useState(false);
    const [time, setTime] = useState(["Ngày", "Tháng", "Năm"]);
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const disabledDate = (current) => {
      return current && current < form.getFieldValue("day_sign").endOf("day");
    };

    const disabled = () => {
      return dateStart === undefined || dateStart === "Invalid date"
        ? true
        : false;
    };

    const GetListTeam = () => {
      GetManageTeam({
        page: 1, page_size: 5000
      }).then((res) => {
        var listTeam = res.data.results.filter((team) => team.is_active === true);

        setTeam(listTeam.sort((a, b) => a.name.localeCompare(b.name)));
      });
    };

    const GetListUser = () => {
      GetManageUser({
        page: 1, page_size: 5000
      }).then((res) => {
        var listUser = res.data.results.filter((user) => user.is_active === true);
        setUserShare(listUser.sort((a, b) => a.username.localeCompare(b.username)));
      });
    };

    useEffect(() => {
      GetListTeam();
      GetListUser();
    }, []);

    const [fileList, setFileList] = useState([]);

    var arrUserUpdate = dataInforUploadFile.user_update.map((item, index) =>
      item.is_active === false ? item.username : item.id
    );

    var arrTagTeam = dataInforUploadFile.tag_team.map((item, index) =>
      item.is_active === false ? item.name : item.id
    );

    useEffect(() => {
      form.resetFields();
      if (open) {
        form.setFieldsValue({
          pk: dataInforUploadFile.id,
          customer: dataInforUploadFile.customer[0].full_name,
          auto_extend: dataInforUploadFile.auto_extend,
          content: dataInforUploadFile.content,
          day_sign: moment(dataInforUploadFile.day_sign, "YYYY-MM-DD"),
          day_expired:
            dataInforUploadFile.day_expired === null
              ? null
              : moment(dataInforUploadFile.day_expired, "YYYY-MM-DD"),
          tag_team: arrTagTeam,
          type_document: dataInforUploadFile.type_document[0].name,
          user_create: dataInforUploadFile.user_create[0].username,
          user_update: arrUserUpdate,
          project: dataInforUploadFile.project,
          code_number: dataInforUploadFile.code_number,
          file_document: dataInforUploadFile.file_document,
          infinite: dataInforUploadFile.infinite,
          extension_time: dataInforUploadFile.extension_time,
          type_extension_time: dataInforUploadFile.type_extension_time,
        });
      }
    }, [open]);

    const onFinish = (values) => {
      setLoadingUpdate(true);
      var FormData = require("form-data");
      var data = new FormData();
      data.append("customer", dataInforUploadFile.customer[0].id);
      data.append("type_document", dataInforUploadFile.type_document[0].id);
      data.append("user_create", dataInforUploadFile.user_create[0].id);
      data.append("project", values.project.trim());
      data.append("user_update", values.user_update);
      data.append("code_number", values.code_number.trim());
      data.append("infinite", values.infinite);
      data.append(
        "tag_team",
        values.tag_team.length !== 0 ? values.tag_team : []
      );
      data.append(
        "extension_time",
        values.extension_time === undefined ||
          values.extension_time === null ||
          values.auto_extend === false
          ? ""
          : values.extension_time
      );
      data.append(
        "type_extension_time",
        values.auto_extend === true ? values.type_extension_time : ""
      );
      data.append("auto_extend", values.auto_extend);
      data.append("day_sign", moment(values.day_sign).format("YYYY-MM-DD"));
      data.append(
        "day_expired",
        values.day_expired === undefined || values.day_expired === null
          ? null
          : moment(values.day_expired).format("YYYY-MM-DD")
      );
      data.append("content", values.content === "null" ? "" : values.content);
      if (values.file_document.fileList == undefined) {
        values.file_document.fileList =
          dataInforUploadFile.file_document.fileList;
      } else {
        values.file_document.fileList.forEach((element) => {
          data.append("file_document", element.originFileObj);
        });
      }
      PutUploadFile(values.pk, data)
        .then((res) => {
          if (res.data.error) {
            setLoadingUpdate(false);
            openNotificationWithIcon("error", res.data.error);
          } else if (res.data.warning) {
            setLoadingUpdate(true);
            fetchData({ page: pager.current, page_size: pager.pageSize });
            onCancel();
            openNotificationWithIcon("warning", res.data.warning);
          } else {
            fetchData({ page: pager.current, page_size: pager.pageSize });
            openNotificationWithIcon(
              "success",
              "Thành công",
              "Chỉnh sửa hồ sơ thành công"
            );
            setLoadingUpdate(true);
            onCancel();
          }
        })
        .catch((err) => {
          setLoadingUpdate(false);
          if (err.status === 400) {
            openNotificationWithIcon("error", err.data.error);
          }
          if (err.status === 413) {
            openNotificationWithIcon(
              "error",
              "Thất bại",
              "Dung lượng tải lên quá lớn"
            );
          }
        });
    };
    const [checkBoxTN, setCheckBox] = useState(dataInforUploadFile.auto_extend);

    const FirstStep = () => {
      form.setFieldsValue({
        type_extension_time:
          dataInforUploadFile.type_extension_time == null
            ? time[0]
            : dataInforUploadFile.type_extension_time,
      });
      form.setFieldsValue({
        extension_time:
          dataInforUploadFile.extension_time == null
            ? 1
            : dataInforUploadFile.extension_time,
      });
      const disabledInfinite = () => {
        return form.getFieldValue("infinite") === true ? true : false;
      };

      const disabledAutoExtend = () => {
        return checkBoxTN === true ? true : false;
      };
      const formateDate = (current) => {
        return moment(current).format(dateFormat);
      };
      const [lsID, setLsID] = useState([]);
      const [lsFile, setLsFile] = useState(
        dataInforUploadFile.file_document.filter(
          (item) => item.is_active == true
        )
      );
      const deleteFile = (record) => {
        DeleteFileDocument({ id: record.id })
          .then((res) => {
            openNotificationWithIcon("success", res.data.result);
            setLsID([...lsID, record.id]);
            setLsFile(
              lsFile.filter((item) => ![...lsID, record.id].includes(item.id))
            );
          })
          .catch((err) => {
            openNotificationWithIcon(
              "error",
              "Thất bại",
              "Xóa hồ sơ không thành công"
            );
          });
      };
      return (
        <>
          {screen.width > 768 ? (
            // Chỉnh sửa Hồ sơ chưa responsive
            <Row className="RowThuNhan">
              <Col span={12}>
                <Form.Item
                  name="customer"
                  label="Tên khách hàng"
                  rules={[{ required: true }]}
                  key="customer"
                >
                  <Input style={{ color: "black" }} disabled maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="type_document"
                  label="Loại tài liệu"
                  rules={[{ required: true }]}
                  key="type_document"
                >
                  <Input style={{ color: "black" }} disabled maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="user_update"
                  label="Người được chia sẻ"
                  rules={[{ required: true }]}
                  key="user_update"
                >
                  <Select
                    placeholder="Chọn người được chia sẻ"
                    showSearch={false}
                    optionFilterProp="children"
                    mode="multiple"
                    maxTagCount="responsive"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    key="user_share"
                  >
                    {userShare.map((item, index) => (
                      <Option key={item.name} value={item.id}>
                        {item.username}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="project"
                  label="Dự án"
                  rules={[{ required: true }]}
                  key="project"
                >
                  <Input maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="tag_team" label="Team" key="tag_team">
                  <Select
                    placeholder="Chọn Team"
                    showSearch={false}
                    style={{
                      width: "100%",
                    }}
                    allowClear
                    mode="multiple"
                    maxTagCount="responsive"
                    key="team1"
                  >
                    {team.map((item, index) => (
                      <Option key={item.name} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="code_number"
                  label="Số hiệu"
                  rules={[{ required: true }]}
                  key="code_number"
                >
                  <Input maxLength={100} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="day_sign"
                  label="Ngày kí kết"
                  rules={[{ required: true }]}
                  key="day_sign"
                >
                  <DatePicker
                    onChange={(value) =>
                      setDateStart(moment(value).format(dateFormat))
                    }
                    style={{ width: "100%" }}
                    format={formateDate}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="day_expired"
                  label="Ngày hết hạn"
                  // rules={[{ required: true }]}
                  key="day_expired"
                >
                  <DatePicker
                    disabled={disabled()}
                    style={{ width: "100%" }}
                    format={formateDate}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="content" label="Nội dung" key="content">
                  <Input.TextArea></Input.TextArea>
                </Form.Item>
              </Col>

              <Col span={12}>
                <div className="container" style={{ display: "flex" }}>
                  <div style={{ width: "50%" }}>
                    <Form.Item
                      label={
                        <span style={{ marginRight: 16 }}>Vô thời hạn:</span>
                      }
                      name="infinite"
                      valuePropName={"checked"}
                      key="infinite"
                      style={{ width: "100%", marginLeft: 74 }}
                    >
                      <Checkbox
                        disabled={disabledAutoExtend()}
                        defaultChecked={form.getFieldValue("infinite")}
                        onChange={(e) => {
                          setCheckInfinite(!checkInfinite);
                          form.setFieldsValue({ infinite: e.target.checked });
                        }}
                        style={{ marginLeft: 5 }}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Form.Item
                      label={<span>Tự động tái tục</span>}
                      name="auto_extend"
                      valuePropName={"checked"}
                      key="auto_extend"
                      style={{ width: "150%" }}
                    >
                      <Checkbox
                        disabled={disabledInfinite()}
                        defaultChecked={dataInforUploadFile.auto_extend}
                        onChange={(e) => {
                          setCheckBox(e.target.checked ? true : false);
                          form.setFieldsValue({
                            auto_extend: e.target.checked,
                          });
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="file_document"
                  label="File Upload"
                  key="file_document"
                >
                  <div style={{ overflowY: "auto", height: 100 }}>
                    {lsFile.map((item, index) => (
                      <Row style={{ marginBottom: "5px", width: "100%" }}>
                        <Col span={22}>
                          <Tag
                            style={{ fontSize: "12px" }}
                            color="blue"
                            value={item.name_file}
                            key={item.name_file}
                          >
                            {item.name_file}
                          </Tag>
                        </Col>
                        <Col span={1}>
                          <span
                            title="Xóa file"
                            className={"col-6"}
                            style={{ color: "#4290d3", cursor: "pointer" }}
                          >
                            <Popconfirm
                              placement="top"
                              title={"Bạn có chắc muốn xóa file này?"}
                              onConfirm={() => deleteFile(item)}
                              okText="Có"
                              cancelText="Không"
                            >
                              <DeleteOutlined
                                style={{ color: "red", cursor: "pointer" }}
                              />
                            </Popconfirm>
                          </span>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Form.Item>
              </Col>

              <Col span={12}>
                {checkBoxTN ? (
                  <Row style={{ width: "100%" }}>
                    <Col span={8}></Col>
                    <Col span={5}>
                      <Form.Item
                        name="extension_time"
                        key="extension_time"
                        rules={[
                          {
                            required: true,
                            type: "number",
                            max: 99,
                            message: "Tối đa 2 chữ số",
                          },
                        ]}
                      >
                        <InputNumber min={1} defaultValue={1} type="number" />
                      </Form.Item>
                    </Col>
                    <Col span={11} style={{ width: "100%" }}>
                      <Form.Item
                        name="type_extension_time"
                        key="type_extension_time"
                        rules={[
                          {
                            required: true,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Chọn mốc thời gian"
                          showSearch={false}
                          style={{
                            width: 218,
                          }}
                          key="time"
                        >
                          {time.map((item, index) => (
                            <Option key={item.toString()} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Col>

              <Col span={24} className="btnModal">
                <Form.Item {...tailLayout} key="btn">
                  <Button
                    variant="contained"
                    onClick={() => {
                      form.getFieldValue("auto_extend") === true
                        ? form.getFieldValue("day_expired") > moment(new Date())
                          ? form.getFieldValue("day_expired") >
                            form.getFieldValue("day_sign")
                            ? gotoStep(current + 1)
                            : openNotificationWithIcon(
                              "error",
                              "Thất bại",
                              "Ngày hết hạn phải lớn hơn ngày kí kết"
                            )
                          : openNotificationWithIcon(
                            "error",
                            "Thất bại",
                            "Ngày hết hạn phải lớn hơn ngày hiện tại"
                          )
                        : form.getFieldValue("day_expired") === undefined ||
                          form.getFieldValue("day_expired") === null ||
                          form.getFieldValue("day_expired") >
                          form.getFieldValue("day_sign")
                          ? gotoStep(current + 1)
                          : openNotificationWithIcon(
                            "error",
                            "Thất bại",
                            "Ngày hết hạn phải lớn hơn ngày kí kết"
                          );
                    }}
                  >
                    Tiếp theo
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          ) : (
            // Đã responsive với màn hình < 768
            <div style={{ height: 200, display: "inline" }}>
              <Col span={24}>
                <Form.Item
                  name="customer"
                  label="Tên khách hàng"
                  rules={[{ required: true }]}
                  key="customer"
                >
                  <Input style={{ color: "black" }} disabled maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="type_document"
                  label="Loại tài liệu"
                  rules={[{ required: true }]}
                  key="type_document"
                >
                  <Input style={{ color: "black" }} disabled maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="user_update"
                  label="Người được chia sẻ"
                  rules={[{ required: true }]}
                  key="user_update"
                >
                  <Select
                    placeholder="Chọn người được chia sẻ"
                    showSearch={false}
                    optionFilterProp="children"
                    mode="multiple"
                    maxTagCount="responsive"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    key="user_share"
                  >
                    {userShare.map((item, index) => (
                      <Option key={item.name} value={item.id}>
                        {item.username}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="project"
                  label="Dự án"
                  rules={[{ required: true }]}
                  key="project"
                >
                  <Input maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="tag_team" label="Team" key="tag_team">
                  <Select
                    placeholder="Chọn Team"
                    showSearch={false}
                    style={{
                      width: "100%",
                    }}
                    allowClear
                    mode="multiple"
                    maxTagCount="responsive"
                    key="team1"
                  >
                    {team.map((item, index) => (
                      <Option key={item.name} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="code_number"
                  label="Số hiệu"
                  rules={[{ required: true }]}
                  key="code_number"
                >
                  <Input maxLength={100} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="day_sign"
                  label="Ngày kí kết"
                  rules={[{ required: true }]}
                  key="day_sign"
                >
                  <DatePicker
                    onChange={(value) =>
                      setDateStart(moment(value).format(dateFormat))
                    }
                    style={{ width: "100%" }}
                    format={formateDate}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="day_expired"
                  label="Ngày hết hạn"
                  // rules={[{ required: true }]}
                  key="day_expired"
                >
                  <DatePicker
                    disabled={disabled()}
                    style={{ width: "100%" }}
                    format={formateDate}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Vô thời hạn"
                  name="infinite"
                  valuePropName={"checked"}
                  key="infinite"
                >
                  <Checkbox
                    disabled={disabledAutoExtend()}
                    defaultChecked={form.getFieldValue("infinite")}
                    onChange={(e) => {
                      setCheckInfinite(!checkInfinite);
                      form.setFieldsValue({ infinite: e.target.checked });
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Tự động tái tục"
                  name="auto_extend"
                  valuePropName={"checked"}
                  key="auto_extend"
                >
                  <Checkbox
                    disabled={disabledInfinite()}
                    defaultChecked={dataInforUploadFile.auto_extend}
                    onChange={(e) => {
                      setCheckBox(e.target.checked ? true : false);
                      form.setFieldsValue({ auto_extend: e.target.checked });
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                {checkBoxTN ? (
                  <Row style={{ width: "100%" }}>
                    <Col span={6}>
                      <Form.Item
                        name="extension_time"
                        key="extension_time"
                        rules={[
                          {
                            required: true,
                            type: "number",
                            max: 99,
                            message: "Tối đa 2 chữ số",
                          },
                        ]}
                      >
                        <InputNumber
                          min={1}
                          style={{ width: 55 }}
                          type="number"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={8} style={{ width: "100%" }}>
                      <Form.Item
                        name="type_extension_time"
                        key="type_extension_time"
                        rules={[
                          {
                            required: true,
                            message: "Không được để trống",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Chọn mốc thời gian"
                          showSearch={false}
                          style={{
                            width: 200,
                            marginLeft: "5%",
                          }}
                          key="time"
                        >
                          {time.map((item, index) => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Col>
              <Col span={24}>
                <Form.Item name="content" label="Nội dung" key="content">
                  <Input.TextArea></Input.TextArea>
                </Form.Item>
              </Col>

              <Col span={24} className="btnModal">
                <Form.Item {...tailLayout} key="btn">
                  <Button
                    variant="contained"
                    onClick={() => {
                      form.getFieldValue("auto_extend") === true
                        ? form.getFieldValue("day_expired") > moment(new Date())
                          ? form.getFieldValue("day_expired") >
                            form.getFieldValue("day_sign")
                            ? gotoStep(current + 1)
                            : openNotificationWithIcon(
                              "error",
                              "Thất bại",
                              "Ngày hết hạn phải lớn hơn ngày kí kết"
                            )
                          : openNotificationWithIcon(
                            "error",
                            "Thất bại",
                            "Ngày hết hạn phải lớn hơn ngày hiện tại"
                          )
                        : form.getFieldValue("day_expired") === undefined ||
                          form.getFieldValue("day_expired") === null ||
                          form.getFieldValue("day_expired") >
                          form.getFieldValue("day_sign")
                          ? gotoStep(current + 1)
                          : openNotificationWithIcon(
                            "error",
                            "Thất bại",
                            "Ngày hết hạn phải lớn hơn ngày kí kết"
                          );
                    }}
                  >
                    Tiếp theo
                  </Button>
                </Form.Item>
              </Col>
            </div>
          )}
        </>
      );
    };

    const SecondStep = () => {
      const handleChange = (info) => {
        let newFileList = [...info.fileList];
        setFileList(newFileList);
      };

      return (
        <Spin spinning={loadingUpdate}>
          {screen.width > 768 ? (
            // Màn hình > 768
            <Row>
              <Col span={18}>
                <p
                  style={{
                    marginLeft: "33%",
                    color: "#FF463F",
                    fontStyle: "italic",
                    marginBottom: 0,
                  }}
                  className="ant-upload-text"
                >
                  Tổng dung lượng File tối đa 50MB !
                </p>
                <Form.Item
                  name="file_document"
                  label={<FolderIcon />}
                  key="folder"
                >
                  <Dragger
                    accept="image/png, image/jpeg, .pdf, .xlsx,.docx"
                    name="file"
                    openFileDialogOnClick={true}
                    defaultFileList={fileList}
                    fileList={fileList}
                    onChange={(info) => handleChange(info)}
                    showUploadList={true}
                    multiple={true}
                    beforeUpload={(file, FileList) => {
                      const isJpgOrPng =
                        file.type !== "application/pdf" &&
                        file.type !== "image/png" &&
                        file.type !== "image/jpeg" &&
                        file.type !==
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                        file.type !==
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                      const checkSize = file.size / 1024 / 1024 > 5;

                      if (isJpgOrPng) {
                        openNotificationWithIcon(
                          "error",
                          "Lỗi",
                          `Sai định dạng file !`
                        );
                        return Upload.LIST_IGNORE;
                      }
                      if (checkSize) {
                        openNotificationWithIcon("error", "Lỗi", `Quá 5MB !`);
                      }

                      return !isJpgOrPng && !checkSize
                        ? false
                        : Upload.LIST_IGNORE;
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click hoặc kéo thả để chọn file
                    </p>
                    <p className="ant-upload-hint">
                      Chọn file trên thiết bị của bạn (
                      <span style={{ color: "#FF463F", fontStyle: "italic" }}>
                        Hỗ trợ định dạng Hình ảnh *.png, pdf, xlsx, docx
                      </span>
                      )
                    </p>
                  </Dragger>
                </Form.Item>
              </Col>
              <Col span={24} className="btnModal">
                <Form.Item {...tailLayout} key="2">
                  <LoadingButton
                    style={{ marginRight: 10 }}
                    variant="contained"
                    color="success"
                    // loading={loadingUpdate}
                    type="submit"
                    onClick={(values) => {
                      form.validateFields().then((r) => {
                        submit().then((result) => {
                          var newSize = 0;
                          var oldSize = 0;
                          dataInforUploadFile.file_document.map((item) => {
                            oldSize += item.size;
                          });
                          if (r.file_document.fileList !== undefined) {
                            r.file_document.fileList.map((item) => {
                              newSize += item.size;
                            });
                          }
                          const listSize =
                            newSize / 1024 / 1024 + oldSize / 1024 / 1024 > 5;
                          if (listSize) {
                          }
                        });
                      });
                    }}
                  >
                    Chỉnh sửa
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      gotoStep(current - 1);
                    }}
                  // disabled={loadingUpdate}
                  >
                    Quay lại
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          ) : (
            // Màn hình < 768
            <>
              <Col span={24}>
                <p
                  style={{
                    marginLeft: "14%",
                    color: "#FF463F",
                    fontStyle: "italic",
                    marginBottom: 0,
                  }}
                  className="ant-upload-text"
                >
                  Tổng dung lượng File tối đa 50MB !
                </p>
                <Form.Item
                  name="file_document"
                  // label={<FolderIcon />}
                  key="folder"
                  style={{ marginBottom: 50 }}
                >
                  <Dragger
                    accept="image/png, image/jpeg, .pdf, .xlsx,.docx"
                    name="file"
                    openFileDialogOnClick={true}
                    defaultFileList={fileList}
                    // rules={[{ required: true }]}
                    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
                    fileList={fileList}
                    // maxCount={1}
                    style={{ width: "100%" }}
                    onChange={(info) => handleChange(info)}
                    showUploadList={true}
                    multiple={true}
                    beforeUpload={(file, FileList) => {
                      const isJpgOrPng =
                        file.type !== "application/pdf" &&
                        file.type !== "image/png" &&
                        file.type !== "image/jpeg" &&
                        file.type !==
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                        file.type !==
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                      const checkSize = file.size / 1024 / 1024 > 5;

                      if (isJpgOrPng) {
                        openNotificationWithIcon(
                          "error",
                          "Lỗi",
                          `Sai định dạng file !`
                        );
                        return Upload.LIST_IGNORE;
                      }
                      if (checkSize) {
                        openNotificationWithIcon("error", "Lỗi", `Quá 5MB !`);
                      }

                      return !isJpgOrPng && !checkSize
                        ? false
                        : Upload.LIST_IGNORE;
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click hoặc kéo thả để chọn file
                    </p>
                    <p className="ant-upload-hint">
                      Chọn file trên thiết bị của bạn (
                      <span style={{ color: "#FF463F", fontStyle: "italic" }}>
                        Hỗ trợ định dạng Hình ảnh *.png, pdf, xlsx, docx
                      </span>
                      )
                    </p>
                  </Dragger>
                </Form.Item>
              </Col>
              <Col span={24} className="btnModal">
                <Form.Item {...tailLayout} key="2">
                  <LoadingButton
                    style={{ marginRight: 10 }}
                    variant="contained"
                    color="success"
                    // loading={loadingUpdate}
                    type="submit"
                    onClick={(values) => {
                      form.validateFields().then((r) => {
                        submit().then((result) => {
                          var newSize = 0;
                          var oldSize = 0;
                          dataInforUploadFile.file_document.map((item) => {
                            oldSize += item.size;
                          });
                          if (r.file_document.fileList !== undefined) {
                            r.file_document.fileList.map((item) => {
                              newSize += item.size;
                            });
                          }
                          const listSize =
                            newSize / 1024 / 1024 + oldSize / 1024 / 1024 > 5;
                          if (listSize) {
                          }
                        });
                      });
                    }}
                  >
                    Chỉnh sửa
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      gotoStep(current - 1);
                    }}
                  >
                    Quay lại
                  </Button>
                </Form.Item>
              </Col>
            </>
          )}
        </Spin>
      );
    };

    const formList = [<FirstStep />, <SecondStep />];

    return (
      <div>
        <Steps current={current}>
          <Step title="Khai báo thông tin" />
          <Step title="Upload File" />
        </Steps>

        <div style={{ marginTop: 30 }}>
          <Form
            {...layout}
            {...formProps}
            style={{ width: "100%" }}
            validateMessages={validateMessages}
          >
            {formList[current]}
          </Form>

          {current === 2 && (
            <Result
              status="success"
              title="Submit is succeed!"
              extra={
                <>
                  <Button
                    onClick={() => {
                      form.resetFields();
                    }}
                  >
                    Tạo đăng ký mới
                  </Button>
                </>
              }
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      title="Chỉnh sửa chứng từ"
      visible={open}
      onCancel={onCloseModal}
      width={screen.width > 768 ? 1000 : "80%"}
      footer={false}
      bodyStyle={
        screen.width > 768
          ? null
          : { overflowY: "auto", maxHeight: "calc(50vh - 145px)" }
      }
    >
      <div>
        <BoxNewSchedule_pro_edit />
      </div>
    </Modal>
  );
};
const { Option } = Select;

const LogDocument = ({ dataLog, idLogContract }) => {
  const [pageSize, setPageSize] = useState(10);
  const [pager, setPager] = useState({
    pageSize: pageSize,
    count: 0,
    current: 1,
  });


  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
      width: "3%",
    },

    {
      title: "Nội dung thao tác",
      key: "content",
      align: "center",
      dataIndex: "content",
      ellipsis: true,
      width: "15%",
      render: (record) => record,
    },

    {
      title: "Thời gian thao tác",
      key: "updated",
      align: "center",
      dataIndex: "updated",
      ellipsis: true,
      width: "15%",
      render: (record) =>
        moment(record.updated).format("DD-MM-YYYY, hh:mm:ss"),
    },
    {
      title: "User thao tác",
      key: "user_log",
      align: "center",
      dataIndex: "user_log",
      ellipsis: true,
      width: "6%",
      render: (text, record, index) => record.user_log[0].username,
    },
  ];
  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = pagination.pageSize;
    setPager({ ...currentPager });
  };

  useEffect(() => {
    setPager({
      pageSize: pageSize,
      count: 0,
      current: 1,
    });
  }, [idLogContract]);

  useEffect(() => {
    setPager({
      pageSize: pageSize,
      count: 0,
      current: 1,
    });
  }, [dataLog]);

  return (
    <Table
      columns={columns}
      dataSource={dataLog}
      onChange={handleChange}
      rowClassName={"styleTableLeTet1"}
      locale={{ emptyText: "Không có dữ liệu" }}
      pagination={{
        current: pager.current,
        pageSize: pageSize,
        // showQuickJumper: true,
        // locale: {
        //   jump_to: "Đến trang: ",
        //   page: "",
        // },
      }}
      size="middle"
    />
  );
};

function ThuNhanTatCa() {
  const defaultPageSize = 10;
  const [pager, setPager] = useState({
    pageSize: defaultPageSize,
    count: 0,
    current: 1,
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [typeDocument, setTypeDocument] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [team, setTeam] = useState([]);
  const [loading_page, setLoading_page] = useState(false);
  const [dataDetail, setDataDetail] = useState();
  const [pageSize, setPageSize] = useState(10);
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const [openInputSearch, setOpenInputSearch] = useState(false);
  const [isModalTable, setIsModalTable] = useState(false);

  const fetchData = (params = {}) => {
    setLoading(true);
    GetManageUploadAll(params)
      .then((res) => {
        setData(res.data.results);
        setPager({
          current: params.page,
          pageSize: params.page_size,
          count: res.data.count,
        });
        setLoading(false);
      })
      .catch((err) => {
        errorHandle(err);
        setLoading(false);
      });
  };

  const GetListCustomer = () => {
    GetManageCustomer({
      page: 1, page_size: 5000
    }).then((res) => {
      var listCustomer = res.data.results.filter(
        (customer) => customer.is_active === false
      );
      listCustomer.sort((a, b) => {
        // var keyA = a.full_name
        //   ,
        //   keyB = b.full_name
        //   ;
        // Compare the 2 dates
        // if (keyA < keyB) return -1;
        // if (keyA > keyB) return 1;
        // return 0
        return a.full_name.localeCompare(b.full_name)

      });
      setCustomer(listCustomer);
    });
  };

  const GetListDocument = () => {
    GetListTypeDocument({
      page: 1, page_size: 5000
    }).then((res) => {
      var listTypeDocument = res.data.results.filter(
        (docs) => docs.is_active === false
      );
      listTypeDocument.sort((a, b) => {
        var keyA = a.name
          ,
          keyB = b.name
          ;
        // Compare the 2 dates
        // if (keyA < keyB) return -1;
        // if (keyA > keyB) return 1;
        return a.name.localeCompare(b.name)
      });
      setTypeDocument(listTypeDocument);
    });
  };

  useEffect(() => {
    GetListCustomer();
    GetListDocument();
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      fetchData({ page: 1, page_size: pager.pageSize, search });
      clearInterval(id);
    }, 300);
    return () => clearInterval(id);
  }, [search]);

  const onShowModalEdit = (record) => {
    GetInforUpload(record.id)
      .then((res) => {
        setDataDetail(res.data);
        setIsEditing(true);
      })
      .catch((err) => {
        if (err) {
          openNotificationWithIcon("error", err);
        }
      });
  };

  const [idLogContract, setIdLogContract] = useState();

  const onShowModalDetail = (record) => {
    GetInforUpload(record.id)
      .then((res) => {
        setDataDetail(res.data);
        setIsModalTable(true);
        setIsDetail(true);
        setIdLogContract(record.id);
      })
      .catch((err) => {
        if (err) {
          openNotificationWithIcon("error", err);
        }
      });
  };

  const [dataLog, setDataLog] = useState([]);

  const onLogContract = (record) => {
    LogContract({ id_document_log: record.id })
      .then((res) => {
        setDataLog(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteUpload = (record) => {
    DeleteUploadApi({ id_document: record.id })
      .then((res) => {
        fetchData({ page: 1, page_size: pager.pageSize, search });
        openNotificationWithIcon(
          "success",
          "Thành công",
          "Xóa hồ sơ thành công"
        );
      })
      .catch((error) => {
        openNotificationWithIcon("error", "Thất bại", error.data.error);
      });
  };

  const content = (record) => {
    const lsFile = [];
    record &&
      record.file_document.map((file) => {
        lsFile.push(<p>{file.name_file}</p>);
      });
    return lsFile;
  };

  const fileDownload_Staff = (record) => {
    setLoading_page(true);
    const fnFileDownload = require("js-file-download");
    const filename =
      "DanhSachFile_" + moment(new Date()).format("DDMMYYYY") + ".zip";
    DownloadFile(record.id, { responseType: "blob" })
      .then((res) => {
        fnFileDownload(res.data, filename);
        setLoading_page(false);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Không tải được danh sách hồ sơ. Bạn vui lòng thử lại."
        );
        setLoading_page(false);
      });
  };

  const renderDetailsButton = (record) => {
    return (
      <Box sx={{ width: "100%" }} style={{ marginLeft: 8 }}>
        {userInfo.role_name === "Admin" || userInfo.role_name === "Manage_Admin" ? (
          <Grid item container xs={12}>
            <Grid
              xs={3}
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <span
                title="Chỉnh sửa"
                className={"col-6"}
                style={{ color: "#4290d3", cursor: "pointer" }}
                onClick={() => onShowModalEdit(record)}
              >
                <EditOutlined />
              </span>
            </Grid>

            <Grid
              xs={3}
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Popover
                placement="topLeft"
                content={() => content(record)}
                arrowPointAtCenter
                key="123"
              >
                <button
                  style={{ border: "none", background: "none" }}
                  onClick={() => fileDownload_Staff(record)}
                >
                  <DownloadOutlined
                    style={{ color: "green", cursor: "pointer" }}
                  />
                </button>
              </Popover>
            </Grid>

            <Grid
              xs={3}
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <span
                title="Xóa hồ sơ"
                className={"col-6"}
                style={{ color: "#4290d3", cursor: "pointer" }}
              >
                <Popconfirm
                  placement="top"
                  title={"Bạn có chắc muốn xóa hồ sơ này?"}
                  onConfirm={() => deleteUpload(record)}
                  okText="Có"
                  cancelText="Không"
                >
                  <DeleteOutlined style={{ color: "red", cursor: "pointer" }} />
                </Popconfirm>
              </span>
            </Grid>
          </Grid>
        ) : (
          <Grid item container xs={12}>
            <Grid xs={2}></Grid>
            {/* <Grid
              xs={3}
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <span
                title="Chỉnh sửa"
                className={"col-6"}
                style={{ color: "#4290d3", cursor: "pointer" }}
                onClick={() => onShowModalEdit(record)}
              >
                <EditOutlined />
              </span>
            </Grid> */}

            <Grid
              xs={3}
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Popover
                placement="topLeft"
                content={() => content(record)}
                arrowPointAtCenter
                key="123"
              >
                <button
                  style={{ border: "none", background: "none" }}
                  onClick={() => fileDownload_Staff(record)}
                >
                  <DownloadOutlined
                    style={{ color: "green", cursor: "pointer" }}
                  />
                </button>
              </Popover>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
      width: "5%",
    },

    {
      title: "Tên khách hàng",
      key: "customer",
      align: "center",
      width: "15%",
      dataIndex: "customer",
      ellipsis: true,
      render: (text, record, index) => record.customer[0].full_name,
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            onShowModalDetail(record);
            setIdLogContract(record.id);
            onLogContract(record);
          },
        };
      },
    },

    {
      title: "Phân loại tài liệu",
      key: "type_document",
      align: "center",
      dataIndex: "type_document",
      ellipsis: true,
      width: "15%",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            onShowModalDetail(record);
            setIdLogContract(record.id);

            onLogContract(record);
          },
        };
      },
      render: (text, record, index) => record.type_document[0].name,
    },

    {
      title: "Tên dự án",
      key: "project",
      align: "center",
      dataIndex: "project",
      ellipsis: true,
      width: "13%",
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            onShowModalDetail(record);
            setIdLogContract(record.id);
            onLogContract(record);
          },
        };
      },
      render: (text, record, index) => record.project,
    },

    {
      title: "Tự động tái tục",
      key: "auto_extend",
      align: "center",
      dataIndex: "auto_extend",
      width: "8%",
      ellipsis: true,
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            onShowModalDetail(record);
            setIdLogContract(record.id);
            onLogContract(record);
          },
        };
      },
      render: (text, record, index) =>
        record.auto_extend === false ? (
          <CloseOutlined style={{ color: "red" }} />
        ) : (
          <CheckOutlined style={{ color: "green" }} />
        ),
    },

    {
      title: "Ngày kí kết",
      key: "day_sign",
      align: "center",
      dataIndex: "day_sign",
      ellipsis: true,
      width: "8%",

      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            onShowModalDetail(record);
            setIdLogContract(record.id);
            onLogContract(record);
          },
        };
      },
      render: (text, record, index) =>
        moment(record.day_sign).format(dateFormat),
    },

    {
      title: "Ngày hết hạn",
      key: "day_expired",
      align: "center",
      dataIndex: "day_expired",
      ellipsis: true,
      width: "8%",

      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            onShowModalDetail(record);
            setIdLogContract(record.id);
            onLogContract(record);
          },
        };
      },
      render: (text, record, index) =>
        record.day_expired ? moment(record.day_expired).format(dateFormat) : "",
    },

    {
      title: "Ngày cập nhật",
      key: "updated",
      align: "center",
      dataIndex: "updated",
      ellipsis: true,
      width: "8%",

      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            onShowModalDetail(record);
            setIdLogContract(record.id);
            onLogContract(record);
          },
        };
      },
      render: (text, record, index) =>
        moment(record.updated).format(dateFormat),
    },

    {
      title: "Người cập nhật",
      key: "user_create",
      align: "center",
      dataIndex: "user_create",
      ellipsis: true,
      width: "8%",

      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            onShowModalDetail(record);
            setIdLogContract(record.id);
            onLogContract(record);
          },
        };
      },
      render: (text, record, index) => record.user_create[0].username,
    },

    {
      title: "Thao tác",
      align: "center",
      width: "8%",
      render: renderDetailsButton,
    },
  ];
  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = pagination.pageSize;
    setPager({ ...currentPager });
    fetchData({
      status: currentPager.status,
      page_size: pagination.pageSize,
      page: pagination.current,
      customer: JSON.stringify(datafilter.customer),
      type_document: JSON.stringify(datafilter.type_document),
      user_update: datafilter.user_update,
      auto_extend: datafilter.auto_extend,
      day_expired: datafilter.day_expired,
      day_sign: datafilter.day_sign,
      project: datafilter.project,
    });
  };

  const onSearch = (e) => {
    setSearch(e);
  };

  const openSearch = () => {
    setOpenInputSearch(!openInputSearch);
  };

  const [datafilter, setDataFilter] = useState({
    customer: undefined,
    type_document: undefined,
    project: "",
    auto_extend: "",
    user_update: "",
    day_sign: "",
    day_expired: "",
  });
  const FilterContract = () => {
    const [form] = Form.useForm();
    const formatDate = (current) => {
      return moment(current).format(dateFormat);
    };

    const onFinish = (values) => {
      fetchData({
        page: 1,
        page_size: pager.pageSize,
        customer:
          values.customer === undefined
            ? undefined
            : values.customer.length !== 0
              ? JSON.stringify(values.customer)
              : undefined,
        type_document:
          values.type_document === undefined
            ? undefined
            : values.type_document.length !== 0
              ? JSON.stringify(values.type_document)
              : undefined,
        project: values.project,
        auto_extend: values.auto_extend,
        user_update: values.user_update,
        day_sign: values.day_sign,
        day_expired: values.day_expired,
      });
      setDataFilter({
        customer: values.customer,
        type_document: values.type_document,
        project: values.project,
        auto_extend: values.auto_extend,
        user_update: values.user_update,
        day_sign: values.day_sign,
        day_expired: values.day_expired,
      });
    };

    const onCloseFilter = () => {
      form.resetFields();
      setDataFilter({
        customer: undefined,
        type_document: undefined,
        project: "",
        auto_extend: "",
        user_update: "",
        day_sign: "",
        day_expired: "",
      });
      fetchData({ page: 1, page_size: pager.pageSize, search });
    };

    return (
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          customer: datafilter.customer,
          type_document: datafilter.type_document,
          project: datafilter.project,
          auto_extend: datafilter.auto_extend,
          user_update: datafilter.user_update,
          day_sign: datafilter.day_sign,
          day_expired: datafilter.day_expired,
        }}
        key="form"
      >
        <Row key={1} style={{ height: 70 }}>
          <Col span={5}>
            <Form.Item name="day_sign" label="Ngày kí kết" key="day_sign">
              <DatePicker
                placeholder="Chọn ngày"
                style={{ width: "100%" }}
                format={formatDate}
              />
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={5}>
            <Form.Item
              name="day_expired"
              label="Ngày hết hạn"
              key="day_expired"
            >
              <DatePicker
                placeholder="Chọn ngày"
                style={{ width: "100%" }}
                format={formatDate}
              />
            </Form.Item>
          </Col>
          <Col span={1}></Col>

          <Col span={5}>
            <Form.Item
              name="type_document"
              label="Phân loại tài liệu"
              key="type_document"
            >
              <Select
                placeholder="Chọn loại tài liệu"
                style={{
                  width: "100%",
                }}
                key="type_document1"
                allowClear
                mode="multiple"
                maxTagCount="responsive"
                optionFilterProp="children"

              >
                {typeDocument.map((item, index) => (
                  <Option key={item.name} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={5}>
            <Form.Item name="project" label="Tên dự án" key="project">
              <Input placeholder="Nhập tên dự án" maxLength={100} />
            </Form.Item>
          </Col>
        </Row>

        <Row key={2}>
          <Col span={5}>
            <Form.Item name="customer" label="Tên khách hàng" key="customer">
              <Select
                placeholder="Chọn tên khách hàng"
                optionFilterProp="children"
                key="customer1"
                mode="multiple"
                allowClear
                maxTagCount="responsive"
              >
                {customer.map((item, index) => (
                  <Option key={item.id} value={item.id}>
                    {item.full_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={5}>
            <Form.Item
              name="user_update"
              label="Người cập nhật"
              key="user_update"
            >
              <Input placeholder="Nhập người cập nhật" maxLength={100} />
            </Form.Item>
          </Col>
          <Col span={1}></Col>

          <Col span={5}>
            <Form.Item
              name="auto_extend"
              label="Tự động tái tục"
              key="auto_extend"
            >
              <Select
                placeholder="Chọn Có hoặc Không"
                optionFilterProp="children"
                key="customer1"
                allowClear
              >
                <Option key="co" value={"True"}>
                  Có
                </Option>
                <Option key="khong" value={"False"}>
                  Không
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={5}>
            <Form.Item key="btn_docs">
              <Button
                type="submit"
                color="info"
                variant="contained"
                style={{ height: 30, marginTop: 30 }}
              >
                Tìm kiếm
              </Button>
              <Button
                type="button"
                color="info"
                variant="contained"
                style={{ height: 30, marginTop: 30, marginLeft: 20 }}
                onClick={onCloseFilter}
              >
                Xóa
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const [keyTab, setKeyTab] = useState("1");
  const onCloseModalTable = () => {
    setKeyTab("1");
    setIsModalTable(false);
  };

  return (
    <div className="FormHomeTable">
      <div className="HeaderContentUser"></div>
      <Row style={{ width: "100%", marginBottom: "10px" }}>
        {screen.width > 768 ? (
          <>
            <Col span={16}>
              <Button
                onClick={openSearch}
                className="btnAddUser"
                style={{
                  float: "right",
                  marginRight: 20,
                  backgroundColor: "#1890ff",
                }}
              >
                <FilterOutlined
                  style={{
                    fontSize: 16,
                    marginRight: 5,
                  }}
                />
                Lọc
              </Button>
            </Col>
            <Col span={8}>
              <Search
                placeholder="Nhập thông tin cần Tìm kiếm"
                allowClear
                enterButton="Tìm kiếm"
                size="middle"
                onSearch={onSearch}
                loading={false}
              />
            </Col>
          </>
        ) : (
          <>
            <Col span={24}>
              <Search
                placeholder="Nhập thông tin cần Tìm kiếm"
                allowClear
                enterButton="Tìm kiếm"
                size="middle"
                onSearch={onSearch}
                loading={false}
              />
            </Col>
          </>
        )}
      </Row>
      {openInputSearch === true ? <FilterContract /> : null}

      <Table
        columns={columns}
        dataSource={data}
        onChange={handleChange}
        rowClassName={"styleTableLeTet1"}
        locale={{ emptyText: "Không có dữ liệu" }}
        pagination={{
          total: pager.count,
          current: pager.current,
          pageSize: pageSize,
          // showQuickJumper: true,
          // locale: {
          //   jump_to: "Đến trang: ",
          //   page: "",
          // },
          showSizeChanger: false,
        }}
        size={screen.availHeight < 850 ? "small" : "middle"}
      />
      <Modal
        visible={isModalTable}
        onCancel={onCloseModalTable}
        width={screen.width > 768 ? 1000 : "80%"}
        footer={false}
      >
        <ModalTable
          isDetail={isDetail}
          dataDetail={dataDetail}
          setIsDetail={setIsDetail}
          dataLog={dataLog}
          keyTab={keyTab}
          setKeyTab={setKeyTab}
          idLogContract={idLogContract}
        />
      </Modal>

      <ModalEditContract
        open={isEditing}
        onCancel={() => setIsEditing(false)}
        dataInforUploadFile={dataDetail}
        pager={pager}
        fetchData={fetchData}
        loading={loading}
      />
    </div>
  );
}

const ModalTable = ({
  isDetail,
  setIsDetail,
  dataDetail,
  dataLog,
  keyTab,
  setKeyTab,
  idLogContract,
}) => {
  useEffect(() => {
    setKeyTab("1");
  }, [isDetail]);

  const onKeyChange = (key) => setKeyTab(key);
  return (
    <Tabs
      defaultActiveKey="1"
      key="def"
      onChange={onKeyChange}
      activeKey={keyTab}
    >
      <Tabs.TabPane tab="Chi tiết hồ sơ" key="1">
        <ModalDetailContract
          open={isDetail}
          onCancel={() => setIsDetail(false)}
          dataDetail={dataDetail}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Lịch sử thao tác" key="2">
        <LogDocument dataLog={dataLog} idLogContract={idLogContract} />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default ThuNhanTatCa;

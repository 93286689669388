import React, { useEffect, useState } from "react";
import {
    Modal,
    Form,
    Input,
    Row,
    Col,
    Select,
} from "antd";
import {openNotificationWithIcon } from "../../../Function";
import {
    PutTeam,
} from "../../../../api/manageTeam";
import {Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import "../qlyNguoiDung.css";

const { Option } = Select;
const { Search } = Input;

const validateMessages = {
    required: "Không được để trống ô ${label} !",
    types: {
        email: "${label} đang sai định dạng!",
        number: "${label} not numbers!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const ModalEditTeam = ({
    isEditing,
    setIsEditing,
    dataInforTeam,
    data,
    setLoading,
    fetchData,
    pager,
    boPhan
}) => {
    const [form] = Form.useForm();
    const [loadingEdit, setLoadingEdit] = useState(false);
    useEffect(() => {
        form.resetFields();
        if (isEditing) {
            form.setFieldsValue({
                // pk: dataInforTeam.id,
                name: dataInforTeam.name,
                bo_phan: dataInforTeam.bo_phan[0].id,
            });
        }
    }, [isEditing]);

    const onFinish = (values) => {
        setLoadingEdit(true);
        const team = {
            name: values.name,
            id_bo_phan: values.bo_phan,
        };

        let flag = false;
        for (let i = 0; i < data.length; i++) {
            if (data[i].name === values.name && dataInforTeam.id !== data[i].id) {
                flag = true;
                break;
            }
        }
        if (flag === false) {
            PutTeam(dataInforTeam.id, team)
                .then((res) => {
                    if (res.data.error) {
                        setLoadingEdit(false);
                        openNotificationWithIcon("error", res.data.error);
                    } else {
                        fetchData({ page: pager.current, page_size: pager.pageSize });
                        openNotificationWithIcon(
                            "success",
                            "Thành công",
                            "Chỉnh sửa Team thành công"
                        );
                        setIsEditing(false);
                        setLoadingEdit(false);

                        form.resetFields();
                    }
                })
                .catch((err) => {
                    setIsEditing(false);
                    setLoadingEdit(false);

                    if (err.data.error) {
                        openNotificationWithIcon("error", err.data.error);
                    }
                });
        }
        if (flag === true) {
            openNotificationWithIcon(
                "error",
                "Thất bại",
                "Team này đã tồn tại"
            );
            setLoadingEdit(false);
        }

    };

    const onCloseModal = () => {
        setIsEditing(false);
    };
    return (
        <>
            <Modal
                title="Chỉnh sửa Team"
                visible={isEditing}
                onCancel={onCloseModal}
                className="modalAddUser"
                width={screen.width > 768 ? 800 : "80%"}
                footer={false}
                bodyStyle={
                    screen.width > 768
                        ? null
                        : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
                }
            >
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    validateMessages={validateMessages}
                    // loading={loading}
                    initialValues={{
                        remember: true,
                    }}
                    key="form_team"
                >
                    {screen.width > 768 ? (
                        <Row>
                            <Col span={11}>
                                <Form.Item
                                    name="name"
                                    label="Tên team"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            pattern: new RegExp(
                                                "^[a-zA-Z0-9$&+=?@#|<>.^*()%!-_ ]*$"
                                            ),
                                            message:
                                                "Không được nhập khoảng cách hoặc tiếng Việt có dấu",
                                        },
                                    ]}
                                    key="name_team"
                                >
                                    <Input placeholder="Nhập tên team" maxLength={100}></Input>
                                </Form.Item>
                            </Col>
                            <Col span={11} offset={2}>
                                <Form.Item
                                    name="bo_phan"
                                    label="Bộ phận"
                                    key="bo_phan"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Chọn Bộ phận"
                                        showSearch={false}
                                        optionFilterProp="children"
                                        maxTagCount="responsive"
                                        allowClear
                                        style={{
                                            width: "100%",
                                        }}
                                        key="bo_phan"
                                    >
                                        {boPhan.map((item, index) => (
                                            <Option key={item.name} value={item.id}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="name"
                                    label="Tên team"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            pattern: new RegExp(
                                                "^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]*$"
                                            ),
                                            message:
                                                "Không được nhập khoảng cách hoặc tiếng Việt có dấu",
                                        },
                                    ]}
                                    key="name_team"
                                >
                                    <Input placeholder="Nhập tên team" maxLength={100}></Input>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="bo_phan" label="Bộ phận" key="bo_phan">
                                    <Select
                                        placeholder="Chọn Bộ phận"
                                        showSearch={false}
                                        optionFilterProp="children"
                                        maxTagCount="responsive"
                                        allowClear
                                        style={{
                                            width: "100%",
                                        }}
                                        key="bo_phan"
                                    >
                                        {boPhan.map((item, index) => (
                                            <Option key={item.name} value={item.id}>
                                                {item.username}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    <Form.Item
                        style={{ marginTop: "20px", textAlign: "center" }}
                        key="btn_team"
                    >
                        <LoadingButton
                            type="submit"
                            className={"m-2"}
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            loading={loadingEdit}
                        >
                            Chỉnh sửa
                        </LoadingButton>
                        <Button
                            onClick={onCloseModal}
                            color="error"
                            variant="contained"
                        // disabled={loadingAddNew}
                        >
                            Hủy
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ModalEditTeam
import React, { useEffect, useState } from "react";
import {
    Modal,
    Form,
    Input,
    Row,
    Col,
    Select,
} from "antd";
import {openNotificationWithIcon} from "../../../Function";
import {
    PutUserApi,
} from "../../../../api/manageUser";

import {Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import "../qlyNguoiDung.css";
const { Option } = Select;
const { Search } = Input;

const validateMessages = {
    required: "Không được để trống ô ${label} !",
    types: {
        email: "${label} đang sai định dạng!",
        number: "${label} not numbers!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};



const ModalEditUser = ({
    open,
    onCancel,
    dataInforUser,
    pager,
    fetchData,
    lsRole,
    lsTeam,
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false);
        form.resetFields();

        if (open) {
            form.setFieldsValue({
                username: dataInforUser.username,
                email: dataInforUser.email,
                last_name: dataInforUser.first_name,
                first_name: dataInforUser.last_name,
                role_user: dataInforUser.role_user[0].id,
                team: dataInforUser.team.length > 0 ? dataInforUser.team[0].id : [],
                msnv: dataInforUser.msnv,
            });
        }
    }, [open]);

    const onFinish = (values) => {
        setLoading(true);
        const user = {
            userId: dataInforUser.id,
            msnv: values.msnv.trim(),
            email: values.email,
            last_name: values.last_name.trim(),
            first_name: values.first_name.trim(),
            role_user: values.role_user,
            block: "false",
            is_lock: false,
            team: values.team === undefined ? [] : values.team,
        };
        PutUserApi(user)
            .then((res) => {
                if (res.data.error) {
                    setLoading(false);
                    openNotificationWithIcon("error", res.data.error);
                } else {
                    setLoading(false);
                    fetchData({ page: pager.current, page_size: pager.pageSize });
                    openNotificationWithIcon(
                        "success",
                        "Thành công",
                        "Chỉnh sửa User thành công"
                    );
                    onCancel();
                    form.resetFields();
                }
            })
            .catch((err) => {
                if (err.data.error) {
                    setLoading(false);
                    openNotificationWithIcon("error", err.data.error);
                }
            });
    };

    const onCloseModal = () => {
        form.resetFields();
        onCancel();
    };

    return (
        <Modal
            title="Chỉnh sửa người dùng"
            visible={open}
            onCancel={onCloseModal}
            width={screen.width > 768 ? 800 : "80%"}
            footer={false}
            bodyStyle={
                screen.width > 768
                    ? null
                    : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
                validateMessages={validateMessages}
                // loading={loading}
                initialValues={{
                    remember: true,
                }}
            >
                {screen.width > 768 ? (
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                name="username"
                                label="User name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="username"
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Email"
                                    onBlur={(e) => {
                                        form.setFieldsValue({ email: e.target.value.trim() });
                                    }}
                                    maxLength={100}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="last_name"
                                label="Tên"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: new RegExp(
                                            "^[0-9a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$"
                                        ),
                                        message: "Không được nhập kí tự đặc biệt",
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={100}
                                    placeholder="Tên"
                                    onBlur={(e) => {
                                        form.setFieldsValue({
                                            last_name: e.target.value.trim(),
                                        });
                                    }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item
                                name="first_name"
                                label="Họ"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: new RegExp(
                                            "^[0-9a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$"
                                        ),
                                        message: "Không được nhập kí tự đặc biệt",
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={100}
                                    placeholder="Họ"
                                    onBlur={(e) => {
                                        form.setFieldsValue({
                                            first_name: e.target.value.trim(),
                                        });
                                    }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="role_user"
                                label="Phân quyền"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="role_user"
                            >
                                <Select
                                    placeholder="Chọn quyền cho user"
                                    showSearch={false}
                                    style={{
                                        width: "100%",
                                    }}
                                    allowClear
                                >
                                    {lsRole.map((item, index) => (
                                        <Option key={item.name} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item
                                name="team"
                                label="Team"
                                // rules={[
                                //   {
                                //     required: true,
                                //   },
                                // ]}
                                key="team"
                            >
                                <Select
                                    placeholder="Chọn team"
                                    showSearch={false}
                                    style={{
                                        width: "100%",
                                    }}
                                    allowClear
                                >
                                    {lsTeam.map((item, index) => (
                                        <Option key={item.name} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="msnv"
                                label="Mã số nhân viên"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="msnv"
                            >
                                <Input
                                    maxLength={100}
                                    placeholder="Mã số nhân viên"
                                    readOnly
                                ></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="username"
                                label="User name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="username"
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Email"
                                    onBlur={(e) => {
                                        form.setFieldsValue({ email: e.target.value.trim() });
                                    }}
                                    maxLength={100}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="last_name"
                                label="Tên"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: new RegExp(
                                            "^[0-9a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$"
                                        ),
                                        message: "Không được nhập kí tự đặc biệt",
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={100}
                                    placeholder="Tên"
                                    onBlur={(e) => {
                                        form.setFieldsValue({
                                            last_name: e.target.value.trim(),
                                        });
                                    }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="first_name"
                                label="Họ"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: new RegExp(
                                            "^[0-9a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$"
                                        ),
                                        message: "Không được nhập kí tự đặc biệt",
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={100}
                                    placeholder="Họ"
                                    onBlur={(e) => {
                                        form.setFieldsValue({
                                            first_name: e.target.value.trim(),
                                        });
                                    }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="role_user"
                                label="Phân quyền"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="role_user"
                            >
                                <Select
                                    placeholder="Chọn quyền cho user"
                                    showSearch={false}
                                    style={{
                                        width: "100%",
                                    }}
                                    allowClear
                                >
                                    {lsRole.map((item, index) => (
                                        <Option key={item.name} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="team"
                                label="Team"
                                // rules={[
                                //   {
                                //     required: true,
                                //   },
                                // ]}
                                key="team"
                            >
                                <Select
                                    placeholder="Chọn team"
                                    showSearch={false}
                                    style={{
                                        width: "100%",
                                    }}
                                    allowClear
                                >
                                    {lsTeam.map((item, index) => (
                                        <Option key={item.name} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="msnv"
                                label="Mã số nhân viên"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="msnv"
                            >
                                <Input
                                    maxLength={100}
                                    placeholder="Mã số nhân viên"
                                    readOnly
                                ></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Form.Item style={{ marginTop: "20px", textAlign: "center" }}>
                    <LoadingButton
                        type="submit"
                        className={"m-2"}
                        style={{ marginRight: "20px" }}
                        variant="contained"
                        loading={loading}
                    >
                        Chỉnh sửa
                    </LoadingButton>
                    <Button
                        onClick={onCloseModal}
                        color="error"
                        variant="contained"
                        disabled={loading}
                    >
                        Hủy
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalEditUser
import { authAxios } from "../api/axiosClient";
// import Cookies from 'universal-cookie';
import {
  manageUploadPersonal,
  manageUploadURL,
  userInfoURL,
  totalContractExpire,
  totalContractYear,
  documentMail,
  notificationAll,
  viewNotification,
  changePassword,
  manageLegalEntity,
  manageDetailLegalEntity,
  exportContractYear,
} from "../constants";

export const usersApi = {
  getAll: (params) => {
    const url = "/api/users";
    return authAxios().get(url, { params });
  },
};

export const GetManageUpload = (params = {}) => {
  const url = manageUploadURL;
  return authAxios().get(url, { params });
};

export const GetManageUploadPersonal = (params = {}) => {
  const url = manageUploadPersonal;
  return authAxios().get(url, { params });
};

export const ContractExpire = (params = {}) => {
  const url = totalContractExpire;
  return authAxios().get(url, { params });
};
export const ContractYear = (year, params = {}) => {
  const url = totalContractYear;
  return authAxios().get(url(year), { params });
};

export const GetDocumentMail = (params = {}) => {
  const url = documentMail;
  return authAxios().get(url, { params });
};

export const ViewNotification = (params = {}) => {
  const url = viewNotification;
  return authAxios().put(url, params);
};

export const NotificationAll = (params = {}) => {
  const url = notificationAll;
  return authAxios().get(url, params);
};

export const UserInfoUrlApi = (params = {}) => {
  const url = userInfoURL;
  return authAxios().get(url, params);
};

export const ChangePassword = (params = {}) => {
  const url = changePassword;
  return authAxios().post(url, params);
};


export const GetDetailLegalEntity = (id, params = {}) => {
  const url = manageDetailLegalEntity;
  return authAxios().get(url(id), { params });
};
export const GetListLegalEntity = (params = {}) => {
  const url = manageLegalEntity;
  return authAxios().get(url, { params });
};
export const PostLegalEntity = (params = {}) => {
  const url = manageLegalEntity;
  return authAxios().post(url, params);
};

export const PutLegalEntity = (params = {}) => {
  const url = manageLegalEntity;
  return authAxios().put(url, params);
};
export const PutLegalEntityAPI = (params = {}) => {
  const url = manageLegalEntity;
  return authAxios().put(url, params);
};


export const ExportContractYearApi = (params = {}) => {
  const url = exportContractYear;
  return authAxios().post(url, params, { responseType: "blob" });
};


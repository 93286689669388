import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Spin,
  Input,
  Upload,
  Popconfirm,
  Popover,
  Tabs,
  Table,
} from "antd";
import "../thuNhan/CaNhan.css"
import moment from "moment";
import { errorHandle, openNotificationWithIcon } from "../../../Function";

import {
  DeleteOutlined,
  EditOutlined,
  DownloadOutlined,
  InboxOutlined,
  FilterOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Grid, Box, Button } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import {
  DeleteUploadApi,
  GetInforUpload,
  DownloadFile,
  ExportDocument,
  ImportContract,
  TemplateContract,
  LogContract,
  GetManageUploadAll,
  GetLegalEntityAll,
  DeleteUploadMulti,
} from "../../../../api/manageContract";

import ModalUploadContract from "./ModalUploadContract";
import ModalEditContract from "./ModalEditContract";
import ModalDetailContract from "./ModalDetailContract";
import FilterContract from "./FilterContract";
import { useSelector } from "react-redux";
import { GetListTypeDocument, GetRuleTypeDocumentAll, GetTypeDocumentAll } from "../../../../api/manageDocs";
import { GetManageCustomer } from "../../../../api/manageCustomer";
import { LoadingButton } from "@mui/lab";
const { Dragger } = Upload;
const { Search } = Input;

const validateMessages = {
  required: "Vui lòng nhập ${label} !",
  types: {
    email: "${label} sai định dạng!",
    number: "${label} phải là số!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const dateFormat = "DD-MM-YYYY";

function CaNhan() {
  const [pageSize, setPageSize] = useState(10);
  const [pager, setPager] = useState({
    pageSize: pageSize,
    count: 0,
    current: 1,
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState();
  const [isModalAddNew, setIsModalAddNew] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState();
  const [loading_page, setLoading_page] = useState(false);
  const [openInputSearch, setOpenInputSearch] = useState(false);
  const [isModalTable, setIsModalTable] = useState(false);
  const [isImportContract, setIsImportContract] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [typeDocument, setTypeDocument] = useState([]);
  const [legalEntity, setLegalEntity] = useState([])

  const [idLogContract, setIdLogContract] = useState();
  const [dataLog, setDataLog] = useState([]);

  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const checkRoleManageAdmin = userInfo.role_name.toLowerCase() === "manage_admin"
  const checkRoleAdmin = userInfo.role_name.toLowerCase() === "admin"

  const [dataAllNameDocs, setDataAllNameDocs] = useState([])
  const [typeDocsAll, setTypeDocsAll] = useState([])
  const [keyTab, setKeyTab] = useState("1");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [checkFilter, setCheckFilter] = useState(false)

  const [loadingDownload, setLoadingDownload] = useState(false)
  const [loadingMultiDelete, setLoadingMultiDelete] = useState(false)
  const [typeDocumentFilter, setDocumentFilter] = useState([])
  const [userWithRoleMember, setUserWithRoleMember] = useState([])
  const [datafilter, setDataFilter] = useState({
    customer: undefined,
    rule_type_document: undefined,
    type_document: undefined,
    legal_entity_signed: undefined,
    project: "",
    auto_extend: "",
    user_update: "",
    day_sign: "",
    day_expired: "",
  });

  const [listIdDoc, setListIdDoc] = useState([])

  const fetchAllNameDocument = () => {
    // setLoading(true);
    GetTypeDocumentAll()
      .then((res) => {
        setDataAllNameDocs(res.data)
        // setData(res.data.results);
        // setPager({
        //   current: params.page,
        //   pageSize: params.page_size,
        //   count: res.data.count,
        // });
        // setLoading(false);
      })
      .catch((err) => {
        errorHandle(err);
        // setLoading(false);
      });
  };

  const GetListCustomer = () => {
    GetManageCustomer({
      page: 1, page_size: 5000
    }).then((res) => {
      var listCustomer = res.data.results.filter(
        (customer) => customer.is_active === true
      );
      listCustomer.sort((a, b) => {
        // var keyA = a.full_name
        //   ,
        //   keyB = b.full_name
        //   ;
        // // Compare the 2 dates
        // if (keyA < keyB) return -1;
        // if (keyA > keyB) return 1;
        // return 0
        return a.full_name.localeCompare(b.full_name)

      });
      setCustomer(listCustomer);
    });
  };

  const fetchAllTypeDocument = () => {
    // setLoading(true);
    GetRuleTypeDocumentAll()
      .then((res) => {
        setTypeDocsAll(res.data)
      })
      .catch((err) => {
        errorHandle(err);
      });
  };


  const GetListDocument = () => {
    GetListTypeDocument({
      page: 1, page_size: 5000
    }).then((res) => {
      var listTypeDocument = res.data.results.filter(
        (docs) => docs.is_active === true
      );
      listTypeDocument.sort((a, b) => {
        // var keyA = a.name
        //   ,
        //   keyB = b.name
        //   ;
        // // Compare the 2 dates
        // if (keyA < keyB) return -1;
        // if (keyA > keyB) return 1;
        // return 0
        return a.name.localeCompare(b.name)
      });
      setTypeDocument(listTypeDocument);
    });
  };

  const FetchLegalEntity = () => {
    GetLegalEntityAll().then((res) => {
      var listLegalEntity = res.data.filter(
        (docs) => docs.is_active === true
      );
      listLegalEntity.sort((a, b) => {
        // var keyA = a.name
        //   ,
        //   keyB = b.name
        //   ;
        // // Compare the 2 dates
        // if (keyA < keyB) return -1;
        // if (keyA > keyB) return 1;
        // return 0
        return a.code_signer.localeCompare(b.code_signer)
      });
      setLegalEntity(listLegalEntity);
    });
  };

  const fetchData = (params = {}) => {
    setLoading(true);
    GetManageUploadAll(params)
      .then((res) => {
        setListIdDoc([])
        setSelectedRowKeys([])
        setData(res.data.results);

        setDocumentFilter(res.data.get_ruletype_doc)
        setPager({
          current: params.page,
          pageSize: params.page_size,
          count: res.data.count,
        });
        setUserWithRoleMember(res.data.get_useraccount)
        if (checkFilter === false) {
        } else {
        }
        setLoading(false);
      })
      .catch((err) => {
        errorHandle(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    GetListCustomer();
    GetListDocument();
    FetchLegalEntity();
    fetchAllNameDocument();
    fetchAllTypeDocument()
  }, []);

  useEffect(() => {
    if (checkFilter === false) {
      const id = setInterval(() => {
        fetchData({page: 1, page_size: pager.pageSize, search });
        clearInterval(id);
      }, 300);
      return () => clearInterval(id);
    }
  }, [search, checkFilter]);

  const onShowModalEdit = (record) => {
    GetInforUpload(record.id)
      .then((res) => {
        setDataDetail(res.data);
        setIsEditing(true);
      })
      .catch((err) => {
        if (err) {
          openNotificationWithIcon("error", err);
        }
      });
  };

  const onShowModalDetail = (record) => {
    GetInforUpload(record.id)
      .then((res) => {
        setDataDetail(res.data);
        setIsDetail(true);
        setIsModalTable(true);
      })
      .catch((err) => {
        if (err) {
          openNotificationWithIcon("error", err);
        }
      });
  };

  const onLogContract = (record) => {
    LogContract({ id_document_log: record.id })
      .then((res) => {
        setDataLog(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteUpload = (record) => {

    DeleteUploadApi({ id_document: record.id })
      .then((res) => {
        fetchData({ page: 1, page_size: pager.pageSize, search });
        openNotificationWithIcon(
          "success",
          "Thành công",
          "Xóa hồ sơ thành công"
        );
      })
      .catch((error) => {
        openNotificationWithIcon(
          "error",
          "Thất bại",
          "Xóa hồ sơ không thành công"
        );
      });
  };

  const deleteMultiUpload = () => {
    DeleteUploadApi({ ls_id_document: listIdDoc })
      .then((res) => {
        fetchData({ page: 1, page_size: pager.pageSize, search });
        openNotificationWithIcon(
          "success",
          "Thành công",
          "Xóa hồ sơ thành công"
        );
      })
      .catch((error) => {
        openNotificationWithIcon(
          "error",
          "Thất bại",
          "Xóa hồ sơ không thành công"
        );
      });
  };

  const content = (record) => {
    const lsFile = [];
    record &&
      record.file_document.map((file) => {
        lsFile.push(<p key={file.name_file}>{file.name_file}</p>);
      });
    return lsFile;
  };

  const fileDownload_Staff = (record) => {
    setLoading_page(true);
    const fnFileDownload = require("js-file-download");
    const filename =
      "DanhSachFile_" + moment(new Date()).format("DDMMYYYY") + ".zip";
    DownloadFile(record.id, { responseType: "blob" })
      .then((res) => {
        fnFileDownload(res.data, filename);
        setLoading_page(false);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Không tải được danh sách hồ sơ. Bạn vui lòng thử lại."
        );
        setLoading_page(false);
      });
  };

  const renderDetailsButton = (record) => {
    return (
      <Box sx={{ width: "100%" }} style={{ marginLeft: 8 }}>
        {userInfo.role_name === "Admin" ? (
          <Grid item container xs={12}>
            <Grid
              xs={3}
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <span
                title="Chỉnh sửa"
                className={"col-6"}
                style={{ color: "#4290d3", cursor: "pointer" }}
                onClick={() => onShowModalEdit(record)}
              >
                <EditOutlined />
              </span>
            </Grid>

            <Grid
              xs={3}
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Popover
                placement="topLeft"
                content={() => content(record)}
                arrowPointAtCenter
                key="123"
              >
                <button
                  style={{ border: "none", background: "none" }}
                  onClick={() => fileDownload_Staff(record)}
                >
                  <DownloadOutlined
                    style={{ color: "green", cursor: "pointer" }}
                  />
                </button>
              </Popover>
            </Grid>

            <Grid
              xs={3}
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <span
                title="Xóa hồ sơ"
                className={"col-6"}
                style={{ color: "#4290d3", cursor: "pointer" }}
              >
                <Popconfirm
                  placement="top"
                  title={"Bạn có chắc muốn xóa hồ sơ này?"}
                  onConfirm={() => deleteUpload(record)}
                  okText="Có"
                  cancelText="Không"
                >
                  <DeleteOutlined style={{ color: "red", cursor: "pointer" }} />
                </Popconfirm>
              </span>
            </Grid>
          </Grid>
        ) :
          userInfo.role_name.toLowerCase() === "manage_admin" ? (
            <Grid item container xs={12}>
              <Grid xs={2}></Grid>
              <Grid
                xs={3}
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {/* <span
                  title="Chỉnh sửa"
                  className={"col-6"}
                  style={{ color: "#4290d3", cursor: "pointer" }}
                  onClick={() => onShowModalEdit(record)}
                >
                  <EditOutlined />
                </span> */}
              </Grid>

              <Grid
                xs={3}
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Popover
                  placement="topLeft"
                  content={() => content(record)}
                  arrowPointAtCenter
                  key="123"
                >
                  <button
                    style={{ border: "none", background: "none" }}
                    onClick={() => fileDownload_Staff(record)}
                  >
                    <DownloadOutlined
                      style={{ color: "green", cursor: "pointer" }}
                    />
                  </button>
                </Popover>
              </Grid>
            </Grid>
          ) : <Grid item container xs={12}>
            <Grid xs={2}></Grid>
            <Grid
              xs={3}
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {userInfo.username === record.user_create[0].username ?
                <span
                  title="Chỉnh sửa"
                  className={"col-6"}
                  style={{ color: "#4290d3", cursor: "pointer" }}
                  onClick={() => onShowModalEdit(record)}
                >
                  <EditOutlined />
                </span>
                : null}
            </Grid>

            <Grid
              xs={3}
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Popover
                placement="topLeft"
                content={() => content(record)}
                arrowPointAtCenter
                key="123"
              >
                <button
                  style={{ border: "none", background: "none" }}
                  onClick={() => fileDownload_Staff(record)}
                >
                  <DownloadOutlined
                    style={{ color: "green", cursor: "pointer" }}
                  />
                </button>
              </Popover>
            </Grid>
          </Grid>
        }
      </Box>
    );
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
      width: 40,
    },

    {
      title: "Tên khách hàng",
      key: "customer",
      align: "center",
      width: 180,
      dataIndex: "customer",
      ellipsis: true,
      render: (text, record, index) => record.customer[0].full_name,
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            onShowModalDetail(record);
            setIdLogContract(record.id);
            onLogContract(record);
          },
        };
      },
    },

    {
      title: "Loại tài liệu",
      key: "rule_type_document",
      align: "center",
      dataIndex: "rule_type_document",
      ellipsis: true,
      width: 120,
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            onShowModalDetail(record);
            setIdLogContract(record.id);
            onLogContract(record);
          },
        };
      },
      render: (text, record, index) => record.rule_type_document.length > 0 ? record.rule_type_document[0].name : undefined,
    },

    {
      title: "Tên tài liệu",
      key: "type_document",
      align: "center",
      dataIndex: "type_document",
      ellipsis: true,
      width: 200,
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            onShowModalDetail(record);
            setIdLogContract(record.id);
            onLogContract(record);
          },
        };
      },
      render: (text, record, index) => record.type_document.length > 0 ? record.type_document[0].name : undefined,
    },

    {
      title: "Nội dung chính",
      key: "content",
      align: "center",
      dataIndex: "content",
      ellipsis: true,
      width: 280,
      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            onShowModalDetail(record);
            setIdLogContract(record.id);
            onLogContract(record);
          },
        };
      },
      render: (text, record, index) => record.content,
    },

    // {
    //   title: "Tự động tái tục",
    //   key: "auto_extend",
    //   align: "center",
    //   dataIndex: "auto_extend",
    //   width: "8%",
    //   ellipsis: true,
    //   onCell: (record, rowIndex) => {
    //     return {
    //       onClick: (ev) => {
    //         onShowModalDetail(record);
    //         setIdLogContract(record.id);
    //         onLogContract(record);
    //       },
    //     };
    //   },
    //   render: (text, record, index) =>
    //     record.auto_extend === false ? (
    //       <CloseOutlined style={{ color: "red" }} />
    //     ) : (
    //       <CheckOutlined style={{ color: "green" }} />
    //     ),
    // },

    {
      title: "Ngày kí kết",
      key: "day_sign",
      align: "center",
      dataIndex: "day_sign",
      ellipsis: true,
      width: 100,

      onCell: (record, rowIndex) => {
        return {
          onClick: (ev) => {
            onShowModalDetail(record);
            setIdLogContract(record.id);
            onLogContract(record);
          },
        };
      },
      render: (text, record, index) =>
        moment(record.day_sign).format(dateFormat),
    },

    // {
    //   title: "Ngày hết hạn",
    //   key: "day_expired",
    //   align: "center",
    //   dataIndex: "day_expired",
    //   ellipsis: true,
    //   width: "8%",

    //   onCell: (record, rowIndex) => {
    //     return {
    //       onClick: (ev) => {
    //         onShowModalDetail(record);
    //         setIdLogContract(record.id);
    //         onLogContract(record);
    //       },
    //     };
    //   },
    //   render: (text, record, index) =>
    //     record.day_expired ? moment(record.day_expired).format(dateFormat) : "",
    // },

    // {
    //   title: "Ngày cập nhật",
    //   key: "updated",
    //   align: "center",
    //   dataIndex: "updated",
    //   ellipsis: true,
    //   width: "8%",

    //   onCell: (record, rowIndex) => {
    //     return {
    //       onClick: (ev) => {
    //         onShowModalDetail(record);
    //         setIdLogContract(record.id);
    //         onLogContract(record);
    //       },
    //     };
    //   },
    //   render: (text, record, index) =>
    //     moment(record.updated).format(dateFormat),
    // },

    // {
    //   title: "Người tạo",
    //   key: "user_create",
    //   align: "center",
    //   dataIndex: "user_create",
    //   ellipsis: true,
    //   width: "8%",

    //   onCell: (record, rowIndex) => {
    //     return {
    //       onClick: (ev) => {
    //         onShowModalDetail(record);
    //         setIdLogContract(record.id);
    //         onLogContract(record);
    //       },
    //     };
    //   },
    //   render: (text, record, index) => record.user_create[0].username,
    // },

    {
      title: "Thao tác",
      align: "center",
      width: 100,
      render: renderDetailsButton,
    },
  ];

  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = pagination.pageSize;
    setPager({ ...currentPager });
    if (checkFilter === false) {
      fetchData({
        page_size: pagination.pageSize,
        page: pagination.current,
        search: search,
      });
    } else {
      fetchData({
        status: currentPager.status,
        page_size: pagination.pageSize,
        page: pagination.current,
        customer: datafilter.customer || "",
        type_document: datafilter.type_document || "",
        rule_type_document: datafilter.rule_type_document || "",
        legal_entity_signed: datafilter.legal_entity_signed || "",
        user_update: datafilter.user_update,
        auto_extend: datafilter.auto_extend,
        day_expired: datafilter.day_expired,
        day_sign: datafilter.day_sign,
        project: datafilter.project,
      });
    }

    setSelectedRowKeys([])
    // setPageSize(pagination.pageSize)
  };

  const onSearch = (e) => {
    setDataFilter({
      customer: undefined,
      rule_type_document: undefined,
      type_document: undefined,
      legal_entity_signed: undefined,
      project: "",
      auto_extend: "",
      user_update: "",
      day_sign: "",
      day_expired: "",
    })
    setOpenInputSearch(false)
    setSearch(e);
    setCheckFilter(false)
  };
  const showModalUploadContract = () => {
    setIsModalAddNew(true);
  };

  const openSearch = () => {
    if (!openInputSearch === false) {
      setCheckFilter(false)
      fetchData({ page: 1, page_size: pager.pageSize, search });
    }
    setOpenInputSearch(!openInputSearch);
  };

  const fileDownload_Contract = () => {
    setLoadingDownload(true);
    const fnFileDownload = require("js-file-download");
    const filename =
      "DanhSachHoSo_" + moment(new Date()).format("DDMMYYYY") + ".xlsx";
    if (listIdDoc.length > 0) {

    }
    ExportDocument(listIdDoc.length === 0 ? {
      search: search,
      customer: datafilter.customer,
      rule_type_document: datafilter.rule_type_document,
      type_document: datafilter.type_document,
      project: datafilter.project,
      auto_extend: datafilter.auto_extend,
      user_update: datafilter.user_update,
      day_sign: datafilter.day_sign,
      day_expired: datafilter.day_expired,
      legal_entity_signed: datafilter.legal_entity_signed,
    } : {
      // params: {
      ls_id_doc: listIdDoc,
      // }
    })
      .then((res) => {
        console.log(res)
        fnFileDownload(res.data, filename);
        setLoadingDownload(false);
        setListIdDoc([])
        setSelectedRowKeys([])
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Không tải được danh sách hồ sơ. Bạn vui lòng thử lại."
        );
        setLoadingDownload(false);
      });
  };

  const showModalImportContract = () => {
    setIsImportContract(true);
  };

  const onCloseModalTable = () => {
    setKeyTab("1");
    setIsModalTable(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setListIdDoc(newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // selections: [
    //   {
    //     key: 'even',
    //     text: 'Xóa hồ sơ',
    //     onSelect: () => {
    //       selectedRowKeys.length > 0 ? onDeleteMulti() : openNotificationWithIcon("warning", "Vui lòng chọn hồ sơ")
    //     },
    //   },
    // ],
  };

  const onDeleteMulti = () => {
    setLoadingMultiDelete(true)
    DeleteUploadMulti({
      params: {
        is_delete_multi: true,
        ls_id_document: JSON.stringify(selectedRowKeys),
      }
    }).then(res => {
      console.log(checkFilter)
      if (checkFilter === false) {
        fetchData({ page: pager.current, page_size: pager.pageSize, search });
      } else {
        fetchData({
          page: pager.current,
          page_size: pager.pageSize,
          customer: datafilter.customer,
          rule_type_document: datafilter.rule_type_document,
          type_document: datafilter.type_document,
          project: datafilter.project,
          auto_extend: datafilter.auto_extend,
          user_update: datafilter.user_update,
          day_sign: datafilter.day_sign,
          day_expired: datafilter.day_expired,
          legal_entity_signed: datafilter.legal_entity_signed,
        });
      }

      setListIdDoc([])
      setLoadingMultiDelete(false)
      openNotificationWithIcon("success", res.data.result)
    }).catch(err => {
      setLoadingMultiDelete(false)
      openNotificationWithIcon("error", err.data.error)
    })
  }

  return (
    <div className="FormHomeTable">
      <Row style={{ width: "98%", marginBottom: "10px" }}>
        {screen.width > 768 ? (
          <>
            <Col span={16}>
              {checkRoleManageAdmin === false ? <>
                <Button
                  className="btnAddUser"
                  onClick={showModalUploadContract}
                  style={{ marginRight: "15px", float: "left" }}
                >
                  <ControlPointIcon
                    style={{ fontSize: 20, marginRight: "5px" }}
                  />
                  Thêm mới
                </Button>

                <LoadingButton
                  onClick={fileDownload_Contract}
                  className="btnAddUser"
                  loading={loadingDownload}
                  style={{
                    float: "left",
                    backgroundColor: "green",
                    marginRight: "15px",
                  }}
                  startIcon={<DownloadOutlined style={{
                    fontSize: 16,
                    marginLeft: "20%"
                  }} />}
                  loadingPosition="start"
                >
                  <span>Tải xuống</span>
                </LoadingButton>

                {listIdDoc.length > 0 ?
                  <LoadingButton
                    onClick={onDeleteMulti}
                    className="btnAddUser"
                    loading={loadingMultiDelete}
                    style={{
                      float: "left",
                      backgroundColor: "red",
                    }}
                    startIcon={<DeleteOutlined style={{
                      fontSize: 16,
                      marginLeft: "20%"
                    }} />}
                    loadingPosition="start"
                  >
                    <span>Xóa</span>
                  </LoadingButton>
                  : null}

                {/* <Button
                  onClick={showModalImportContract}
                  className="btnAddUser"
                  style={{
                    float: "left",
                    marginLeft: 15,
                    backgroundColor: "#FF4C49",
                  }}
                >
                  <UploadOutlined
                    style={{
                      fontSize: 16,
                      marginRight: 5,
                    }}
                  />
                  Tải lên
                </Button> */}
              </> : <>
                <LoadingButton
                  onClick={fileDownload_Contract}
                  className="btnAddUser"
                  loading={loadingDownload}
                  style={{
                    float: "left",
                    backgroundColor: "green",
                  }}
                  startIcon={<DownloadOutlined style={{
                    fontSize: 16,
                    marginLeft: "20%"
                  }} />}
                  loadingPosition="start"
                >
                  <span>Tải xuống</span>
                </LoadingButton>
              </>
              }

              <Button
                onClick={openSearch}
                className="btnAddUser"
                style={{
                  float: "right",
                  marginRight: 20,
                  backgroundColor: "#1890ff",
                }}
              >
                <FilterOutlined
                  style={{
                    fontSize: 16,
                    marginRight: 5,
                  }}
                />
                Lọc
              </Button>

            </Col>
            <Col span={8}>
              <Search
                placeholder="Nhập thông tin cần Tìm kiếm"
                allowClear
                enterButton="Tìm kiếm"
                size="middle"
                loading={false}
                onSearch={onSearch}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </Col>
          </>
        ) : (
          <>
            <Col span={8}>
              <Button
                className="btnAddUser"
                onClick={showModalUploadContract}
                style={{ float: "left", height: 32 }}
              >
                <ControlPointIcon style={{ marginRight: "5px" }} /> Thêm mới
              </Button>
            </Col>
            <Col span={16}>
              <Search
                placeholder="Nhập thông tin cần Tìm kiếm"
                allowClear
                size="middle"
                onSearch={onSearch}
                loading={false}
                style={{ transition: "width 2s" }}
                enterButton
              />
            </Col>
          </>
        )}
      </Row>

      {openInputSearch === true ?
        <FilterContract
          fetchData={fetchData}
          pager={pager}
          search={search}
          setDataFilter={setDataFilter}
          datafilter={datafilter}
          typeDocument={typeDocument}
          data={data}
          customer={customer}
          legalEntity={legalEntity}
          dataAllNameDocs={dataAllNameDocs}
          typeDocsAll={typeDocsAll}
          openInputSearch={openInputSearch}
          setSearch={setSearch}
          setCheckFilter={setCheckFilter}
          typeDocumentFilter={typeDocumentFilter}
        /> : null}

      <Spin spinning={loading_page}>
      {/* <div style={{width: "100%", position: "relative"}}> */}
        <Table
          columns={columns}
          dataSource={data}
          rowSelection={checkRoleAdmin === true ? rowSelection : null}
          // rowSelection={rowSelection}
          onChange={handleChange}
          rowClassName={"styleTableContract"}
          locale={{ emptyText: "Không có dữ liệu" }}
          style={{ position: "absolute" }}
          scroll={{
            y: openInputSearch === true ? "45vh" : "68vh",
          }}
          // className="custom-table table-striped-rows"

          pagination={{
            current: pager.current,
            pageSize: pager.pageSize,
            total: pager.count,
            // showQuickJumper: true,
            // locale: {
            //   jump_to: "Đến trang: ",
            //   page: "",
            // },
            // pageSizeOptions: ["10", "15", "25", "50"],
            showSizeChanger: true,
          }}
          size={screen.availHeight < 850 ? "small" : "middle"}
        />
      {/* </div> */}
      </Spin>

      <ModalUploadContract
        open={isModalAddNew}
        onCancel={() => setIsModalAddNew(false)}
        fetchData={fetchData}
        pager={pager}
        customers={customer}
        legalEntity={legalEntity}
        dataAllNameDocs={dataAllNameDocs}
        typeDocsAll={typeDocsAll}
        typeDocument={typeDocument}
        userWithRoleMember={userWithRoleMember}
        setOpenInputSearch = {setOpenInputSearch}
      // loading={loading}
      />
      <ModalEditContract
        open={isEditing}
        onCancel={() => setIsEditing(false)}
        dataInforUploadFile={dataDetail}
        pager={pager}
        fetchData={fetchData}
        // lsRole={lsRole}
        loading={loading_page}
        legalEntity={legalEntity}
        checkFilter={checkFilter}
        search={search}
        datafilter={datafilter}
        userWithRoleMember={userWithRoleMember}
      />

      <Modal
        visible={isModalTable}
        onCancel={onCloseModalTable}
        footer={false}
        width={screen.width > 768 ? 1000 : "80%"}
      >
        <ModalTable
          isDetail={isDetail}
          dataDetail={dataDetail}
          setIsDetail={setIsDetail}
          dataLog={dataLog}
          keyTab={keyTab}
          setKeyTab={setKeyTab}
          idLogContract={idLogContract}
          legalEntity={legalEntity}
        />
      </Modal>

      <ModalImportContract
        open={isImportContract}
        onCancel={() => setIsImportContract(false)}
        fetchData={fetchData}
        pager={pager}
      // loading={loading}
      />
    </div>
  );
}

const ModalTable = ({
  isDetail,
  setIsDetail,
  dataDetail,
  dataLog,
  keyTab,
  setKeyTab,
  idLogContract,
  legalEntity
}) => {
  useEffect(() => {
    setKeyTab("1");
  }, [isDetail]);

  const onKeyChange = (key) => setKeyTab(key);
  return (
    <Tabs
      defaultActiveKey="1"
      key="def"
      onChange={onKeyChange}
      activeKey={keyTab}
    >
      <Tabs.TabPane tab="Chi tiết hồ sơ" key="1">
        <ModalDetailContract
          open={isDetail}
          onCancel={() => setIsDetail(false)}
          dataDetail={dataDetail}
          legalEntity={legalEntity}
        // pager={pager}
        // lsRole={lsRole}
        // loading={loading}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Lịch sử thao tác" key="2">
        <LogDocument dataLog={dataLog} idLogContract={idLogContract} />
      </Tabs.TabPane>
    </Tabs>
  );
};

const ModalImportContract = ({ open, onCancel, fetchData, pager }) => {
  const [formImport] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [loadingImport, setLoadingImport] = useState(false);

  useEffect(() => {
    formImport.resetFields();
  }, []);

  const onCloseModal = () => {
    formImport.resetFields();
    onCancel();
  };

  const handleChange = (info) => {
    let newFileList = [...info.fileList];
    setFileList(newFileList);
  };

  const onFinishImport = (values) => {
    setLoadingImport(true);
    var FormData = require("form-data");
    var data = new FormData();
    data.append("fileupload", values.fileupload.fileList[0].originFileObj);
    ImportContract(data)
      .then((res) => {
        // setLoadingAddNew(true);
        openNotificationWithIcon(
          "success",
          "Thành công",
          "Thêm mới hồ sơ thành công"
        );
        fetchData({ page: pager.current, page_size: pager.pageSize });
        onCloseModal();
        formImport.resetFields();
        setLoadingImport(false);
      })
      .catch((err) => {
        setLoadingImport(false);
        if (err.data.message.length > 500) {
          openNotificationWithIcon("error", err.data.message, null, "custom-notification");
        } else {
          openNotificationWithIcon("error", err.data.message);
        }
      });
  };

  const fileDownloadTemplate_Contract = () => {
    // setLoading_page(true);
    const fnFileDownload = require("js-file-download");
    const filename =
      "FileMauHoSo_" + moment(new Date()).format("DDMMYYYY") + ".xlsm";
    TemplateContract({ responseType: "blob" })
      .then((res) => {
        fnFileDownload(res.data, filename);
        // setLoading_page(false);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Không tải được danh sách hồ sơ. Bạn vui lòng thử lại."
        );
        // setLoading_page(false);
      });
  };

  return (
    <>
      <Modal
        title={
          <Row>
            <Col span={15}>
              <span>Upload file</span>
            </Col>
            <Col span={8}>
              <a
                // href={localhost + "/media/template/Template_contract.xlsx"}
                // href={"/media/template/Template_contract.xlsx"}
                style={{ float: "right" }}
                onClick={fileDownloadTemplate_Contract}
              >
                <DownloadOutlined style={{ marginRight: 5, fontSize: 14 }} />
                <span style={{ fontSize: 14 }}>File mẫu</span>
              </a>
            </Col>
          </Row>
        }
        visible={open}
        onCancel={onCloseModal}
        className="modalAddUser site-drawer-render-in-current-wrapper"
        width={screen.width > 768 ? 800 : "80%"}
        footer={false}
        bodyStyle={
          screen.width > 768
            ? null
            : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
        }
      >
        <Spin spinning={loadingImport}>
          <Form
            form={formImport}
            layout="vertical"
            autoComplete="off"
            onFinish={onFinishImport}
            // onFinishFailed={onFinishFailed}
            // validateMessages={validateMessages}
            initialValues={{
              remember: true,
            }}
            key="formImport"
          >
            <Form.Item
              name="fileupload"
              label={<FolderIcon />}
              rules={[
                {
                  required: true,
                  message: "File upload không được để trống!",
                },
              ]}
              key="fileupload"
            >
              <Dragger
                accept=".xlsm,.xlsx"
                name="file"
                openFileDialogOnClick={true}
                // defaultFileList={fileList}
                // fileList={fileList}
                onChange={(info) => handleChange(info)}
                showUploadList={true}
                maxCount={1}
                beforeUpload={(file, FileList) => {
                  const isJpgOrPng =
                    !["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel.sheet.macroEnabled.12"].includes(file.type);
                  // const checkSize = file.size / 1024 / 1024 > 5;
                  if (isJpgOrPng) {
                    openNotificationWithIcon(
                      "error",
                      "Lỗi",
                      `Sai định dạng file !`
                    );
                    return Upload.LIST_IGNORE;
                  }
                  // if (checkSize) {
                  //   openNotificationWithIcon("error", "Lỗi", `Quá 5MB !`);
                  // }

                  return !isJpgOrPng ? false : Upload.LIST_IGNORE;
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click hoặc kéo thả để chọn file
                </p>
                <p className="ant-upload-hint">
                  Chọn file trên thiết bị của bạn (
                  <span style={{ color: "#FF463F", fontStyle: "italic" }}>
                    Hỗ trợ định dạng xlsm, xlsx
                  </span>
                  )
                </p>
              </Dragger>
            </Form.Item>
            <Form.Item
              style={{ marginTop: "20px", textAlign: "center" }}
              key="button"
            >
              <LoadingButton
                type="submit"
                className={"m-2"}
                style={{ marginRight: "20px" }}
                variant="contained"
              // loading={loadingAddNew}
              >
                Đăng ký
              </LoadingButton>
              <Button
                onClick={onCloseModal}
                color="error"
                variant="contained"
              // disabled={loadingAddNew}
              >
                Hủy
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

const LogDocument = ({ dataLog, idLogContract }) => {
  const [pageSize, setPageSize] = useState(10);
  const [pager, setPager] = useState({
    pageSize: pageSize,
    count: 0,
    current: 1,
  });

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
      width: "3%",
    },

    {
      title: "Nội dung thao tác",
      key: "content",
      align: "center",
      dataIndex: "content",
      ellipsis: true,
      width: "15%",
      render: (text, record, index) => record.content,
    },

    {
      title: "Thời gian thao tác",
      key: "updated",
      align: "center",
      dataIndex: "updated",
      ellipsis: true,
      width: "15%",
      render: (text, record, index) =>
        moment(record.updated).format("DD-MM-YYYY, hh:mm:ss"),
    },
    {
      title: "User thao tác",
      key: "user_log",
      align: "center",
      dataIndex: "user_log",
      ellipsis: true,
      width: "6%",
      render: (text, record, index) => record.user_log[0].username,
    },
  ];

  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = pagination.pageSize;
    setPager({ ...currentPager });
  };

  useEffect(() => {
    setPager({
      pageSize: pageSize,
      count: 0,
      current: 1,
    });
  }, [idLogContract]);

  useEffect(() => {
    setPager({
      pageSize: pageSize,
      count: 0,
      current: 1,
    });
  }, [dataLog]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataLog}
        onChange={handleChange}
        rowClassName={"tableLogHistory"}
        locale={{ emptyText: "Không có dữ liệu" }}
        pagination={{
          current: pager.current,
          pageSize: pager.pageSize,
          // showSizeChanger: true,
          // showQuickJumper: true,
          // pageSizeOptions: ["10", "15", "25", "50"],
          // locale: {
          //   jump_to: "Đến trang: ",
          //   page: "",
          // },
        }}
        size="small"
      />
    </>
  );
};

export default CaNhan;

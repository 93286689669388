import React, { useEffect, useState } from "react";
import {
    Input,
    Row,
    Col,
    Popconfirm,
    Table,
} from "antd";
import { errorHandle, openNotificationWithIcon } from "../../../Function";
import {
    GetManageTeam,
    GetBoPhan,
    LockTeam,
    GetDetailTeam,
} from "../../../../api/manageTeam";
import {
    EditOutlined,
    LockOutlined,
    UnlockOutlined,
} from "@ant-design/icons";
import { Grid, Box, Button } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import "../qlyNguoiDung.css";
import { useSelector } from "react-redux";
import ModalAddTeam from "./ModalAddTeam";
import ModalEditTeam from "./ModalEditTeam";
import ModalEditDepartment from "./ModalEditDepartment";

const { Search } = Input;

const validateMessages = {
    required: "Không được để trống ô ${label} !",
    types: {
        email: "${label} đang sai định dạng!",
        number: "${label} not numbers!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const TableTeam = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [isEditDepartment, setIsEditDepartment] = useState(false);
    const [isModalAddNew, setIsModalAddNew] = useState(false);
    const [lsRole, setLsRole] = useState([]);
    const [dataInforTeam, setDataInforTeam] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [pager, setPager] = useState({
        pageSize: pageSize,
        count: 0,
        current: 1,
    });
    const [boPhan, setBoPhan] = useState([]);

    const userInfo = useSelector((state) => state.getUserInfo.userInfo);
    const checkRoleManageAdmin = userInfo.role_name.toLowerCase() === "manage_admin"


    const fetchData = (params = {}) => {
        setLoading(true);
        GetManageTeam(params)
            .then((res) => {
                setData(res.data.results);
                setPager({
                    current: params.page,
                    pageSize: params.page_size,
                    count: res.data.count,
                });
                setLoading(false);
            })
            .catch((err) => {
                errorHandle(err);
                setLoading(false);
            });
    };

    const getListBoPhan = (params = {}) => {
        GetBoPhan(params)
            .then((res) => {
                setBoPhan(res.data);
            })
            .catch((err) => {
                errorHandle(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        getListBoPhan();
    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            fetchData({ page: 1, page_size: pager.pageSize, search });
            clearInterval(id);
        }, 300);
        return () => clearInterval(id);
    }, [search]);

    const showModalAddTeam = () => {
        setIsModalAddNew(true);
    };

    const onLock_Unlock = (record) => {
        const lock = {
            team_id: record.id,
            is_lock: !record.is_active,
        };
        LockTeam(lock)
            .then((r) => {
                fetchData({ page: pager.current, page_size: pager.pageSize, search });
            })
            .catch((err) => {
                if (err.data.error) {
                    openNotificationWithIcon("error", err.data.error);
                }
            });
    };

    const onShowModalEdit = (record) => {
        GetDetailTeam(record.id)
            .then((re) => {
                setDataInforTeam(re.data);
                setIsEditing(true);
            })
            .catch((err) => { });
    };

    const renderDetailsButton = (record) => {
        return (
            <Box sx={{ width: "100%" }}>
                <Grid item container xs={12}>
                    <Grid
                        xs={4}
                        item
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    ></Grid>
                    <Grid
                        xs={2}
                        item
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <span
                            title="Chỉnh sửa"
                            className={"col-6"}
                            style={{ color: "#4290d3", cursor: "pointer", marginRight: 10 }}
                            onClick={() => onShowModalEdit(record)}
                        >
                            <EditOutlined />
                        </span>
                    </Grid>
                    <Grid
                        xs={2}
                        item
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {record.is_active !== true ? (
                            <span
                                title={"Team này đang khóa"}
                                className={"col-6"}
                                style={{ color: "red", cursor: "pointer" }}
                            >
                                <Popconfirm
                                    title="Xác nhận mở khóa Team"
                                    onConfirm={() => onLock_Unlock(record)}
                                    okText="Mở khóa"
                                    cancelText="Hủy"
                                >
                                    <LockOutlined style={{ color: "#ff4d4f" }} />
                                </Popconfirm>
                            </span>
                        ) : (
                            <span
                                title={"Team này đang mở"}
                                className={"col-6"}
                                style={{ color: "#008000", cursor: "pointer" }}
                            >
                                <Popconfirm
                                    title="Xác nhận khóa Team"
                                    onConfirm={() => onLock_Unlock(record)}
                                    okText="Khóa"
                                    cancelText="Hủy"
                                >
                                    <UnlockOutlined
                                        style={{ color: "green", cursor: "pointer" }}
                                    />
                                </Popconfirm>
                            </span>
                        )}
                    </Grid>
                </Grid>
            </Box>
        );
    };
    const columnsManageAdmin = [
        {
            title: "STT",
            dataIndex: "id",
            key: "id",
            align: "center",
            width: "5%",
            render: (value, item, index) =>
                index + 1 + (pager.current - 1) * pager.pageSize,
        },
        {
            title: "Tên Team",
            dataIndex: "name",
            align: "center",
            key: "name",
            ellipsis: true,
            render: (text, record) => text
        },
        {
            title: "Bộ phận",
            key: "bo_phan",
            align: "center",
            dataIndex: "bo_phan",
            ellipsis: true,
            render: (text, record, index) => record.bo_phan[0].name,
        },
    ];
    const columns = [
        {
            title: "STT",
            dataIndex: "id",
            key: "id",
            align: "center",
            width: "5%",
            render: (value, item, index) =>
                index + 1 + (pager.current - 1) * pager.pageSize,
        },
        {
            title: "Tên Team",
            dataIndex: "name",
            align: "center",
            key: "name",
            ellipsis: true,
            render: (text, record) => text
        },
        {
            title: "Bộ phận",
            key: "bo_phan",
            align: "center",
            dataIndex: "bo_phan",
            ellipsis: true,
            render: (text, record, index) => record.bo_phan[0].name,
        },
        {
            title: "Thao tác",
            align: "center",
            width: "15%",
            render: renderDetailsButton,
        },
    ];

    const onSearch = (e) => {
        setSearch(e);
    };

    const handleChange = (pagination) => {
        const currentPager = { ...pager };
        currentPager.current = pagination.current;
        currentPager.pageSize = pagination.pageSize;
        setPager({ ...currentPager });
        fetchData({
            status: currentPager.status,
            page_size: pagination.pageSize,
            page: pagination.current,
            search,
        });
    };

    const showModalEditDepartment = () => {
        setIsEditDepartment(true);
    };

    return (
        <>
            <Row style={{ width: "100%", marginBottom: "10px" }}>
                {screen.width > 768 ? (
                    <>
                        <Col span={16}>

                            <Button
                                className="btnAddUser btnCus"
                                style={{ marginRight: "16px", float: "left" }}
                                onClick={showModalAddTeam}
                            >
                                <ControlPointIcon style={{ fontSize: 20, marginRight: "5px" }} /> Thêm mới
                            </Button>
                            <Button
                                className="btnEditDepartment"
                                onClick={showModalEditDepartment}
                            >
                                <ControlPointIcon style={{ fontSize: 20, marginRight: "5px" }} /> Chỉnh sửa bộ
                                phận
                            </Button>
                        </Col>
                        <Col span={8}>
                            <Search
                                placeholder="Nhập thông tin cần Tìm kiếm"
                                allowClear
                                enterButton="Tìm kiếm"
                                size="middle"
                                onSearch={onSearch}
                                loading={false}
                            />
                        </Col>
                    </>
                ) : (
                    <>
                        <Col span={8}>
                            <Button
                                className="btnAddUser btnCus"
                                style={{ marginRight: "16px", float: "left", height: 32 }}
                                onClick={showModalAddTeam}
                            >
                                <ControlPointIcon style={{ marginRight: "5px" }} /> Thêm mới
                            </Button>
                        </Col>
                        <Col span={16}>
                            <Search
                                placeholder="Nhập thông tin cần Tìm kiếm"
                                allowClear
                                enterButton
                                size="middle"
                                onSearch={onSearch}
                                loading={false}
                            />
                        </Col>
                    </>
                )}
            </Row>
            <Row>
                <Col span={18} offset={3}>
                    <Table
                        columns={columns}
                        dataSource={data}
                        onChange={handleChange}
                        locale={{ emptyText: "Không có dữ liệu" }}
                        style={{ position: "absolute" }}
                        scroll={{
                            y: "60vh",
                        }}
                        pagination={{
                            current: pager.current,
                            pageSize: pager.pageSize,
                            total: pager.count,
                            showSizeChanger: true,
                            // showQuickJumper: true,
                            // pageSizeOptions: ["10", "15", "25", "50"],
                            // locale: {
                            //   jump_to: "Đến trang: ",
                            //   page: "",
                            // },
                        }}
                        size="middle"
                    />
                </Col>
            </Row>

            <ModalAddTeam
                open={isModalAddNew}
                onCancel={() => setIsModalAddNew(false)}
                lsRole={lsRole}
                loading={loading}
                boPhan={boPhan}
                fetchData={fetchData}
                pager={pager}
                getListBoPhan={getListBoPhan}
                data={data}
            />
            <ModalEditTeam
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                dataInforTeam={dataInforTeam}
                data={data}
                setLoading={setLoading}
                fetchData={fetchData}
                pager={pager}
                boPhan={boPhan}
            />
            <ModalEditDepartment boPhan={boPhan} setIsEditing={setIsEditing} setIsEditDepartment={setIsEditDepartment} isEditDepartment={isEditDepartment} fetchData={fetchData} pager={pager} getListBoPhan={getListBoPhan} setLoading={setLoading} />
        </>
    );
};

export default TableTeam
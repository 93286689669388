import React from "react";
import { Layout, Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./Login";
import { logout } from "../app/Actions/auth";
import "./userLayout.css";
const { Header, Content, Footer, Sider } = Layout;

// import logoLogin from "../images/logo ITIIS.png"

class UserLayout extends React.Component {
  render() {
    return (
      <div className="backgroundLogin">
        <Layout
          className="ant-layout"
          style={
            {
              background: "linear-gradient(to bottom right,#1c92d2 0,#6dcadd)",
            }
          }
        >
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ height: "94vh" }}
          >
            <Col sm={7} style={{margin: "auto"}} className="FormLogin">
              <Login />
            </Col>
          </Row>
        </Layout>

        <div className="custom-footer">
          <Footer className="custom-footer-login">
            Rainscales ©{new Date().getFullYear()} Made by DRI Team
          </Footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.token !== null,
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserLayout)
);

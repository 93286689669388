import React, { useEffect, useState } from "react";
import {
    Modal,
    Form,
    Row,
    Col,
    Input,
} from "antd";

import {
    openNotificationWithIcon,
} from "../../../Function";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
    PutCustomerApi,
} from "../../../../api/manageCustomer.js";
import "../quanLyKhachHang/QuanLyKH"

const validateMessages = {
    required: "Vui lòng nhập ${label} !",
    types: {
        email: "${label} sai định dạng!",
        number: "${label} phải là số!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};
const ModalEditCustomer = ({
    visible,
    onCancel,
    dataInforCustomer,
    pager,
    fetchData,
    lsRole,
    fullNameSearch,
    search
}) => {
    const [form] = Form.useForm();
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    useEffect(() => {
        setLoadingUpdate(false);
        form.resetFields();
        if (visible) {
            form.setFieldsValue({
                full_name: dataInforCustomer.full_name,
                name_organization: dataInforCustomer.name_organization,
                code_organization: dataInforCustomer.code_organization,
                address: dataInforCustomer.address,
                tax_code: dataInforCustomer.tax_code,
            });
        }
    }, [visible]);

    const onFinish = (values) => {
        setLoadingUpdate(true);
        PutCustomerApi({
            full_name: values.full_name.trim(),
            name_organization: values.name_organization.trim(),
            code_organization: values.code_organization,
            address: values.address,
            lock: false,
            id_customer: dataInforCustomer.id,
            tax_code: values.tax_code || "",
        })
            .then((res) => {
                if (res.data.error) {
                    openNotificationWithIcon("error", res.data.error);
                } else {
                    setLoadingUpdate(true);
                    fetchData({ page: pager.current, page_size: pager.pageSize, search, full_name: fullNameSearch});
                    openNotificationWithIcon(
                        "success",
                        "Thành công",
                        "Chỉnh sửa thông tin khách hàng thành công"
                    );
                    onCancel();
                    form.resetFields();
                }
            })
            .catch((err) => {
                if (err.data.error) {
                    setLoadingUpdate(false);
                    openNotificationWithIcon("error", err.data.error);
                }
            });
    };

    const onCloseModal = () => {
        form.resetFields();
        onCancel();
    }

    return (
        <Modal
            title="Chỉnh sửa thông tin khách hàng"
            visible={visible}
            onCancel={onCloseModal}
            width={screen.width > 768 ? 800 : "80%"}
            footer={false}
            bodyStyle={
                screen.width > 768
                    ? null
                    : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
                validateMessages={validateMessages}
                key="form_edit"
            >
                <Row>
                    <Col span={11}>
                        <Form.Item
                            name="full_name"
                            label="Tên khách hàng"
                            rules={[
                                {
                                    required: true,
                                },
                                // {
                                //   pattern: new RegExp(
                                //     "^[0-9a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$"
                                //   ),
                                //   message: "Không được nhập kí tự đặc biệt",
                                // },
                            ]}
                            key="full_name"
                        >
                            <Input
                                placeholder="Nhập tên khách hàng"
                                onBlur={(e) => {
                                    form.setFieldsValue({
                                        full_name: e.target.value.trim().toUpperCase(),
                                    });
                                }}
                                maxLength={255}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={2}>
                        <Form.Item
                            name="name_organization"
                            label="Tên theo hợp đồng"
                            rules={[
                                {
                                    required: true,
                                },
                                // {
                                //   pattern: new RegExp(
                                //     "^[0-9a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$"
                                //   ),
                                //   message: "Không được nhập kí tự đặc biệt",
                                // },
                            ]}
                            key="name_organization"
                        >
                            <Input
                                placeholder="Nhập tên theo hợp đồng"
                                onBlur={(e) => {
                                    form.setFieldsValue({
                                        name_organization: e.target.value.trim(),
                                    });
                                }}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            name="code_organization"
                            label="Mã khách hàng"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            key="code_organization"
                        >
                            <Input
                                placeholder="Nhập mã khách hàng"
                                maxLength={100}
                                onBlur={(e) => {
                                    form.setFieldsValue({
                                        last_name: e.target.value.trim(),
                                    });
                                }}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={2}>
                        <Form.Item
                            name="address"
                            label="Địa chỉ"
                            key="address"
                        >
                            <Input
                                placeholder="Nhập địa chỉ"
                                onBlur={(e) => {
                                    form.setFieldsValue({ address: e.target.value.trim() });
                                }}
                                maxLength={100}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            name="tax_code"
                            label="Mã số thuế"
                            // rules={[
                            //   {
                            //     required: true,
                            //   },
                            // ]}
                            key="tax_code"
                        >
                            <Input
                                placeholder="Nhập mã số thuế"
                                onBlur={(e) => {
                                    form.setFieldsValue({
                                        tax_code: e.target.value.trim(),
                                    });
                                }}
                                maxLength={100}
                            ></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    style={{ marginTop: "20px", textAlign: "center" }}
                    key="btn_edit"
                >
                    <LoadingButton
                        type="submit"
                        className={"m-2"}
                        style={{ marginRight: "20px" }}
                        variant="contained"
                        loading={loadingUpdate}
                    >
                        Chỉnh sửa
                    </LoadingButton>
                    <Button
                        onClick={onCloseModal}
                        color="error"
                        variant="contained"
                        disabled={loadingUpdate}
                    >
                        Hủy
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalEditCustomer
import { LoadingButton } from '@mui/lab';
import { Col, Form, Modal, Row, Select, Input, Upload, Button } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react';
import { PutForm } from '../../../../api/manageForm';
import { openNotificationWithIcon } from '../../../Function';
import { UploadOutlined } from '@ant-design/icons';
const { Option } = Select;

const validateMessages = {
    required: "${label} không được để trống!",
    types: {
        email: "${label} is not in the correct email format!",
        number: "${label} not numbers!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

function ModalEditForm({ isOpenModalEditForm, onCancelModalEdit, dataDetail, ruleTypeDocs, fetchFormData, pager, search }) {
    const [form] = Form.useForm();
    const [loadingEditBtn, setLoadingEditBtn] = useState(false)
    const [fileList, setFileList] = useState([])
    const [isChangeFile, setIsChangeFile] = useState(false)
    useEffect(() => {
        if (isOpenModalEditForm === true) {
            // form.resetFields()
            form.setFieldsValue({
                name_form: dataDetail.name_form,
                fore_rule_typedoc: dataDetail.fore_rule_typedoc
            })
            setFileList([
                {
                    uid: '1',
                    name: dataDetail.name_file,
                    status: 'done',
                    response: 'Server Error 500',
                    // custom error message to show
                    // url: dataDetail.file_form,
                }
            ])
        }
    }, [isOpenModalEditForm]);

    const onFinish = (values) => {
        setLoadingEditBtn(true)
        var FormData = require("form-data");
        var data = new FormData();
        data.append("form_id", dataDetail.id)
        data.append("id_rule", values.fore_rule_typedoc)
        data.append("name_form", values.name_form.trim().replace(/\s+/g, ' '))
        data.append("is_lock", true)
        if (isChangeFile === true) {
            data.append("file_form", values.file_form.fileList[1].originFileObj)
        }
        PutForm(data).then((res) => {
            form.resetFields();
            setLoadingEditBtn(false)
            fetchFormData({ page: pager.current, page_size: pager.pageSize, search });
            onCancelModalEdit()
            openNotificationWithIcon("success", res.data.result)
        }).catch(err => {
            setLoadingEditBtn(false)
            openNotificationWithIcon("error", err.data.error)
        })
    }

    const customShowUploadList = {
        showRemoveIcon: false,
    };

    const handleChange = (info) => {
        let newFileList = [info.fileList[1]];
        setIsChangeFile(true)
        setFileList(newFileList);
    };

    return (
        <>
            <Modal
                title="Chỉnh sửa biểu mẫu"
                visible={isOpenModalEditForm}
                onCancel={onCancelModalEdit}
                className="modalAddUser"
                width={screen.width > 768 ? 800 : "80%"}
                footer={false}
                bodyStyle={
                    screen.width > 768
                        ? null
                        : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
                }
            >
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    validateMessages={validateMessages}
                    initialValues={{
                        remember: true,
                    }}
                    key="form_docs"
                >
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                name="name_form"
                                label="Tên biêu mẫu"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: new RegExp(
                                            "^[a-zA-Z0-9À-ỹỲỳỴỵỶỷỸỹ_ \\-!@#$%^}&\\\\*=+{[\\|(`~),./?\\]]+$"
                                        ),
                                        message:
                                            "Không được nhập các kí tự đặc biệt như: \" : < > ; ' ",
                                    },
                                ]}
                                key="name_form"
                            >
                                <Input
                                    placeholder="Nhập tên biểu mẫu"
                                // onBlur={(e) => {
                                //     formAddCustomer.setFieldsValue({
                                //         name_organization: e.target.value.trim(),
                                //     });
                                // }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={2}>
                            <Form.Item
                                name="fore_rule_typedoc"
                                label="Loại tài liệu"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="fore_rule_typedoc"
                            >
                                <Select
                                    placeholder="Chọn loại tài liệu"
                                    showSearch={false}
                                    optionFilterProp="children"
                                    // mode="multiple"
                                    maxTagCount="responsive"
                                    // open={false}
                                    key="fore_rule_typedoc"
                                >
                                    {ruleTypeDocs.map((item, index) => (
                                        <Option key={index} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="file_form"
                                label={<span className="title-field-bill-check">File biểu mẫu</span>}
                                key="file_form"
                            // rules={[
                            //     {
                            //         required: true,
                            //     },
                            // ]}
                            >
                                <Upload
                                    fileList={fileList}
                                    // onRemove={false}
                                    showUploadList={customShowUploadList}
                                    onChange={(info) => handleChange(info)}
                                    beforeUpload={(file, FileList) => {
                                        const checkSize = file.size / 1024 / 1024 > 50;
                                        if (checkSize) {
                                            openNotificationWithIcon("error", "Lỗi", `Đã quá 5MB !`);
                                        }

                                        return !checkSize
                                            ? false
                                            : Upload.LIST_IGNORE;
                                    }}>
                                    <Button htmlType="button" icon={<UploadOutlined />}>
                                        Click to Upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        style={{ marginTop: "20px", textAlign: "center" }}
                        key="btn_docs"
                    >
                        <LoadingButton
                            type="submit"
                            className={"m-2"}
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            loading={loadingEditBtn}
                        >
                            Chỉnh sửa
                        </LoadingButton>
                        <LoadingButton
                            onClick={onCancelModalEdit}
                            color='error'
                            className={"m-2"}
                            variant="contained"
                            disabled={loadingEditBtn}
                        >
                            Hủy
                        </LoadingButton>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default ModalEditForm
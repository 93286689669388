import { authAxios } from "../api/axiosClient";
import { exportFormTemplate, formTemplate } from "../constants";

export const GetFormTemplate = (params = {}) => {
    const url = formTemplate;
    return authAxios().get(url, {params});
};

export const ExportFormTemplateApi = (params = {}) => {
    const url = exportFormTemplate;
    return authAxios().post(url, params, {responseType: "blob"});
};

export const PutForm = (params = {}) => {
    const url = formTemplate;
    return authAxios().put(url, params);
};

export const PostForm = (params = {}) => {
    const url = formTemplate;
    return authAxios().post(url, params);
};
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { AutoComplete, Col, Form, Input, Modal, Row } from 'antd';
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { PostRuleTypeDocument, PostTypeDocument } from '../../../../api/manageDocs';
import { openNotificationWithIcon, toSlug } from '../../../Function';
import '../taiLieu/taiLieu.css';

const validateMessages = {
    required: "${label} không được để trống!",
    types: {
        email: "${label} is not in the correct email format!",
        number: "${label} not numbers!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const ModalAddDocs = ({ open, onCancel, lsRole, loading, fetchData, pager, dataAllTypeDocs, dataAllNameDocs, search, fetchAllTypeDocument, fetchAllNameDocument }) => {
    const [formAddDoc] = Form.useForm();
    const [loadingAddNew, setLoadingAddNew] = useState(false);
    const [dataNameDocsFilter, setDataNameDocsFilter] = useState([])


    useEffect(() => {
        formAddDoc.resetFields();
        if (open === true) {
            fetchAllTypeDocument()
            fetchAllNameDocument()
            setDataNameDocsFilter([])
        }
    }, [open]);

    const addNewTypeDoc = (values) => {
        const data = {
            name: values.type_document,
        }
        PostRuleTypeDocument(data).then(res => {
            onCancel()
            fetchData({ page: 1, page_size: pager.pageSize, search })
            openNotificationWithIcon("success", res.data.result);
        }).catch(err => {
            console.log(err)
        })
    }

    // const addNewNameDoc = (values) => {
    //   let id = 0;
    //   for (let i = 0; i < dataAllTypeDocs.length; i++) {
    //     if (values.type_document.toLowerCase() === dataAllTypeDocs[i].name.toLowerCase()) {
    //       id = dataAllTypeDocs[i].id;
    //       break;
    //     } else {
    //       id = dataAllTypeDocs[length - 1].id + 1
    //     }
    //   }
    //   const data = {
    //     name: values.name_document,
    //     id_rule: id,
    //   }
    //   PostTypeDocument(data).then(res => {
    //     fetchData({ page: 1, page_size: pager.pageSize, search })

    //     openNotificationWithIcon("success", res.data.result);
    //     onCancel()
    //   }).catch(err => {
    //     openNotificationWithIcon("error", err.data.error);
    //   })
    // }

    const onFinish = (values) => {
        // if (!dataAllTypeDocs.map((item) => item.name.toLowerCase()).includes(values.type_document.toLowerCase())) {
        //   addNewTypeDoc(values);
        // }
        // if (values.name_document !== "" && values.name_document !== undefined) {
        //   // if (dataAllNameDocs.map((item) => item.name.toLowerCase()).includes(values.name_document.toLowerCase())) {
        //   //   openNotificationWithIcon("error", "Tên tài liệu đã tồn tại");
        //   // } else {
        //   addNewNameDoc(values)
        // }

        setLoadingAddNew(true)
        const data = {
            name_rule: values.type_document.trim().replace(/\s+/g, ' '),
            name: values.name_document !== "" && values.name_document !== null && values.name_document !== undefined ? values.name_document.trim().replace(/\s+/g, ' ') : "",
        }
        PostTypeDocument(data).then(res => {
            fetchData({ page: 1, page_size: pager.pageSize, search })
            openNotificationWithIcon("success", res.data.result);
            onCancel()
            setLoadingAddNew(false)

        }).catch(err => {
            setLoadingAddNew(false)

            openNotificationWithIcon("error", err.data.error);
        })
    };

    const onFinishFailed = (errorInfo) => {
        openNotificationWithIcon("error", "Lỗi");
    };

    const onCloseModal = () => {
        formAddDoc.resetFields();
        onCancel();
    };

    const disabledDate = (current) => {
        return current && current > moment().endOf("day");
    };

    const onChangeFields = (event, field, intChoose) => {
        let valueTemp = event.target.value;
        switch (intChoose) {
            case 1:
                if (
                    event.target.value.charAt(event.target.value.length - 1) === "." ||
                    event.target.value === "-"
                ) {
                    valueTemp = event.target.value.slice(0, -1);
                }
                let newValue = toSlug(event.target.value);
                formAddDoc.setFieldsValue({
                    [field]: newValue,
                    // username: valueTemp.replace(/\s+/, ''),
                });
                break;
            case 2:
                break;
            default:
            // code block
        }
    };

    const handleChangeTypeDocs = (value, data) => {
        // const filterNameDocs = dataAllNameDocs.filter(item => item.data_rule[0].id === data.id_type_document)
        const filterNameDocs = dataAllNameDocs.filter(item => item.fore_rule_typedoc === data.id_type_document).filter(item => item.name !== "" && item.name !== null && item.name !== undefined)
        setDataNameDocsFilter(filterNameDocs)
        formAddDoc.setFieldsValue({ type_document: value })
    }

    useEffect(() => {
        formAddDoc.resetFields(["name_document"])
    }, [dataNameDocsFilter]);

    return (
        <Modal
            title="Thêm tài liệu"
            visible={open}
            onCancel={onCloseModal}
            className="modalAddUser"
            width={screen.width > 768 ? 800 : "80%"}
            footer={false}
            bodyStyle={
                screen.width > 768
                    ? null
                    : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
            }
        >
            <Form
                form={formAddDoc}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
                // initialValues={{
                //   remember: true,
                // }}
                key="form_docs"
            >
                <Row>
                    <Col span={11}>
                        <Form.Item
                            name="type_document"
                            label="Loại tài liệu"
                            rules={[
                                {
                                    required: true,
                                },
                                {
                                    pattern: new RegExp(
                                        "^[a-zA-Z0-9À-ỹỲỳỴỵỶỷỸỹ_ \\-!@#$%^}&\\\\*=+{[\\|(`~),./?\\]]+$"
                                    ),
                                    message:
                                        "Không được nhập các kí tự đặc biệt như: \" : < > ; ' ",
                                },
                            ]}
                            key="bo_phan"
                        >
                            <Input.Group compact key="add_team">
                                <AutoComplete
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Nhập hoặc chọn loại tài liệu"
                                    onChange={handleChangeTypeDocs}
                                    key="auto"
                                >
                                    {dataAllTypeDocs.map((item, index) => (
                                        <AutoComplete.Option key={index} value={item.name} id_type_document={item.id}>
                                            {item.name}
                                        </AutoComplete.Option>
                                    ))}
                                </AutoComplete>
                            </Input.Group>
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={2}>
                        <Form.Item
                            name="name_document"
                            label="Tên tài liệu"
                            key="name_document"
                            rules={[
                                {
                                    pattern: new RegExp(
                                        "^[a-zA-Z0-9À-ỹỲỳỴỵỶỷỸỹ_ \\-!@#$%^}&\\\\*=+{[\\|(`~),./?\\]]+$"
                                    ),
                                    message:
                                        "Không được nhập các kí tự đặc biệt như: \" : < > ; ' ",
                                },
                            ]}
                        >
                            <Input.Group compact key="name_document">
                                <AutoComplete
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Nhập hoặc chọn tên tài liệu"
                                    key="auto"
                                    onChange={(value) => {
                                        formAddDoc.setFieldsValue({ name_document: value });
                                    }}
                                >
                                    {dataNameDocsFilter.map((item) => (
                                        <AutoComplete.Option key={item.id} value={item.name} id_name_doc={item.fore_rule_typedoc}>
                                            {item.name}
                                        </AutoComplete.Option>
                                    ))}
                                </AutoComplete>
                            </Input.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    style={{ marginTop: "20px", textAlign: "center" }}
                    key="btn_docs"
                >
                    <LoadingButton
                        type="submit"
                        className={"m-2"}
                        style={{ marginRight: "20px" }}
                        variant="contained"
                        loading={loadingAddNew}
                    >
                        Đăng ký
                    </LoadingButton>
                    <Button
                        onClick={onCloseModal}
                        color="error"
                        variant="contained"
                        disabled={loadingAddNew}
                    >
                        Hủy
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAddDocs
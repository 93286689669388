// export const localhost = 'http://192.168.1.6:8080'
// export const localhost = 'http://192.168.20.234:4001'
// export const localhost = 'http://192.168.20.162:4000'
// export const localhost = 'http://10.1.21.123:4000'
// export const localhost = 'http://10.1.21.192:2001'
// export const localhost = 'http://192.168.1.130:5002'

export const localhost = 'https://qlct.rainscales.com.vn'



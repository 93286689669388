import { LoadingButton } from '@mui/lab';
import {Button } from '@mui/material';
import {Col, Form, Input, Modal, Row, Select } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import {GetRuleTypeDocumentAll, PutRuleTypeDocument} from '../../../../api/manageDocs';
import { errorHandle, openNotificationWithIcon } from '../../../Function';
import '../taiLieu/taiLieu.css';

const { Option } = Select;

const validateMessages = {
    required: "${label} không được để trống!",
    types: {
        email: "${label} is not in the correct email format!",
        number: "${label} not numbers!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const ModalEditRuleDocument = ({ isOpenModalEditRuleDoc, setIsOpenModalEditRuleDoc, fetchData, pager, search }) => {
    const [formEditRuleDoc] = Form.useForm();
    const [dataAllTypeDocs, setDataAllTypeDocs] = useState([])
    const [loadingEdit, setLoadingEdit] = useState(false)

    const fetchAllTypeDocument = () => {
        // setLoading(true);
        GetRuleTypeDocumentAll()
            .then((res) => {
                setDataAllTypeDocs(res.data)
                // setData(res.data.results);
                // setPager({
                //   current: params.page,
                //   pageSize: params.page_size,
                //   count: res.data.count,
                // });
                // setLoading(false);
            })
            .catch((err) => {
                errorHandle(err);
                // setLoading(false);
            });
    };

    useEffect(() => {
        if (isOpenModalEditRuleDoc) {
            formEditRuleDoc.resetFields()
            fetchAllTypeDocument()

        }
    }, [isOpenModalEditRuleDoc]);

    const onCloseModal = () => {
        setIsOpenModalEditRuleDoc(false)
    }

    const onFinish = (values) => {
        setLoadingEdit(true)
        const data = {
            id_rule: values.rule_document,
            name: values.new_rule_document,
            is_lock: true,
        }

        PutRuleTypeDocument(data).then(res => {
            setLoadingEdit(false)
            setIsOpenModalEditRuleDoc(false)
            openNotificationWithIcon("success", res.data.result)
            fetchData({ page: pager.current, page_size: pager.pageSize, search });
        }).catch(err => {
            setLoadingEdit(false)
            openNotificationWithIcon("error", err.data.error)
        })
    }

    return (
        <>
            <Modal
                title="Chỉnh sửa loại tài liệu"
                visible={isOpenModalEditRuleDoc}
                // onCancel={onCloseModal}
                className="modalAddUser"
                width={screen.width > 768 ? 800 : "80%"}
                footer={false}
                bodyStyle={
                    screen.width > 768
                        ? null
                        : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
                }
            >
                <Form
                    form={formEditRuleDoc}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    // loading={loading}
                    initialValues={{
                        remember: true,
                    }}
                    key="form_team"
                >
                    <Row>
                        <Col span={11}>
                            <Form.Item name="rule_document" label="Loại tài liệu cũ" key="rule_document"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}>
                                <Select
                                    placeholder="Chọn Loại tài liệu"
                                    showSearch={false}
                                    optionFilterProp="children"
                                    maxTagCount="responsive"
                                    allowClear
                                    style={{
                                        width: "100%",
                                    }}
                                    key="rule_document"
                                >
                                    {dataAllTypeDocs.map((item, index) => (
                                        <Option key={item.name} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={2}>
                            <Form.Item
                                name="new_rule_document"
                                label="Loại tài liệu mới"
                                key="new_rule_document"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Nhập loại tài liệu"
                                    maxLength={100}
                                ></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        style={{ marginTop: "20px", textAlign: "center" }}
                        key="btn_team"
                    >
                        <LoadingButton
                            type="submit"
                            className={"m-2"}
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            loading={loadingEdit}
                        >
                            Chỉnh sửa
                        </LoadingButton>
                        <Button
                            onClick={onCloseModal}
                            color="error"
                            variant="contained"
                        // disabled={loadingAddNew}
                        >
                            Hủy
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default ModalEditRuleDocument
import React, { useEffect, useState } from "react";
import {
  Table,
  Form,
  Row,
  Col,
  Select,
  Input,
  Popconfirm,
  Upload,
} from "antd";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import {
  errorHandle,
  openNotificationWithIcon,
} from "../../../Function";
import moment from "moment";
import { Grid, Box, Button } from "@mui/material";

import {
  EditOutlined,
  LockOutlined,
  UnlockOutlined,
  DownloadOutlined,
  FilterOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  GetManageCustomer,
  ExportCustomer,
} from "../../../../api/manageCustomer";

import {
  GetInforUserCustomer,
  PutCustomerApi,
} from "../../../../api/manageCustomer.js";
import "../quanLyKhachHang/QuanLyKH"
import { useSelector } from "react-redux";
import ModalAddCustomer from "./ModalAddCustomer";
import ModalEditCustomer from "./ModalEditCustomer";
import FilterCustomer from "./FilterCustomer";
import ModalImportFileCustomer from "./ModalImportFileCustomer";

const validateMessages = {
  required: "Vui lòng nhập ${label} !",
  types: {
    email: "${label} sai định dạng!",
    number: "${label} phải là số!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
const { Option } = Select;
const { Search } = Input;
const { Dragger } = Upload;

const TableCustomer = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isModalAddNew, setIsModalAddNew] = useState(false);
  const [isModalImportFile, setIsModalImportFile] = useState(false);
  const [lsRole, setLsRole] = useState([]);
  const [dataInforCustomer, setDataInforCustomer] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [fullNameSearch, setFullNameSearch] = useState([])
  const [openInputSearch, setOpenInputSearch] = useState(false);

  const [pager, setPager] = useState({
    pageSize: pageSize,
    count: 0,
    current: 1,
  });

  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const checkRoleManageAdmin = userInfo.role_name.toLowerCase() === "manage_admin"

  const [formFilter] = Form.useForm();

  const fetchData = (params = {}) => {
    setLoading(true);
    GetManageCustomer(params)
      .then((res) => {
        setData(res.data.results);
        setPager({
          current: params.page,
          pageSize: params.page_size,
          count: res.data.count,
        });
        setLoading(false);
      })
      .catch((err) => {
        errorHandle(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    const id = setInterval(() => {
      fetchData({ page: 1, page_size: pager.pageSize, search });
      clearInterval(id);
    }, 300);
    return () => clearInterval(id);
  }, [search]);

  const showModalAddCustomer = () => {
    setIsModalAddNew(true);
  };

  const showModalImportFile = () => {
    setIsModalImportFile(true);
  };

  const onShowModalEdit = (record) => {
    GetInforUserCustomer(record.id)
      .then((re) => {
        setDataInforCustomer(re.data);
        setIsEditing(true);
      })
      .catch((err) => {
        if (err.data.error) {
          openNotificationWithIcon("error", err.data.error);
        }
      });
  };

  const onLock_Unlock = (record) => {
    PutCustomerApi({
      id_customer: record.id,
      lock: "true",
      isActive: !record.is_active,
    })
      .then((r) => {
        fetchData({ page: pager.current, page_size: pager.pageSize, search });
      })
      .catch((err) => {
        if (err.data.error) {
          openNotificationWithIcon("error", err.data.error);
        }
      });
  };

  const renderDetailsButton = (record) => {
    return (
      <Box sx={{ width: "100%" }}>
        <Grid item container xs={12}>
          <Grid
            xs={3}
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
          ></Grid>
          <Grid
            xs={3}
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <span
              title="Chỉnh sửa"
              className={"col-6"}
              style={{ color: "#4290d3", cursor: "pointer" }}
              onClick={() => onShowModalEdit(record)}
            >
              <EditOutlined />
            </span>
          </Grid>
          <Grid
            xs={3}
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {record.is_active === false ? (
              <span
                title={"Khách hàng đang khóa"}
                className={"col-6"}
                style={{ color: "red", cursor: "pointer" }}
              >
                <Popconfirm
                  title="Xác nhận mở khóa khách hàng"
                  onConfirm={() => onLock_Unlock(record)}
                  okText="Mở khóa"
                  cancelText="Hủy"
                >
                  <LockOutlined style={{ color: "#ff4d4f" }} />
                </Popconfirm>
              </span>
            ) : (
              <span
                title={"Khách hàng đang mở"}
                className={"col-6"}
                style={{ color: "#008000", cursor: "pointer" }}
              >
                <Popconfirm
                  title="Xác nhận khóa khách hàng"
                  onConfirm={() => onLock_Unlock(record)}
                  okText="Khóa"
                  cancelText="Hủy"
                >
                  <UnlockOutlined style={{ color: "#008000" }} />
                </Popconfirm>
              </span>
            )}
          </Grid>
          <Grid
            xs={3}
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
          ></Grid>
        </Grid>
      </Box>
    );
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
      width: "5%",
    },
    {
      title: "Mã khách hàng",
      dataIndex: "code_organization",
      key: "code_organization",
      ellipsis: true,
      align: "center",
      render: (text, record) => text
    },
    {
      title: "Tên khách hàng",
      dataIndex: "full_name",
      key: "full_name",
      ellipsis: true,
      align: "center",
      render: (text, record) => text
    },
    {
      title: "Tên theo hợp đồng",
      dataIndex: "name_organization",
      align: "center",
      key: "name_organization",
      ellipsis: true,
      render: (text, record) => text
    },
    {
      title: "Thao tác",
      align: "center",
      render: renderDetailsButton,
      key: "thaotac",
      width: "10%",
    },
  ];

  const onSearch = (e) => {
    setFullNameSearch([])
    formFilter.resetFields();
    fetchData({
      // status: currentPager.status,
      page_size: pager.pageSize,
      page: 1,
      search: e,
      full_name: [],
    });
    setSearch(e);
  };

  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = pagination.pageSize;
    setPager({ ...currentPager });
    fetchData({
      // status: currentPager.status,
      page_size: pagination.pageSize,
      page: pagination.current,
      search,
      full_name: fullNameSearch,
    });
  };

  const fileDownload_Customer = () => {
    // setLoading_page(true);
    const fnFileDownload = require("js-file-download");
    const filename =
      "DanhSachKhachHang_" + moment(new Date()).format("DDMMYYYY") + ".xlsx";
    ExportCustomer({ responseType: "blob" })
      .then((res) => {
        fnFileDownload(res.data, filename);
        // setLoading_page(false);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Không tải được danh sách hồ sơ. Bạn vui lòng thử lại."
        );
        // setLoading_page(false);
      });
  };

  const openSearch = () => {
    setOpenInputSearch(!openInputSearch);
  };

  return (
    <>
      <Row className="row" style={{ width: "100%", marginBottom: "10px" }}>
        <Col span={16}>

            <Button
              className="btnAddUser"
              style={{ marginRight: "15px", float: "left" }}
              onClick={showModalAddCustomer}
            >
              <ControlPointIcon
                style={{ fontSize: 20, marginRight: "5px" }}
              />{" "}
              Thêm mới
            </Button>
            <Button
              onClick={fileDownload_Customer}
              className="btnAddUser"
              style={{ float: "left", backgroundColor: "green" }}
            >
              <DownloadOutlined
                style={{
                  fontSize: 16,
                  marginRight: 5,
                }}
              />
              Tải xuống
            </Button>

            <Button
              className="btnAddUser"
              style={{
                float: "left",
                backgroundColor: "#FF4C49",
                marginLeft: 15,
              }}
              onClick={showModalImportFile}
            >
              <UploadOutlined
                style={{
                  fontSize: 16,
                  marginRight: 5,
                }}
              />
              Tải lên
            </Button>
          <Button
            onClick={openSearch}
            className="btnAddUser"
            style={{
              float: "right",
              marginRight: 15,
              backgroundColor: "#1890ff",
            }}
          >
            <FilterOutlined
              style={{
                fontSize: 16,
                marginRight: 5,
              }}
            />
            Lọc
          </Button>
        </Col>

        <Col span={8}>
          <Search
            placeholder="Nhập thông tin cần Tìm kiếm"
            allowClear
            enterButton="Tìm kiếm"
            size="middle"
            onSearch={onSearch}
            loading={false}
          />
        </Col>
      </Row>
      {openInputSearch === true ? <FilterCustomer pager={pager} fetchData={fetchData} search={search} setPager={setPager} setData={setData} setSearch={setSearch} setFullNameSearch={setFullNameSearch} formFilter={formFilter} /> : null}
      <Row>
        <Col span={20} offset={2}>
          <Table
            columns={columns}
            dataSource={data}
            onChange={handleChange}
            rowClassName={"styleTableLeTet"}
            locale={{ emptyText: "Không có dữ liệu" }}
            key={"table-customer"}
            style={{ position: "absolute" }}
            scroll={{
              y: openInputSearch === true ? "60vh" : "67vh",
            }}
            pagination={{
              current: pager.current,
              pageSize: pager.pageSize,
              total: pager.count,
              // showQuickJumper: true,
              // pageSizeOptions: ["10", "15", "25", "50"],

              // locale: {
              //   jump_to: "Đến trang: ",
              //   page: "",
              // },
              showSizeChanger: true,
            }}
            // size="middle"
            size={screen.availHeight < 850 ? "small" : "middle"}
          />
        </Col>
      </Row>

      <ModalImportFileCustomer
        open={isModalImportFile}
        onCancel={() => setIsModalImportFile(false)}
        fetchData={fetchData}
        pager={pager}
        setIsModalImportFile={setIsModalImportFile}
      />
      <ModalAddCustomer
        open={isModalAddNew}
        onCancel={() => setIsModalAddNew(false)}
        lsRole={lsRole}
        fetchData={fetchData}
        pager={pager}
      />

      <ModalEditCustomer
        visible={isEditing}
        onCancel={() => setIsEditing(false)}
        dataInforCustomer={dataInforCustomer}
        pager={pager}
        fetchData={fetchData}
        lsRole={lsRole}
        search = {search}
        fullNameSearch={fullNameSearch}
      />
    </>
  );
};

const TableOfPage = () => {
  return (
    // <Tabs defaultActiveKey="1" key="def">
    //   <Tabs.TabPane tab="Danh sách khách hàng" key="1">
    //     <TableCustomer />
    //   </Tabs.TabPane>
    //   <Tabs.TabPane tab="Danh sách loại tài liệu" key="2">
    //     <TableDocs />
    //   </Tabs.TabPane>
    // </Tabs>
    <>
      <TableCustomer />
    </>
  );
};

function QuanLyKH() {
  return <TableOfPage key="22" />;
}

export default QuanLyKH;

import React, { useEffect, useState } from "react";
import {
    Modal,
    Form,
    Row,
    Col,
    Select,
    Steps,
    Input,
    Result,
    Upload,
    DatePicker,
    Checkbox,
    InputNumber,
    Tooltip,
    Drawer,
} from "antd";
import { useStepsForm } from "sunflower-antd";
import "../thuNhan/CaNhan.css"
import moment from "moment";
import { openNotificationWithIcon, QuiTacDatTenKH } from "../../../Function";

import {
    InboxOutlined,
    PlusOutlined,
    BulbOutlined,
} from "@ant-design/icons";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";

import { GetManageCustomer, PostManageCustomer } from "../../../../api/manageCustomer.js";
import { useDispatch, useSelector } from "react-redux";
import { addContract } from "../../../../app/Actions/getQuantityNoti";
import dayjs from "dayjs";
import { PostManageUpload, TemplateContract } from "../../../../api/manageContract";
import { GetManageUser } from "../../../../api/manageUser";
import { GetManageTeam } from "../../../../api/manageTeam";
const { Step } = Steps;
const { Dragger } = Upload;

const validateMessages = {
    required: "Vui lòng nhập ${label} !",
    types: {
        email: "${label} sai định dạng!",
        number: "${label} phải là số!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const dateFormat = "DD-MM-YYYY";
const { Option } = Select;

const ModalUploadContract = ({
    open,
    onCancel,
    fetchData,
    pager,
    customers,
    legalEntity,
    dataAllNameDocs,
    typeDocsAll,
    typeDocument,
    userWithRoleMember,
    setOpenInputSearch
}) => {
    const [addCustomer, setAddCustomer] = useState(false);
    const [customer, setCustomer] = useState([]);

    useEffect(() => {
        var listCustomer = customers.filter(
            (customer) => customer.is_active === true
        );
        setCustomer(listCustomer.sort((a, b) => a.full_name.localeCompare(b.full_name)));
    }, [customers]);

    const validateMessages = {
        required: "${label} không được để trống!",
        types: {
            email: "${label} is not a valid email!",
            number: "${label} is not a valid number!",
        },
        number: {
            range: "${label} must be between ${min} and ${max}",
        },
    };

    const [form] = Form.useForm();
    const onCloseModal = () => {
        form.resetFields();
        onCancel();
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    const ModalAddCustomer = ({ open, onCancel }) => {
        const [formAddCustomer] = Form.useForm();
        const [loadingAddNew, setLoadingAddNew] = useState(false);
        const GetListCustomer = () => {
            GetManageCustomer({ page: 1, page_size: 5000 }).then((res) => {
                setCustomer(res.data.results);
            });
        };

        useEffect(() => {
            formAddCustomer.resetFields();
        }, []);

        const onFinish = (values) => {
            let check = true;
            for (let i = 0; i < values.full_name.length; i++) {
                if (values.full_name[i] === " " && values.full_name[i + 1] === " ") {
                    openNotificationWithIcon(
                        "error",
                        "Thất bại",
                        "Không được có quá nhiều khoảng cách giữa 2 từ"
                    );
                    check = true;
                    return;
                } else {
                    check = false;
                }
            }
            if (check === false) {
                setLoadingAddNew(true);
                PostManageCustomer({
                    full_name: values.full_name.trim(),
                    name_organization: values.name_organization.trim(),
                    code_organization: values.code_organization,
                    numberphone: values.numberphone,
                    email: values.email,
                    profession: "",
                    position: "",
                    address: values.address,
                    tax_code: "",
                })
                    .then((res) => {
                        if (res.data.error) {
                            openNotificationWithIcon("error", res.data.error);
                        } else {
                            setLoadingAddNew(false);
                            openNotificationWithIcon(
                                "success",
                                "Thành công",
                                "Thêm mới khách hàng thành công"
                            );
                            onCloseModal();
                            formAddCustomer.resetFields();
                            setCustomer([...customer, values]);
                            GetListCustomer();
                        }
                    })
                    .catch((err) => {
                        if (err.data.error) {

                            setLoadingAddNew(false);
                            openNotificationWithIcon("error", err.data.error);
                        }
                    });
            }
        };

        const onCloseModal = () => {
            form.resetFields();
            onCancel();
        };

        const [openDraw, setOpenDraw] = useState(false);
        const showDrawer = () => {
            setOpenDraw(true);
        };
        const onClose = () => {
            setOpenDraw(false);
        };

        return (
            <Modal
                title="Thêm khách hàng"
                visible={open}
                onCancel={onCancel}
                className="modalAddUser site-drawer-render-in-current-wrapper"
                width={screen.width > 768 ? 800 : "80%"}
                footer={false}
                bodyStyle={
                    screen.width > 768
                        ? null
                        : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
                }
            >
                <Drawer
                    title={
                        <h3 style={{ textAlign: "center" }}>
                            Quy định trong khai báo thông tin khách hàng
                        </h3>
                    }
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    key="right"
                    visible={openDraw}
                    style={{ position: "absolute" }}
                    getContainer={false}
                    width="70%"
                >
                    <QuiTacDatTenKH />
                </Drawer>
                <Form
                    form={formAddCustomer}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    validateMessages={validateMessages}
                    initialValues={{
                        remember: true,
                    }}
                    key="form"
                >
                    <Row>
                        <Col span={10}>
                            <Form.Item
                                name="full_name"
                                label="Tên khách hàng"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    // {
                                    //   pattern: new RegExp(
                                    //     "^[0-9a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$"
                                    //   ),
                                    //   message: "Không được nhập kí tự đặc biệt",
                                    // },
                                ]}
                                key="full_name"
                            >
                                <Input
                                    placeholder="Nhập tên khách hàng"
                                    onBlur={(e) => {
                                        formAddCustomer.setFieldsValue({
                                            full_name: e.target.value.trim(),
                                        });
                                    }}
                                    maxLength={30}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={1}>
                            <BulbOutlined
                                onClick={showDrawer}
                                className="customIconDrawer"
                            />
                        </Col>
                        <Col span={11} offset={2}>
                            <Form.Item
                                name="name_organization"
                                label="Tên theo hợp đồng"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    // {
                                    //   pattern: new RegExp(
                                    //     "^[0-9a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$"
                                    //   ),
                                    //   message: "Không được nhập kí tự đặc biệt",
                                    // },
                                ]}
                                key="name_organization"
                            >
                                <Input
                                    placeholder="Nhập tên theo hợp đồng"
                                    onBlur={(e) => {
                                        formAddCustomer.setFieldsValue({
                                            name_organization: e.target.value.trim(),
                                        });
                                    }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="code_organization"
                                label="Mã khách hàng"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="code_organization"
                            >
                                <Input
                                    placeholder="Nhập mã khách hàng"
                                    maxLength={100}
                                    onBlur={(e) => {
                                        formAddCustomer.setFieldsValue({
                                            last_name: e.target.value.trim(),
                                        });
                                    }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        {/* <Col span={11} offset={2}>
                            <Form.Item
                                name="numberphone"
                                label="Số điện thoại"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="numberphone"
                            >
                                <Input
                                    pattern="^[0-9]*$"
                                    placeholder="Nhập số điện thoại"
                                    onBlur={(e) => {
                                        formAddCustomer.setFieldsValue({
                                            numberphone: e.target.value.trim(),
                                        });
                                    }}
                                    maxLength={100}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                    },
                                ]}
                                key="email"
                            >
                                <Input
                                    placeholder="Nhập email"
                                    onBlur={(e) => {
                                        formAddCustomer.setFieldsValue({
                                            email: e.target.value.trim(),
                                        });
                                    }}
                                    maxLength={100}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={2}>
                            <Form.Item
                                name="address"
                                label="Địa chỉ"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="address"
                            >
                                <Input
                                    placeholder="Nhập địa chỉ"
                                    onBlur={(e) => {
                                        formAddCustomer.setFieldsValue({
                                            address: e.target.value.trim(),
                                        });
                                    }}
                                    maxLength={100}
                                ></Input>
                            </Form.Item>
                        </Col> */}
                    </Row>

                    <Form.Item
                        style={{ marginTop: "20px", textAlign: "center" }}
                        key="button"
                    >
                        <LoadingButton
                            type="submit"
                            className={"m-2"}
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            loading={loadingAddNew}
                        >
                            Đăng ký
                        </LoadingButton>
                        <Button
                            onClick={onCloseModal}
                            color="error"
                            variant="contained"
                            disabled={loadingAddNew}
                        >
                            Hủy
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const BoxNewSchedule_pro = () => {
        const {
            form,
            current,
            gotoStep,
            stepsProps,
            formProps,
            submit,
            formLoading,
        } = useStepsForm({
            async submit(values) {
                var flag = false;
                for (let i = 0; i < values.file_document.fileList.length; i++) {
                    for (let j = i + 1; j < values.file_document.fileList.length; j++) {
                        if (
                            values.file_document.fileList[i].name ===
                            values.file_document.fileList[j].name
                        ) {
                            openNotificationWithIcon(
                                "error",
                                "Thất bại",
                                `file ${values.file_document.fileList[i].name} đã có trong danh sách`
                            );
                            flag = true;
                            return;
                        } else {
                            flag = false;
                        }
                    }
                }
                if (flag === false) {
                    addNewFile(values);
                }
                await new Promise((r) => setTimeout(r, 500));
                return "ok";
            },
            total: 2,
            isBackValidate: false,
        });

        const [checkInfinite, setCheckInfinite] = useState(false);
        const [loadingAddNew, setLoadingAddNew] = useState(false);
        const [team, setTeam] = useState([]);
        const [time, setTime] = useState(["Ngày", "Tháng", "Năm"]);
        const [search, setSearch] = useState("");
        const [fileList, setFileList] = useState([]);
        const [ruleTypeDocument, setRuleTypeDocument] = useState([])
        const [userShare, setUserShare] = useState([]);
        const [dataUserShareFilter, setDataUserShareFilter] = useState([])
        const [dataAllNameDocsFilter, setDataAllNameDocsFilter] = useState([])

        const dispatch = useDispatch();
        var idName = 0


        // const GetListDocument = () => {
        //     GetListTypeDocument({
        //         page: 1, page_size: 5000
        //     }).then((res) => {
        //         var listTypeDocument = res.data.results.filter(
        //             (docs) => docs.is_active === true
        //         );
        //         setTypeDocument(listTypeDocument.sort((a, b) => a.name.localeCompare(b.name)));
        //     });
        // };

        const GetListUser = () => {
            setUserShare(userWithRoleMember.sort((a, b) => a.username.localeCompare(b.username)));
        };


        const GetListTeam = () => {
            GetManageTeam({
                page: 1, page_size: 5000
            }).then((res) => {
                var listTeam = res.data.results.filter((team) => team.is_active === true);

                setTeam(listTeam.sort((a, b) => a.name.localeCompare(b.name)));
            });
        };

        useEffect(() => {
            if (open) {
                GetListTeam();
                GetListUser();
                const listActive = typeDocument.filter(item => item.is_active === true)
                const filters = listActive.reduce((acc, item) => {
                    if (!acc.find((filter) => filter.name_rule === item.name_rule)) {
                        acc.push(item);
                    }
                    return acc;
                }, []);
                filters.sort((a, b) => {
                    return a.name_rule.localeCompare(b.name_rule)
                });
                setRuleTypeDocument(filters)
            }
        }, [open]);

        const onIdName = (value) => {
            idName = value
        }

        const handleIdNameDoc = (value) => {
            onIdName(value)
        }

        const addNewFile = (values) => {
            setLoadingAddNew(true);
            var FormData = require("form-data");
            var data = new FormData();
            data.append("customer", values.customer);
            data.append("rule_type_document", values.rule_type_document);

            data.append("type_document", values.type_document || undefined);
            // data.append("user_create", values.user_create);
            data.append("project", values.project !== undefined ? values.project.trim() : "");
            data.append("user_update", values.user_update || undefined);
            data.append("code_number", values.code_number !== undefined ? values.code_number.trim() : "");
            data.append("tag_team", values.tag_team || "");
            data.append("auto_extend", values.auto_extend || undefined);
            data.append("legal_entity_signed", values.legal_entity_signed);
            // data.append("infinite", values.infinite);
            data.append(
                "extension_time",
                values.extension_time === undefined || values.auto_extend === false
                    ? ""
                    : values.extension_time
            );
            data.append(
                "type_extension_time",
                values.auto_extend === false ? "" : values.type_extension_time
            );
            data.append("day_sign", moment(values.day_sign).format("YYYY-MM-DD"));
            data.append(
                "day_expired",
                values.day_expired === undefined || values.day_expired === null
                    ? null
                    : moment(values.day_expired).format("YYYY-MM-DD")
            );
            data.append("content", values.content || "");
            if (
                values.file_document.fileList != undefined &&
                values.file_document.fileList.length != 0
            ) {
                values.file_document.fileList.forEach((element) => {
                    data.append("file_document", element.originFileObj);
                });
            }

            PostManageUpload(data)
                .then((res) => {
                    setLoadingAddNew(true);
                    openNotificationWithIcon(
                        "success",
                        "Thành công",
                        `Thêm mới thành công`
                    );
                    const action = addContract;
                    dispatch(action);
                    fetchData({ page: 1, page_size: pager.pageSize, search });
                    onCloseModal();
                    setOpenInputSearch(false)
                })
                .catch((error) => {
                    if (error) {
                        setLoadingAddNew(false);
                        openNotificationWithIcon("error", "Thất bại", error.data.error);
                    }
                });
        };

        const FirstStep = () => {
            const [checkBoxTN, setCheckBox] = useState(false);
            const [dateStart, setDateStart] = useState("");
            const [checkActiveDayExpire, setCheckActiveDayExpire] = useState(true)

            form.setFieldsValue({ type_extension_time: time[0] });
            form.setFieldsValue({ extension_time: 1 });

            const formatDate = (current) => {
                return moment(current).format(dateFormat);
            };

            const disabledDate = (current) => {
                // Can not select days before today and today
                return current && current < form.getFieldValue("day_sign").endOf("day");
            };

            const disabled = () => {
                return dateStart === undefined || dateStart === "Invalid date" || dateStart === ""
                    ? true
                    : false;
            };

            const handleStartDay = (e) => {
                if (e === null) {
                    setCheckActiveDayExpire(true)
                } else {
                    setCheckActiveDayExpire(false)
                }
                form.resetFields(["day_expired"])
                const strDate = dayjs(e._d).format(dateFormat)
                setDateStart(strDate)

            }

            const handleCheckAutoExtend = (e) => {
                setCheckBox(e.target.checked);
                form.setFieldsValue({ auto_extend: e.target.checked });
            }

            const disabledInfinite = () => {
                return checkInfinite === true ? true : false;
            };
            const disabledAutoExtend = () => {
                return checkBoxTN === true ? true : false;
            };

            const handleFilterNameDocs = (value) => {
                form.resetFields(["type_document"])
                const newData = dataAllNameDocs.filter(item => item.fore_rule_typedoc === value).filter(item => item.is_active === true).filter(item => item.name !== "" && item.name !== undefined && item.name !== null)
                setDataAllNameDocsFilter(newData)
            }


            const chooseUserShare = (event) => {
                form.resetFields(["user_update"])
                // const filterUserShare = userShare.filter(item => item.team.length > 0 ? !event.includes(item.team) : item.team.length === 0 ? item : null)
                const filterUserShare = userShare.filter(item =>  !event.includes(item.team))
                if (event.length > 0) {
                    setDataUserShareFilter(filterUserShare)
                } else {
                    form.resetFields(["user_update"])
                    setDataUserShareFilter([])
                }
            }

            return (
                <>
                    <Row className="RowThuNhan">
                        <Col span={11}>
                            <Form.Item
                                name="customer"
                                label="Tên khách hàng"
                                rules={[{ required: true }]}
                                key="customer"
                                style={{ marginLeft: 35 }}
                            >
                                <Select
                                    placeholder="Chọn tên khách hàng"

                                    optionFilterProp="children"
                                    key="customer1"
                                    allowClear
                                    showSearch={true}
                                >
                                    {customer.map((item, index) => (
                                        <Option key={item.full_name} value={item.id}>
                                            {item.full_name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1}>
                            <button
                                className="btnAddNewCustomer"
                                // variant="outlined"
                                type="button"
                                onClick={() => setAddCustomer(true)}
                            >
                                <Tooltip title="Thêm khách hàng mới" placement="top">
                                    <PlusOutlined />
                                </Tooltip>
                            </button>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginLeft: "16.7%" }}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="rule_type_document"
                                            label="Tài liệu"
                                            rules={[{ required: true, message: "Không được để trống" }]}
                                            key="rule_type_document"
                                            style={{ width: "100%" }}
                                        >
                                            <Select
                                                placeholder="Chọn loại tài liệu"
                                                style={{
                                                    width: "95%"
                                                }}
                                                key="rule_type_document2"
                                                allowClear
                                                showSearch={true}
                                                optionFilterProp="children"
                                                onChange={handleFilterNameDocs}
                                            >
                                                {/* {typeDocsAll.map((item, index) => (
                                                    <Option key={index} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                ))} */}
                                                {ruleTypeDocument.map((item, index) => (
                                                    <Option key={index} value={item.fore_rule_typedoc}>
                                                        {item.name_rule}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ width: "400px" }}>
                                        <Form.Item
                                            name="type_document"
                                            key="type_document"
                                            style={{ width: "100%" }}
                                        >
                                            <Select
                                                placeholder="Chọn tên tài liệu"
                                                style={{
                                                    width: "100%",
                                                }}
                                                // key="type_document2"
                                                allowClear
                                                // className="type-document"
                                                key="name_type_document"
                                                showSearch={true}
                                                onChange={(value) => handleIdNameDoc(value)}
                                                optionFilterProp="children"
                                            >
                                                {dataAllNameDocsFilter.map((item, index) => (
                                                    <Option key={index} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </div>

                            </Row>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="tag_team" label="Nhóm Team" key="tag_team" rules={[{ required: true }]}>
                                <Select
                                    placeholder="Chọn Team"
                                    showSearch={false}
                                    style={{
                                        width: "100%",
                                    }}
                                    allowClear
                                    mode="multiple"
                                    key="team2"
                                    maxTagCount="responsive"
                                    onChange={chooseUserShare}
                                >
                                    {team.map((item, index) => (
                                        <Option key={item.name} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="project"
                                label="Tên dự án"
                                key="project"
                            >
                                <Input placeholder="Nhập tên dự án" maxLength={100} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="user_update"
                                label="Người được chia sẻ"
                                // rules={[{ required: true }]}
                                key="user_update"
                            >
                                <Select
                                    placeholder="Chọn người được chia sẻ"
                                    showSearch={false}
                                    optionFilterProp="children"
                                    mode="multiple"
                                    key="user_share2"
                                    maxTagCount="responsive"
                                >
                                    {dataUserShareFilter.map((item, index) => (
                                        <Option key={index} value={item.id}>
                                            {item.username}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="code_number"
                                label="Số hiệu"
                                key="code_number"
                            >
                                <Input placeholder="Nhập số hiệu" maxLength={100} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="day_sign"
                                label="Ngày kí kết"
                                rules={[{ required: true }]}
                                key="day_sign"
                            >
                                <DatePicker
                                    onChange={handleStartDay}
                                    style={{ width: "100%" }}
                                    format={formatDate}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="day_expired"
                                label="Ngày hết hạn"
                                // rules={[{ required: true }]}
                                key="day_expired"
                            >
                                <DatePicker
                                    disabledDate={disabledDate}
                                    disabled={checkActiveDayExpire}
                                    style={{ width: "100%" }}
                                    format={formatDate}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Pháp nhân ký"
                                name="legal_entity_signed"
                                key="legal_entity_signed"
                            >
                                <Select
                                    placeholder="Chọn mã pháp nhân"
                                    optionFilterProp="children"
                                    // key="customer1"
                                    allowClear
                                    showSearch={true}
                                    mode="multiple"
                                >
                                    {legalEntity.map((item, index) => (
                                        <Option key={index} value={item.id}>
                                            {item.code_signer}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Tự động tái tục"
                                name="auto_extend"
                                valuePropName={"checked"}
                                key="auto_extend"
                            >
                                <Checkbox
                                    disabled={disabledInfinite()}
                                    onChange={(e) => handleCheckAutoExtend(e)}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="content" label="Nội dung" key="content" rules={[{ required: true }]}>
                                <Input.TextArea placeholder="Nhập nội dung"></Input.TextArea>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            {checkBoxTN === true ? (
                                <Row style={{ width: "100%" }}>
                                    <Col span={8}></Col>
                                    <Col span={5}>
                                        <Form.Item
                                            name="extension_time"
                                            key="extension_time"
                                            rules={[
                                                {
                                                    required: true,
                                                    type: "number",
                                                    max: 99,
                                                    message: "Tối đa 2 chữ số",
                                                },
                                            ]}
                                        >
                                            <InputNumber min={1} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11} style={{ width: "100%" }}>
                                        <Form.Item
                                            name="type_extension_time"
                                            key="type_extension_time"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Không được để trống",
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Chọn mốc thời gian"
                                                showSearch={false}
                                                style={{
                                                    width: 218,
                                                }}
                                                key="time2"
                                            >
                                                {time.map((item, index) => (
                                                    <Option key={item.toString()} value={item}>
                                                        {item}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                ""
                            )}
                        </Col>

                        <Col span={24} className="btnModal">
                            <Form.Item {...tailLayout} key="1">
                                <Button
                                    variant="contained"
                                    type="submit"
                                    onClick={() => {
                                        form.getFieldValue("auto_extend") === true
                                            ? form.getFieldValue("day_expired") >
                                                moment(new Date())
                                                ? form.getFieldValue("day_expired") >
                                                    form.getFieldValue("day_sign")
                                                    ? gotoStep(current + 1)
                                                    : openNotificationWithIcon(
                                                        "error",
                                                        "Thất bại",
                                                        "Ngày hết hạn phải lớn hơn ngày kí kết"
                                                    )
                                                : openNotificationWithIcon(
                                                    "error",
                                                    "Thất bại",
                                                    "Ngày hết hạn phải lớn hơn ngày hiện tại"
                                                )
                                            : form.getFieldValue("day_expired") === undefined ||
                                                form.getFieldValue("day_expired") === null ||
                                                form.getFieldValue("day_expired") >
                                                form.getFieldValue("day_sign")
                                                ? gotoStep(current + 1)
                                                : openNotificationWithIcon(
                                                    "error",
                                                    "Thất bại",
                                                    "Ngày hết hạn phải lớn hơn ngày kí kết"
                                                );
                                    }}
                                >
                                    Tiếp theo
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            );
        };

        const SecondStep = () => {
            const handleChange = (info) => {
                let newFileList = [...info.fileList];
                setFileList(newFileList);
            };
            return (
                <>
                    {screen.width > 768 ? (
                        <Row>
                            <Col span={18}>
                                <p
                                    style={{
                                        marginLeft: "33%",
                                        color: "#FF463F",
                                        fontStyle: "italic",
                                        marginBottom: 0,
                                    }}
                                    className="ant-upload-text"
                                >
                                    Tổng dung lượng File tối đa 50MB !
                                </p>
                                <Form.Item
                                    name="file_document"
                                    label={<FolderIcon />}
                                    rules={[
                                        {
                                            required: true,
                                            message: "File upload không được để trống!",
                                        },
                                    ]}
                                    key="file_document"
                                >
                                    <Dragger
                                        accept="image/png, image/jpeg, .pdf, .xlsx,.docx"
                                        name="file"
                                        openFileDialogOnClick={true}
                                        defaultFileList={fileList}
                                        rules={[{ required: true }]}
                                        // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
                                        fileList={fileList}
                                        // maxCount={1}
                                        onChange={(info) => handleChange(info)}
                                        showUploadList={true}
                                        multiple={true}
                                        beforeUpload={(file, FileList) => {
                                            const isJpgOrPng =
                                                file.type !== "application/pdf" &&
                                                file.type !== "image/png" &&
                                                file.type !== "image/jpeg" &&
                                                file.type !==
                                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                                                file.type !==
                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                                            const checkSize = file.size / 1024 / 1024 > 50;

                                            if (isJpgOrPng) {
                                                openNotificationWithIcon(
                                                    "error",
                                                    "Lỗi",
                                                    `Sai định dạng file !`
                                                );
                                                return Upload.LIST_IGNORE;
                                            }
                                            if (checkSize) {
                                                openNotificationWithIcon("error", "Lỗi", `Quá 50MB !`);
                                            }

                                            return !isJpgOrPng && !checkSize
                                                ? false
                                                : Upload.LIST_IGNORE;
                                        }}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">
                                            Click hoặc kéo thả để chọn file
                                        </p>
                                        <p className="ant-upload-hint">
                                            Chọn file trên thiết bị của bạn (
                                            <span style={{ color: "#FF463F", fontStyle: "italic" }}>
                                                Hỗ trợ định dạng Hình ảnh *.png, pdf, xlsx, docx
                                            </span>
                                            )
                                        </p>
                                    </Dragger>
                                </Form.Item>
                            </Col>

                            <Col span={24} className="btnModal">
                                <Form.Item {...tailLayout} key="3">
                                    <LoadingButton
                                        style={{ marginRight: 10 }}
                                        variant="contained"
                                        color="success"
                                        loading={loadingAddNew}
                                        type="submit"
                                        key={"13"}
                                        onClick={(values) => {
                                            form.validateFields().then((r) => {
                                                submit()
                                                    .then((result) => { })
                                                    .catch((err) => {
                                                        var size = 0;
                                                        r.file_document.fileList.map((item) => {
                                                            size += item.size;
                                                        });
                                                        const listSize = size / 1024 / 1024 > 50;
                                                        if (listSize) {
                                                            openNotificationWithIcon(
                                                                "error",
                                                                "Thất bại",
                                                                err
                                                            );
                                                        }
                                                    });
                                            });
                                        }}
                                    >
                                        Đăng ký
                                    </LoadingButton>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            gotoStep(current - 1);
                                        }}
                                        disabled={loadingAddNew}
                                    >
                                        Quay lại
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <Col span={24}>
                                <p
                                    style={{
                                        marginLeft: "14%",
                                        color: "#FF463F",
                                        fontStyle: "italic",
                                        marginBottom: 0,
                                    }}
                                    className="ant-upload-text"
                                >
                                    Tổng dung lượng File tối đa 50MB !
                                </p>
                                <Form.Item
                                    name="file_document"
                                    // label={<FolderIcon />}
                                    rules={[
                                        {
                                            required: true,
                                            message: "File upload không được để trống!",
                                        },
                                    ]}
                                    style={{ marginBottom: 50 }}
                                    key="file_document"
                                >
                                    <Dragger
                                        accept="image/png, image/jpeg, .pdf, .xlsx,.docx"
                                        name="file"
                                        openFileDialogOnClick={true}
                                        defaultFileList={fileList}
                                        rules={[{ required: true }]}
                                        // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
                                        fileList={fileList}
                                        // maxCount={1}
                                        onChange={(info) => handleChange(info)}
                                        showUploadList={true}
                                        multiple={true}
                                        beforeUpload={(file, FileList) => {
                                            const isJpgOrPng =
                                                file.type !== "application/pdf" &&
                                                file.type !== "image/png" &&
                                                file.type !== "image/jpeg" &&
                                                file.type !==
                                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                                                file.type !==
                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                                            const checkSize = file.size / 1024 / 1024 > 50;

                                            if (isJpgOrPng) {
                                                openNotificationWithIcon(
                                                    "error",
                                                    "Lỗi",
                                                    `Sai định dạng file !`
                                                );
                                                return Upload.LIST_IGNORE;
                                            }
                                            if (checkSize) {
                                                openNotificationWithIcon("error", "Lỗi", `Quá 50MB !`);
                                            }

                                            return !isJpgOrPng && !checkSize
                                                ? false
                                                : Upload.LIST_IGNORE;
                                        }}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">
                                            Click hoặc kéo thả để chọn file
                                        </p>
                                        <p className="ant-upload-hint">
                                            Chọn file trên thiết bị của bạn (
                                            <span style={{ color: "#FF463F", fontStyle: "italic" }}>
                                                Hỗ trợ định dạng Hình ảnh *.png, pdf, xlsx, docx
                                            </span>
                                            )
                                        </p>
                                    </Dragger>
                                </Form.Item>
                            </Col>

                            <Col span={24} className="btnModal">
                                <Form.Item {...tailLayout} key="3">
                                    <LoadingButton
                                        style={{ marginRight: 10 }}
                                        variant="contained"
                                        color="success"
                                        loading={loadingAddNew}
                                        type="submit"
                                        onClick={(values) => {
                                            form.validateFields().then((r) => {
                                                submit()
                                                    .then((result) => { })
                                                    .catch((err) => {
                                                        var size = 0;
                                                        r.file_document.fileList.map((item) => {
                                                            size += item.size;
                                                        });
                                                        const listSize = size / 1024 / 1024 > 50;
                                                        if (listSize) {
                                                            openNotificationWithIcon(
                                                                "error",
                                                                "Thất bại",
                                                                err
                                                            );
                                                        }
                                                    });
                                            });
                                        }}
                                    >
                                        Đăng ký
                                    </LoadingButton>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            gotoStep(current - 1);
                                        }}
                                        disabled={loadingAddNew}
                                    >
                                        Quay lại
                                    </Button>
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </>
            );
        };

        const formList = [<FirstStep />, <SecondStep />];
        return (
            <div>
                <Steps current={current}>
                    <Step title="Khai báo thông tin" />
                    <Step title="Upload File" />
                </Steps>

                <div style={{ marginTop: 30 }}>
                    <Form
                        {...layout}
                        {...formProps}
                        style={{ width: "100%" }}
                        validateMessages={validateMessages}
                    >
                        {formList[current]}
                    </Form>

                    {current === 2 && (
                        <Result
                            status="success"
                            title="Submit is succeed!"
                            extra={
                                <>
                                    <Button
                                        onClick={() => {
                                            form.resetFields();
                                        }}
                                    >
                                        Tạo đăng ký mới
                                    </Button>
                                </>
                            }
                        />
                    )}
                </div>
            </div>
        );
    };

    const onCloseModalCustomer = () => {
        setAddCustomer(false);
    };

    const fileDownloadTemplate_Contract = () => {
        // setLoading_page(true);
        const fnFileDownload = require("js-file-download");
        const filename =
            "FileMauHoSo_" + moment(new Date()).format("DDMMYYYY") + ".xlsx";
        TemplateContract({ responseType: "blob" })
            .then((res) => {
                fnFileDownload(res.data, filename);
                // setLoading_page(false);
            })
            .catch((err) => {
                openNotificationWithIcon(
                    "error",
                    "Không tải được danh sách hồ sơ. Bạn vui lòng thử lại."
                );
                // setLoading_page(false);
            });
    };

    return (
        <Modal
            // title={
            //   <Row>
            //     <Col span={15}>
            //       <span>Thêm chứng từ</span>
            //     </Col>
            //     <Col span={8}>
            //       <a
            //         // href={localhost + "/media/template/Template_contract.xlsx"}
            //         // href={"/media/template/Template_contract.xlsx"}
            //         style={{ float: "right" }}
            //         onClick={fileDownloadTemplate_Contract}
            //       >
            //         <DownloadOutlined style={{ marginRight: 5, fontSize: 14 }} />
            //         <span style={{ fontSize: 14 }}>File mẫu</span>
            //       </a>
            //     </Col>
            //   </Row>
            // }
            title="Thêm chứng từ"
            visible={open}
            onCancel={onCloseModal}
            className="modalAddUser"
            width={screen.width > 768 ? 1000 : "80%"}
            footer={false}
            bodyStyle={
                screen.width > 768
                    ? null
                    : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
            }
        >
            <div>
                <ModalAddCustomer
                    open={addCustomer}
                    onCancel={onCloseModalCustomer}
                ></ModalAddCustomer>
                <BoxNewSchedule_pro />
            </div>
        </Modal>
    );
};

export default ModalUploadContract
import { LoadingButton } from '@mui/lab';
import { Col, Form, Modal, Row, Select, Input, Upload, Button } from 'antd'
import React from 'react'
import { useState } from 'react';
import { PostForm } from '../../../../api/manageForm';
import { UploadOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from '../../../Function';

const { Option } = Select;

const validateMessages = {
    required: "${label} không được để trống!",
    types: {
        email: "${label} is not in the correct email format!",
        number: "${label} not numbers!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};


function ModalAddForm({ onCancelModalAdd, isOpenModalAddForm, ruleTypeDocs, fetchFormData, pager, search }) {
    const [form] = Form.useForm();
    const [loadingAddNew, setLoadingAddNew] = useState(false)

    const onCloseModal = () => {
        onCancelModalAdd()
        form.resetFields()

    }

    const onFinish = (values) => {
        var FormData = require("form-data");
        var data = new FormData();
        setLoadingAddNew(true)
        data.append("id_rule", values.id_rule)
        data.append("name_form", values.name_form.trim().replace(/\s+/g, ' '))
        data.append("file_form", values.file_form.fileList[0].originFileObj)
        // data.append("id_rule", values.id_rule)
        PostForm(data).then((res) => {
            form.resetFields()
            setLoadingAddNew(false)
            fetchFormData({ page: pager.current, page_size: pager.pageSize, search });
            openNotificationWithIcon("success", res.data.result)
            onCancelModalAdd()
        }).catch((err) => {
            setLoadingAddNew(false)
            openNotificationWithIcon("error", err.data.error)
        })
    }
    return (
        <>
            <Modal
                title="Thêm biểu mẫu"
                visible={isOpenModalAddForm}
                onCancel={onCloseModal}
                className="modalAddUser"
                width={screen.width > 768 ? 800 : "80%"}
                footer={false}
                bodyStyle={
                    screen.width > 768
                        ? null
                        : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
                }
            >
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    validateMessages={validateMessages}
                    initialValues={{
                        remember: true,
                    }}
                    key="form_docs"
                >
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                name="name_form"
                                label="Tên biểu mẫu"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: new RegExp(
                                            "^[a-zA-Z0-9À-ỹỲỳỴỵỶỷỸỹ_ \\-!@#$%^}&\\\\*=+{[\\|(`~),./?\\]]+$"
                                        ),
                                        message:
                                            "Không được nhập các kí tự đặc biệt như: \" : < > ; ' ",
                                    },
                                ]}
                                key="name_form"
                            >
                                <Input
                                    placeholder="Nhập tên biểu mẫu"
                                // onBlur={(e) => {
                                //     formAddCustomer.setFieldsValue({
                                //         name_organization: e.target.value.trim(),
                                //     });
                                // }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={2}>
                            <Form.Item
                                name="id_rule"
                                label="Loại tài liệu"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="id_rule"
                            >
                                <Select
                                    placeholder="Chọn loại tài liệu"
                                    showSearch={false}
                                    optionFilterProp="children"
                                    // mode="multiple"
                                    maxTagCount="responsive"
                                    // open={false}
                                    key="id_rule"
                                >
                                    {ruleTypeDocs.map((item, index) => (
                                        <Option key={index} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="file_form"
                                // label={<span className="title-field-bill-check">File biểu mẫu</span>}
                                label="File biểu mẫu"
                                key="file_form"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Upload maxCount={1} beforeUpload={(file, FileList) => {
                                    const checkSize = file.size / 1024 / 1024 > 50;
                                    if (checkSize) {
                                        openNotificationWithIcon("error", "Lỗi", `Đã quá 5MB !`);
                                    }

                                    return !checkSize
                                        ? false
                                        : Upload.LIST_IGNORE;
                                }}>
                                    <Button htmlType="button" icon={<UploadOutlined />}>
                                        Click to Upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        style={{ marginTop: "20px", textAlign: "center" }}
                        key="btn_docs"
                    >
                        <LoadingButton
                            type="submit"
                            className={"m-2"}
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            loading={loadingAddNew}
                        >
                            Đăng ký
                        </LoadingButton>
                        <LoadingButton
                            onClick={onCloseModal}
                            color='error'
                            className={"m-2"}
                            variant="contained"
                            disabled={loadingAddNew}
                        >
                            Hủy
                        </LoadingButton>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default ModalAddForm
import React, { useEffect, useState } from "react";
import {
  Input,
  Select,
  Tabs,
} from "antd";
import "./qlyNguoiDung.css";
import TableUser from "./user/TableUser";
import TableTeam from "./team/TableTeam";

const TableOfPage = () => {
  return (
    <Tabs defaultActiveKey="1" key="def">
      <Tabs.TabPane tab="Danh sách User" key="1">
        <TableUser />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Danh sách Team" key="3">
        <TableTeam />
      </Tabs.TabPane>
    </Tabs>
  );
};

function QlyNguoiDung() {
  return <TableOfPage key="22" />;
}

export default QlyNguoiDung;

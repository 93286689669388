import { authAxios } from "../api/axiosClient";
import { deleteFileDocument, downloadFile, exportDocument, getManageUploadAll, importContract, legalEntityAll, logContract, manageDetailUpload, manageUploadAll, manageUploadURL, templateContract } from "../constants";

export const DeleteUploadApi = (params = {}) => {
    const url = manageUploadURL;
    return authAxios().delete(url, { data: params });
};

export const GetInforUpload = (id, params = {}) => {
    const url = manageDetailUpload;
    return authAxios().get(url(id), params);
};

export const DownloadFile = (id, params = {}) => {
    const url = downloadFile;
    return authAxios().get(url(id), params);
};

export const ExportDocument = (params = {}) => {
    const url = exportDocument;
    return authAxios().post(url, params, { responseType: "blob" });
};

export const ImportContract = (params = {}) => {
    const url = importContract;
    return authAxios().post(url, params);
};

export const TemplateContract = (params = {}) => {
    const url = templateContract;
    return authAxios().get(url, params);
};

export const LogContract = (params = {}) => {
    const url = logContract;
    return authAxios().get(url, { params });
};

export const GetManageUploadAll = (params = {}) => {
    // const url = manageUploadAll;
    const url = getManageUploadAll;
    return authAxios().get(url, { params });
};

export const GetLegalEntityAll = (params = {}) => {
    const url = legalEntityAll;
    return authAxios().get(url, params);
};

export const DeleteUploadMulti = (params = {}) => {
    const url = manageUploadURL;
    return authAxios().delete(url, params);
};

export const DeleteFileDocument = (params = {}) => {
    const url = deleteFileDocument;
    return authAxios().delete(url, { data: params });
};

export const PostManageUpload = (params = {}) => {
    const url = manageUploadURL;
    return authAxios().post(url, params);
};

export const PutUploadFile = (id, params = {}) => {
    const url = manageDetailUpload;
    return authAxios().put(url(id), params);
};
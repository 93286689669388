import React, { useEffect, useState } from "react";
import {
    Modal,
    Form,
    Input,
    Row,
    Col,
    Select,
} from "antd";
import { openNotificationWithIcon} from "../../../Function";
import {
    PutDepartment,
    GetDetailDepartment,
} from "../../../../api/manageTeam";
import {Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import "../qlyNguoiDung.css";

const { Option } = Select;

const validateMessages = {
    required: "Không được để trống ô ${label} !",
    types: {
        email: "${label} đang sai định dạng!",
        number: "${label} not numbers!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};


const ModalEditDepartment = ({
    boPhan,
    setIsEditing,
    setIsEditDepartment,
    isEditDepartment,
    fetchData,
    pager,
    getListBoPhan,
    setLoading
}) => {
    const [form] = Form.useForm();
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [detailDepartment, setDetailDepartment] = useState();
    const [idDepartment, setIdDepartment] = useState(0);
    const getDetailDepartment = (record) => {
        setIdDepartment(record);
        GetDetailDepartment(record)
            .then((res) => {
                setDetailDepartment(res.data);
                setIsEditing(false);
            })
            .catch((err) => { });
    };

    useEffect(() => {
        form.resetFields();
    }, [isEditDepartment]);

    const onCloseModal = () => {
        setIsEditDepartment(false);
    };

    const onFinish = (values) => {
        let checkNameBP = false;
        setLoadingEdit(true)
        for (let i = 0; i < boPhan.length; i++) {
            if (values.bo_phan_moi !== boPhan[i].name) {
                checkNameBP = true;
            }
            if (values.bo_phan_moi === boPhan[i].name) {
                checkNameBP = false;
                break;
            }
        }
        if (checkNameBP === true) {
            const department = {
                name: values.bo_phan_moi,
            };
            PutDepartment(idDepartment, department)
                .then((res) => {
                    if (res.data.error) {
                        setLoadingEdit(false)
                        openNotificationWithIcon("error", res.data.error);
                    } else {
                        fetchData({ page: pager.current, page_size: pager.pageSize });
                        openNotificationWithIcon(
                            "success",
                            "Thành công",
                            "Chỉnh sửa Team thành công"
                        );
                        setLoadingEdit(false)
                        setIsEditDepartment(false);
                        getListBoPhan();
                        form.resetFields();
                    }
                })
                .catch((err) => {
                    setLoadingEdit(false)
                    if (err.data.error) {
                        openNotificationWithIcon("error", err.data.error);
                    }
                });
        }
        if (checkNameBP === false) {
            setLoadingEdit(false)
            openNotificationWithIcon("error", "Bộ phận đã tồn tại");
        }

    };
    return (
        <>
            <Modal
                title="Chỉnh sửa bộ phận"
                visible={isEditDepartment}
                onCancel={onCloseModal}
                className="modalAddUser"
                width={screen.width > 768 ? 800 : "80%"}
                footer={false}
                bodyStyle={
                    screen.width > 768
                        ? null
                        : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
                }
            >
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    // loading={loading}
                    initialValues={{
                        remember: true,
                    }}
                    key="form_team"
                >
                    {screen.width > 768 ? (
                        <Row>
                            <Col span={11}>
                                <Form.Item name="bo_phan" label="Bộ phận cũ" key="bo_phan"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}>
                                    <Select
                                        placeholder="Chọn Bộ phận"
                                        showSearch={false}
                                        optionFilterProp="children"
                                        maxTagCount="responsive"
                                        allowClear
                                        style={{
                                            width: "100%",
                                        }}
                                        key="bo_phan"
                                    >
                                        {boPhan.map((item, index) => (
                                            <Option key={item.name} value={item.id}>
                                                <button
                                                    style={{
                                                        width: "100%",
                                                        background: "none",
                                                        border: "1px solid rgb(255,255,255)",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => getDetailDepartment(item.id)}
                                                >
                                                    <p style={{ float: "left" }}>{item.name}</p>
                                                </button>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11} offset={2}>
                                <Form.Item
                                    name="bo_phan_moi"
                                    label="Bộ phận mới"
                                    key="name_team"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Nhập tên bộ phận"
                                        maxLength={100}
                                    ></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="name"
                                    label="Tên team"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            pattern: new RegExp(
                                                "^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]*$"
                                            ),
                                            message:
                                                "Không được nhập khoảng cách hoặc tiếng Việt có dấu",
                                        },
                                    ]}
                                    key="name_team"
                                >
                                    <Input placeholder="Nhập tên team" maxLength={100}></Input>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="bo_phan" label="Bộ phận" key="bo_phan">
                                    <Select
                                        placeholder="Chọn Bộ phận"
                                        showSearch={false}
                                        optionFilterProp="children"
                                        maxTagCount="responsive"
                                        allowClear
                                        style={{
                                            width: "100%",
                                        }}
                                        key="bo_phan"
                                    >
                                        {boPhan.map((item, index) => (
                                            <Option key={item.name} value={item.id}>
                                                {item.username}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    <Form.Item
                        style={{ marginTop: "20px", textAlign: "center" }}
                        key="btn_team"
                    >
                        <LoadingButton
                            type="submit"
                            className={"m-2"}
                            style={{ marginRight: "20px" }}
                            variant="contained"
                            loading={loadingEdit}
                        >
                            Chỉnh sửa
                        </LoadingButton>
                        <Button
                            onClick={onCloseModal}
                            color="error"
                            variant="contained"
                        // disabled={loadingAddNew}
                        >
                            Hủy
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ModalEditDepartment
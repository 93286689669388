import React, { useState, useEffect } from "react";
import "./thongKe.css";
import { Button, Card, Col, DatePicker, Row, Table, Tooltip } from "antd";
import Chart from "react-apexcharts";
import {
  ContractExpire,
  ContractYear,
  ExportContractYearApi,
} from "../../../../api/usersApi";
import moment from "moment";
import { openNotificationWithIcon } from "../../../Function";
import { Carousel } from "antd";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import ModalDetailContractExpire from "./ModalDetailContractExpire";
import { GetManageCustomer } from "../../../../api/manageCustomer";
import { DownloadOutlined } from "@ant-design/icons";

const dateFormat = "DD-MM-YYYY";

const ModalViewContractExpire = ({ open, contractExpire, onCancel }) => {
  const [pageSize, setPageSize] = useState(10);
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false)
  const [dataDetail, setDataDetail] = useState([])
  const [pager, setPager] = useState({
    pageSize: pageSize,
    count: 0,
    current: 1,
  });

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
      width: "5%",
    },

    {
      title: "Tên khách hàng",
      key: "customer",
      align: "center",
      width: "15%",
      dataIndex: "customer",
      ellipsis: true,
      render: (text, record, index) => record.customer[0].full_name,
    },

    {
      title: "Loại tài liệu",
      key: "rule_type_document",
      align: "center",
      dataIndex: "rule_type_document",
      ellipsis: true,
      width: "15%",
      render: (text, record, index) => record.rule_type_document.length > 0 ? record.rule_type_document[0].name : null,
    },

    {
      title: "Tên tài liệu",
      key: "type_document",
      align: "center",
      width: "15%",
      dataIndex: "type_document",
      ellipsis: true,
      render: (text, record, index) => record.type_document.length > 0 ? record.type_document[0].name : null,
    },

    {
      title: "Nội dung chính",
      key: "content",
      align: "center",
      dataIndex: "content",
      ellipsis: true,
      width: "13%",
      render: (text, record, index) => record.content,
    },

    {
      title: "Ngày kí kết",
      key: "day_sign",
      align: "center",
      dataIndex: "day_sign",
      ellipsis: true,
      width: "8%",

      render: (text, record, index) =>
        moment(record.day_sign).format(dateFormat),
    },

    {
      title: "Ngày hết hạn",
      key: "day_expired",
      align: "center",
      dataIndex: "day_expired",
      ellipsis: true,
      width: "8%",
      render: (text, record, index) =>
        record.day_expired ? moment(record.day_expired).format(dateFormat) : "",
    },

    {
      title: "Người tạo",
      key: "user_create",
      align: "center",
      dataIndex: "user_create",
      ellipsis: true,
      width: "8%",
      render: (text, record, index) => record.user_create[0].username,
    },
  ];

  const closeModal = () => {
    setIsOpenModalDetail(false)
  }

  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = pagination.pageSize;
    setPager({ ...currentPager });
  };

  const onLogContract = (record) => {
    setIsOpenModalDetail(true)
    setDataDetail(record)
  };

  return (<>
    {/* <Modal title={<p style={{textTransform: "uppercase", fontWeight: 750, color: "#0E375C"}}>Danh sách hồ sơ sắp hết hạn</p>}
      visible={open}
      onCancel={onCancel}
      footer={false}
      width="80%"
    > */}
    <Table
      columns={columns}
      dataSource={contractExpire}
      onChange={handleChange}
      className="table-statistic"
      rowClassName={"styleTableLeTet1"}
      locale={{ emptyText: "Không có dữ liệu" }}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => { onLogContract(record) }
        };
      }}
      // style={{position: "absolute"}}
      pagination={{
        current: pager.current,
        pageSize: pageSize,
        // showSizeChanger: true,
        // showQuickJumper: true,
        // pageSizeOptions: ["10", "15", "25", "50"],
        // locale: {
        //   jump_to: "Đến trang: ",
        //   page: "",
        // },
      }}
      size="small"
    />
    {isOpenModalDetail === true ? <ModalDetailContractExpire open={isOpenModalDetail} dataDetail={dataDetail} onCancel={closeModal} /> : null}

  </>)
}


function ThongKe() {
  const [contractExpire, setContractExpire] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  const [pager, setPager] = useState({
    pageSize: pageSize,
    count: 0,
    current: 1,
  });
  const [newData, setData] = useState([]);
  const [contractOfYear, setContractOfYear] = useState([]);
  const [openModalContractExpire, setOpenModalContractExpire] = useState(false);

  const [chooseYear, setChooseYear] = useState(moment(new Date()).year());
  const [t1, setT1] = useState(0);
  const [t2, setT2] = useState(0);
  const [t3, setT3] = useState(0);
  const [t4, setT4] = useState(0);
  const [t5, setT5] = useState(0);
  const [t6, setT6] = useState(0);
  const [t7, setT7] = useState(0);
  const [t8, setT8] = useState(0);
  const [t9, setT9] = useState(0);
  const [t10, setT10] = useState(0);
  const [t11, setT11] = useState(0);
  const [t12, setT12] = useState(0);

  const fetchData = () => {
    GetManageCustomer({
      page: 1, page_size: 5000
    })
      .then((res) => {
        var listCustomer = res.data.results.filter((cus) => cus.is_active === true);
        setData(listCustomer);
      })
      .catch((err) => {
        openNotificationWithIcon("error", "Thất bại");
      });
  };

  const totalContractExpire = (params = {}) => {
    ContractExpire({
      page: 1, page_size: 5000
    })
      .then((res) => {
        var listContractExpire = res.data.results.filter(
          (contr) => contr.is_active === true
        );

        setContractExpire(listContractExpire);
      })
      .catch((err) => {
        openNotificationWithIcon("error", "Thất bại");
      });
  };

  const totalContractOfYear = (params = {}) => {
    ContractYear(chooseYear)
      .then((res) => {
        var listContractOfYear = res.data.filter(
          (contract) => contract.is_active === true
        );
        setContractOfYear(listContractOfYear);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    fetchData();
    totalContractExpire();
  }, []);

  useEffect(() => {
    totalContractOfYear();
  }, [chooseYear]);

  useEffect(() => {
    let th1 = contractOfYear.filter(
      (item) =>
        moment(item.day_sign).month() + 1 === 1 &&
        moment(item.day_sign).year() === chooseYear
    );

    setT1(th1.length);

    let th2 = contractOfYear.filter(
      (item) =>
        moment(item.day_sign).month() + 1 === 2 &&
        moment(item.day_sign).year() === chooseYear
    );
    setT2(th2.length);

    let th3 = contractOfYear.filter(
      (item) =>
        moment(item.day_sign).month() + 1 === 3 &&
        moment(item.day_sign).year() === chooseYear
    );
    setT3(th3.length);

    let th4 = contractOfYear.filter(
      (item) =>
        moment(item.day_sign).month() + 1 === 4 &&
        moment(item.day_sign).year() === chooseYear
    );
    setT4(th4.length);

    let th5 = contractOfYear.filter(
      (item) =>
        moment(item.day_sign).month() + 1 === 5 &&
        moment(item.day_sign).year() === chooseYear
    );
    setT5(th5.length);

    let th6 = contractOfYear.filter(
      (item) =>
        moment(item.day_sign).month() + 1 === 6 &&
        moment(item.day_sign).year() === chooseYear
    );
    setT6(th6.length);

    let th7 = contractOfYear.filter(
      (item) =>
        moment(item.day_sign).month() + 1 === 7 &&
        moment(item.day_sign).year() === chooseYear
    );
    setT7(th7.length);

    let th8 = contractOfYear.filter(
      (item) =>
        moment(item.day_sign).month() + 1 === 8 &&
        moment(item.day_sign).year() === chooseYear
    );
    setT8(th8.length);

    let th9 = contractOfYear.filter(
      (item) =>
        moment(item.day_sign).month() + 1 === 9 &&
        moment(item.day_sign).year() === chooseYear
    );
    setT9(th9.length);

    let th10 = contractOfYear.filter(
      (item) =>
        moment(item.day_sign).month() + 1 === 10 &&
        moment(item.day_sign).year() === chooseYear
    );
    setT10(th10.length);

    let th11 = contractOfYear.filter(
      (item) =>
        moment(item.day_sign).month() + 1 === 11 &&
        moment(item.day_sign).year() === chooseYear
    );
    setT11(th11.length);

    let th12 = contractOfYear.filter(
      (item) =>
        moment(item.day_sign).month() + 1 === 12 &&
        moment(item.day_sign).year() === chooseYear
    );
    setT12(th12.length);
  }, [contractOfYear]);

  const mobile = screen.width > 768;

  const data = {
    series: [
      {
        name: "Số lượng hồ sơ",
        data: [
          {
            x: mobile ? "Tháng 1" : "Th.1",
            y: t1,
          },
          {
            x: mobile ? "Tháng 2" : "Th.2",
            y: t2,
          },
          {
            x: mobile ? "Tháng 3" : "Th.3",
            y: t3,
          },
          {
            x: mobile ? "Tháng 4" : "Th.4",
            y: t4,
          },
          {
            x: mobile ? "Tháng 5" : "Th.5",
            y: t5,
          },
          {
            x: mobile ? "Tháng 6" : "Th.6",
            y: t6,
          },
          {
            x: mobile ? "Tháng 7" : "Th.7",
            y: t7,
          },
          {
            x: mobile ? "Tháng 8" : "Th.8",
            y: t8,
          },
          {
            x: mobile ? "Tháng 9" : "Th.9",
            y: t9,
          },
          {
            x: mobile ? "Tháng 10" : "Th.10",
            y: t10,
          },
          {
            x: mobile ? "Tháng 11" : "Th.11",
            y: t11,
          },
          {
            x: mobile ? "Tháng 12" : "Th.12",
            y: t12,
          },
        ],

      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",

        toolbar: {
          show: true,
          tools: {
            download: false, // Disable default download tool

          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      colors: ["#4290d3"],
      dataLabels: {
        enabled: false,
      },
    },
  };

  const onCloseModalContractExpire = () => {
    setOpenModalContractExpire(false)
  }
  const history = useHistory();

  const toManageCustomer = useCallback(() => history.push('/manage_customer'), [history]);

  const fileDownload_Statistic = () => {
    // setLoading_page(true);
    const fnFileDownload = require("js-file-download");
    const filename =
      "DanhSachHoSoPhatSinh_" + chooseYear + ".xlsx";
    var FormData = require("form-data");
    var data = new FormData();
    data.append("year", chooseYear);
    ExportContractYearApi(data)
      .then((res) => {
        fnFileDownload(res.data, filename);
        // setLoading_page(false);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Không tải được danh sách hồ sơ. Bạn vui lòng thử lại."
        );
        // setLoading_page(false);
      });
  };

  return (
    <>

      {screen.width > 768 ? (
        <div className="site-card-wrapper">
          <Row gutter={16}>
            <Col span={6}>
              <Card
                title="Số hồ sơ sắp hết hạn"
                headStyle={{ background: "#FF5733" }}
                bordered={false}
              >
                <Card.Grid style={{ width: "100%", cursor: "pointer" }} onClick={() => setOpenModalContractExpire(true)}>{contractExpire.length}</Card.Grid>
              </Card>
            </Col>
            <Col span={3}></Col>
            <Col span={6}>
              {/* <Tooltip
            placement="topRight"
            title={"Số hợp đồng phát sinh trong năm"}
          > */}
              <Card
                title={`Số hồ sơ phát sinh năm ${chooseYear}`}
                bordered={false}
              >
                <Card.Grid style={{ width: "100%" }}>{contractOfYear.length}</Card.Grid>
              </Card>
              {/* </Tooltip> */}
            </Col>
            <Col span={3}></Col>

            <Col span={6}>
              <Card
                title="Tổng số khách hàng"
                headStyle={{ background: "green" }}
                bordered={false}
              >
                <Card.Grid style={{ width: "100%", cursor: "pointer" }} onClick={toManageCustomer}>{newData.length}</Card.Grid>

              </Card>
            </Col>
          </Row>
          <div style={{ height: "62vh", overflow: "auto", marginTop: "2%", width: "100%" }}>
            <ModalViewContractExpire open={openModalContractExpire} contractExpire={contractExpire} onCancel={onCloseModalContractExpire} />
            <Row>
              <div style={{ margin: "auto", marginTop: "10px" }}>

                <div style={{ textAlign: "center" }}>
                  <DatePicker
                    style={{ width: "30%" }}
                    defaultValue={moment(new Date())}
                    onChange={(value) => setChooseYear(moment(value).year())}
                    picker="year"
                  />
                </div>
                <Tooltip title="Tải xuống danh sách tài liệu" id="tooltip-statistic" color={"#fff"}>
                  <DownloadOutlined onClick={fileDownload_Statistic} style={{ fontSize: 18, float: "right" }} />
                  {/* <Button onClick={fileDownload_Statistic} style={{ float: "right", borderRadius: 8, border: "unset", cursor: "pointer", background: "unset" }}><span style={{ color: "#2EB85C" }}><DownloadOutlined onClick={fileDownload_Statistic} style={{fontSize: 16,float: "right"}}/></span></Button> */}
                </Tooltip>
                <div style={{ marginTop: "5%" }}>
                  <Chart
                    options={data.options}
                    series={data.series}
                    type="bar"
                    // width="280%"
                    // height="280%"

                    width={screen.availWidth <= 1920 && screen.width >= 1440 ? "260%" : "200%"}
                    // width={screen.availWidth <= 1920 && screen.width >= 1440 ? "260%" : "210%"}
                    height={"310%"}
                  // height={screen.availWidth < 1920 && screen.width > 1440 ? "245%" : screen.availWidth === 1920 ? "310%" : "250%"}
                  />
                  <div className="titleChart" style={{ fontSize: 18 }}>
                    <strong>Biểu đồ thống kê số hồ sơ trong năm {chooseYear}</strong>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      ) : (
        <div className="spaceChart">
          <Carousel dotPosition="top" className="site-card-wrapper">
            <Col span={24}>
              <Card
                title="Số hồ sơ sắp hết hạn"
                bordered={false}
                headStyle={{ background: "#FF5733" }}
              >
                {contractExpire.length}
              </Card>
            </Col>
            <Col span={24}>
              <Card
                title={`Số hồ sơ phát sinh năm ${chooseYear}`}
                bordered={false}
              >
                {contractOfYear.length}
              </Card>
            </Col>

            <Col span={24}>
              <Card
                title="Tổng số khách hàng"
                bordered={false}
                headStyle={{ background: "#1DFF27" }}
              >
                {newData.length}
              </Card>
            </Col>
          </Carousel>

          <DatePicker
            className="datePickerChart"
            defaultValue={moment(new Date())}
            onChange={(value) => setChooseYear(moment(value).year())}
            picker="year"
          />
          <Chart
            options={data.options}
            series={data.series}
            type="bar"
            width={"100%"}
          />
          <div className="titleChart">
            <p>Biểu đồ thống kê số hồ sơ trong năm {chooseYear}</p>
          </div>
        </div>
      )}
    </>

  );
}



export default ThongKe;

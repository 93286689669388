import React, { useCallback, useEffect, useState } from "react";
import {
    Modal,
    Form,
    Row,
    Col,
    Select,
    Spin,
    Tag,
    Steps,
    Input,
    Result,
    Upload,
    DatePicker,
    Checkbox,
    Popconfirm,
    InputNumber,
} from "antd";
import { useStepsForm } from "sunflower-antd";
import "../thuNhan/CaNhan.css"
import moment from "moment";
import { openNotificationWithIcon } from "../../../Function";

import {
    DeleteOutlined,
    InboxOutlined,
} from "@ant-design/icons";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import { GetManageTeam } from "../../../../api/manageTeam";
import { GetManageUser } from "../../../../api/manageUser";
import { DeleteFileDocument, PutUploadFile } from "../../../../api/manageContract";
const { Step } = Steps;
const { Dragger } = Upload;

const validateMessages = {
    required: "Vui lòng nhập ${label} !",
    types: {
        email: "${label} sai định dạng!",
        number: "${label} phải là số!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const dateFormat = "DD-MM-YYYY";
const { Option } = Select;

const ModalEditContract = ({
    open,
    onCancel,
    dataInforUploadFile,
    pager,
    fetchData,
    loading,
    legalEntity,
    search,
    checkFilter,
    datafilter,
    userWithRoleMember
}) => {
    const validateMessages = {
        required: "${label} không được để trống!",
        types: {
            email: "${label} is not a valid email!",
            number: "${label} is not a valid number!",
        },
        number: {
            range: "${label} must be between ${min} and ${max}",
        },
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    const onCloseModal = () => {
        onCancel();
    };

    const BoxNewSchedule_pro_edit = () => {
        const {
            form,
            current,
            gotoStep,
            stepsProps,
            formProps,
            submit,
            formLoading,
        } = useStepsForm({
            async submit(values) {
                onFinish(values);
                await new Promise((r) => setTimeout(r, 500));
                return "ok";
            },
            total: 2,
            isBackValidate: false,
        });
        const [team, setTeam] = useState([]);
        const [userShare, setUserShare] = useState([]);
        const [dateStart, setDateStart] = useState(dataInforUploadFile.day_sign);
        const [checkInfinite, setCheckInfinite] = useState(false);
        const [time, setTime] = useState(["Ngày", "Tháng", "Năm"]);
        const [loadingUpdate, setLoadingUpdate] = useState(false);

        const disabledDate = (current) => {
            return current && current < form.getFieldValue("day_sign").endOf("day");
        };

        const disabled = () => {
            return dateStart === undefined || dateStart === "Invalid date"
                ? true
                : false;
        };

        const GetListTeam = () => {
            GetManageTeam({
                page: 1, page_size: 5000
            }).then((res) => {
                var listTeam = res.data.results.filter((team) => team.is_active === true);
                setTeam(listTeam.sort((a, b) => a.name.localeCompare(b.name)));
            });
        };

        const GetListUser = () => {
            setUserShare(userWithRoleMember.sort((a, b) => a.username.localeCompare(b.username)));
            // GetManageUser({
            //     page: 1, page_size: 5000
            // }).then((res) => {
            //     var listUser = res.data.results.filter((user) => user.is_active === true);
            //     setUserShare(listUser.sort((a, b) => a.username.localeCompare(b.username)));

            //     // setUserShare(listUser);
            // });
        };

        useEffect(() => {
            GetListTeam();
            GetListUser();
        }, []);

        const [fileList, setFileList] = useState([]);

        var arrUserUpdate = dataInforUploadFile.user_update.map((item, index) =>
            item.is_active === false ? item.username : item.id
        );

        var arrTagTeam = dataInforUploadFile.tag_team.map((item, index) =>
            item.is_active === false ? item.name : item.id
        );

        var arrLegalEntity = dataInforUploadFile.legal_entity_signed.map((item, index) =>
            item.is_active === false ? item.name : item.id
        );

        useEffect(() => {
            form.resetFields();
            if (open) {
                form.setFieldsValue({
                    pk: dataInforUploadFile.id,
                    customer: dataInforUploadFile.customer[0].full_name,
                    auto_extend: dataInforUploadFile.auto_extend,
                    content: dataInforUploadFile.content,
                    day_sign: moment(dataInforUploadFile.day_sign, "YYYY-MM-DD"),
                    day_expired:
                        dataInforUploadFile.day_expired === null
                            ? null
                            : moment(dataInforUploadFile.day_expired, "YYYY-MM-DD"),
                    tag_team: arrTagTeam,
                    type_document: dataInforUploadFile.type_document.length > 0 ? dataInforUploadFile.type_document[0].name : undefined,
                    rule_type_document: dataInforUploadFile.rule_type_document.length > 0 ? dataInforUploadFile.rule_type_document[0].name : undefined,
                    user_create: dataInforUploadFile.user_create[0].username,
                    user_update: arrUserUpdate,
                    project: dataInforUploadFile.project,
                    code_number: dataInforUploadFile.code_number,
                    file_document: dataInforUploadFile.file_document,
                    infinite: dataInforUploadFile.infinite,
                    extension_time: dataInforUploadFile.extension_time,
                    type_extension_time: dataInforUploadFile.type_extension_time,
                    legal_entity_signed: arrLegalEntity,
                });
                // const filterUserShare = dataInforUploadFile.user_update.filter(item => item.team.length > 0 ? !arrTagTeam.includes(item.team[0].id) : item.team.length === 0 ? item : null)
                // setDataUserShareFilter(filterUserShare)

            }
        }, [open]);

        const onFinish = (values) => {
            setLoadingUpdate(true);
            var FormData = require("form-data");
            var data = new FormData();
            data.append("customer", dataInforUploadFile.customer[0].id);
            data.append("type_document", dataInforUploadFile.type_document.length > 0 ? dataInforUploadFile.type_document[0].id : undefined);
            data.append("rule_type_document", dataInforUploadFile.rule_type_document.length > 0 ? dataInforUploadFile.rule_type_document[0].id : undefined);

            data.append("user_create", dataInforUploadFile.user_create[0].id);
            data.append("project", values.project !== undefined && values.project !== null ? values.project.trim() : "");
            data.append("user_update", values.user_update);
            data.append("legal_entity_signed", values.legal_entity_signed === null ? "" : values.legal_entity_signed);
            data.append("code_number", values.code_number !== undefined && values.code_number !== null ? values.code_number.trim() : "");
            data.append("infinite", values.infinite);
            data.append(
                "tag_team",
                values.tag_team.length !== 0 ? values.tag_team : []
            );

            data.append(
                "extension_time",
                values.extension_time === undefined ||
                    values.extension_time === null ||
                    values.auto_extend === false
                    ? ""
                    : values.extension_time
            );
            data.append(
                "type_extension_time",
                values.auto_extend === true ? values.type_extension_time : ""
            );
            data.append("auto_extend", values.auto_extend);
            data.append("day_sign", moment(values.day_sign).format("YYYY-MM-DD"));
            data.append(
                "day_expired",
                values.day_expired === undefined || values.day_expired === null
                    ? null
                    : moment(values.day_expired).format("YYYY-MM-DD")
            );
            data.append("content", values.content === "null" ? "" : values.content);
            if (values.file_document.fileList == undefined) {
                values.file_document.fileList =
                    dataInforUploadFile.file_document.fileList;
            } else {
                values.file_document.fileList.forEach((element) => {
                    data.append("file_document", element.originFileObj);
                });
            }
            PutUploadFile(values.pk, data)
                .then((res) => {
                    if (res.data.error) {
                        setLoadingUpdate(false);
                        openNotificationWithIcon("error", res.data.error);
                    } else if (res.data.warning) {
                        setLoadingUpdate(true);
                        if (checkFilter === false) {
                            fetchData({ page: pager.current, page_size: pager.pageSize, search });

                        } else {
                            fetchData({ page: pager.current, page_size: pager.pageSize, datafilter });

                        }
                        onCancel();
                        openNotificationWithIcon("warning", res.data.warning);
                    } else {
                        if (checkFilter === false) {
                            fetchData({ page: pager.current, page_size: pager.pageSize, search });
                        } else {
                            fetchData(
                                {
                                    page: pager.current, page_size: pager.pageSize, customer: datafilter.customer || "",
                                    type_document: datafilter.type_document || "",
                                    rule_type_document: datafilter.rule_type_document || "",
                                    legal_entity_signed: datafilter.legal_entity_signed || "",
                                    user_update: datafilter.user_update,
                                    auto_extend: datafilter.auto_extend,
                                    day_expired: datafilter.day_expired,
                                    day_sign: datafilter.day_sign,
                                    project: datafilter.project,
                                });

                        }
                        openNotificationWithIcon(
                            "success",
                            "Thành công",
                            "Chỉnh sửa hồ sơ thành công"
                        );
                        setLoadingUpdate(true);
                        onCancel();
                    }
                })
                .catch((err) => {
                    setLoadingUpdate(false);
                    if (err) {
                        openNotificationWithIcon("error", err.data.error);
                    }
                    if (err.status === 413) {
                        openNotificationWithIcon(
                            "error",
                            "Thất bại",
                            "Dung lượng tải lên quá lớn"
                        );
                    }
                });
        };



        const FirstStep = () => {
            const [dataUserShareFilter, setDataUserShareFilter] = useState(userShare.filter(item => !arrTagTeam.includes(item.team)))
            // const [dataUserShareFilter, setDataUserShareFilter] = useState(userShare.filter(item => item.team.length > 0 ? !arrTagTeam.includes(item.team[0].id) : item.team.length === 0 ? item : null))
            const [lsFile, setLsFile] = useState(
                dataInforUploadFile.file_document.filter((item) => item.is_active == true)
            );
            const [lsID, setLsID] = useState();
            form.setFieldsValue({
                type_extension_time:
                    dataInforUploadFile.type_extension_time == null
                        ? time[0]
                        : dataInforUploadFile.type_extension_time,
            });
            form.setFieldsValue({
                extension_time:
                    dataInforUploadFile.extension_time == null
                        ? 1
                        : dataInforUploadFile.extension_time,
            });
            const [checkBoxTN, setCheckBox] = useState(dataInforUploadFile.auto_extend);

            const disabledInfinite = () => {
                return form.getFieldValue("infinite") === true ? true : false;
            };

            const disabledAutoExtend = () => {
                return checkBoxTN === true ? true : false;
            };
            const formateDate = (current) => {
                return moment(current).format(dateFormat);
            };

            const handleDaySign = (value) => {
                form.resetFields(["day_expired"])
                setDateStart(moment(value).format(dateFormat))
            }

            const deleteFile = (record) => {
                let fileClone = lsFile
                DeleteFileDocument({ id: record.id })
                    .then((res) => {
                        openNotificationWithIcon("success", res.data.result);
                        // setLsID([...lsID, record.id]);
                        // console.log(record.id)
                        // console.log(lsFile.filter((item) => ![...lsID, record.id].includes(item.id)))
                        for (let i = 0; i < fileClone.length; i++) {
                            if (record.id === fileClone[i].id) {
                                fileClone.splice(i, 1)
                                break;
                            }
                        }
                        setLsFile([...fileClone])
                        // setLsFile(
                        //     lsFile.filter((item) => ![...lsID, record.id].includes(item.id))
                        // );
                    })
                    .catch((err) => {
                        openNotificationWithIcon(
                            "error",
                            "Thất bại",
                            "Xóa hồ sơ không thành công"
                        );
                    });
            };

            const handleCheckBoxChange = useCallback(
                (e) => {
                    setCheckBox(e.target.checked);
                    form.setFieldsValue({
                        auto_extend: e.target.checked,
                    });
                },
                [setCheckBox, form]
            );

            const chooseUserShare = (event) => {
                form.resetFields(["user_update"])
                // const filterUserShare = userShare.filter(item => item.team.length > 0 ? !event.includes(item.team[0].id) : item.team.length === 0 ? item : null)
                const filterUserShare = userShare.filter(item => !event.includes(item.team))
                if (event.length > 0) {
                    setDataUserShareFilter(filterUserShare)
                } else {
                    form.resetFields(["user_update"])
                    setDataUserShareFilter([])
                }
            }

            return (
                <>
                    <Row className="RowThuNhan">
                        <Col span={12}>
                            <Form.Item
                                name="customer"
                                label="Tên khách hàng"
                                rules={[{ required: true }]}
                                key="customer"
                            >
                                <Input style={{ color: "black" }} disabled maxLength={100} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginLeft: "16.7%" }}>
                                <Col span={12}>
                                    <Form.Item
                                        name="rule_type_document"
                                        label="Tài liệu"
                                        // rules={[{ required: true, message: "Không được để trống" }]}
                                        key="rule_type_document"
                                        style={{ width: "100%" }}
                                    >
                                        <Input style={{ color: "black", width: "95%" }} disabled maxLength={100} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ width: "400px" }}>
                                    <Form.Item
                                        name="type_document"
                                        key="type_document"
                                        style={{ width: "100%" }}
                                    // style={{ marginLeft: 35 }}
                                    >
                                        <Input style={{ color: "black" }} disabled maxLength={100} />
                                    </Form.Item>
                                </Col>
                            </div>
                        </Col>
                        <Col span={12}>

                            <Form.Item name="tag_team" label="Team" key="tag_team" rules={[{ required: true }]}>
                                <Select
                                    placeholder="Chọn Team"
                                    showSearch={false}
                                    style={{
                                        width: "100%",
                                    }}
                                    allowClear
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    key="team1"
                                    onChange={chooseUserShare}

                                >
                                    {team.map((item, index) => (
                                        <Option key={item.name} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="project"
                                label="Dự án"
                                key="project"
                            >
                                <Input maxLength={100} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>

                            <Form.Item
                                name="user_update"
                                label="Người được chia sẻ"
                                key="user_update"
                            >
                                <Select
                                    placeholder="Chọn người được chia sẻ"
                                    showSearch={false}
                                    optionFilterProp="children"
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    allowClear
                                    style={{
                                        width: "100%",
                                    }}
                                    key="user_update"
                                // defaultValue={dataInforUploadFile.user_update}
                                >
                                    {dataUserShareFilter.map((item, index) => (
                                        <Option key={index} value={item.id}>
                                            {item.username}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="code_number"
                                label="Số hiệu"
                                key="code_number"
                            >
                                <Input maxLength={100} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="day_sign"
                                label="Ngày kí kết"
                                rules={[{ required: true }]}
                                key="day_sign"
                            >
                                <DatePicker
                                    onChange={(value) =>
                                        handleDaySign(value)
                                    }
                                    style={{ width: "100%" }}
                                    format={formateDate}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="day_expired"
                                label="Ngày hết hạn"
                                // rules={[{ required: true }]}
                                key="day_expired"
                            >
                                <DatePicker
                                    disabled={disabled()}
                                    style={{ width: "100%" }}
                                    format={formateDate}
                                    disabledDate={disabledDate}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="content" label="Nội dung" key="content" rules={[{ required: true }]}
                            >
                                <Input.TextArea></Input.TextArea>
                            </Form.Item>
                        </Col>

                        <Col span={12}>

                            <Form.Item
                                name="legal_entity_signed"
                                label="Pháp nhân ký"
                                key="legal_entity_signed"
                            >
                                <Select
                                    placeholder="Chọn mã pháp nhân"
                                    showSearch={false}
                                    optionFilterProp="children"
                                    // mode="multiple"
                                    maxTagCount="responsive"
                                    allowClear
                                    style={{
                                        width: "100%",
                                    }}
                                    key="legal_entity_signed"
                                    mode="multiple"
                                >
                                    {legalEntity.map((item, index) => (
                                        <Option key={index} value={item.id}>
                                            {item.code_signer}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            {/* <div className="container" style={{ display: "flex" }}> */}
                            {/* <div style={{ width: "50%" }}>
                                    <Form.Item
                                        label={
                                            <span style={{ marginRight: 16 }}>Vô thời hạn:</span>
                                        }
                                        name="infinite"
                                        valuePropName={"checked"}
                                        key="infinite"
                                        style={{ width: "100%", marginLeft: 74 }}
                                    >
                                        <Checkbox
                                            disabled={disabledAutoExtend()}
                                            defaultChecked={form.getFieldValue("infinite")}
                                            onChange={(e) => {
                                                setCheckInfinite(!checkInfinite);
                                                form.setFieldsValue({ infinite: e.target.checked });
                                            }}
                                            style={{ marginLeft: 5 }}
                                        />
                                    </Form.Item>
                                </div> */}
                            {/* <div style={{ width: "100%" }}> */}
                            <Form.Item
                                label={<span>Tự động tái tục</span>}
                                name="auto_extend"
                                valuePropName={"checked"}
                                key="auto_extend"
                            // style={{ width: "150%" }}
                            >
                                <Checkbox
                                    disabled={disabledInfinite()}
                                    defaultChecked={dataInforUploadFile.auto_extend}
                                    onChange={handleCheckBoxChange}
                                />
                            </Form.Item>
                            {checkBoxTN ? (
                                <Row style={{ width: "100%" }}>
                                    <Col span={8}></Col>
                                    <Col span={5}>
                                        <Form.Item
                                            name="extension_time"
                                            key="extension_time"
                                            rules={[
                                                {
                                                    required: true,
                                                    type: "number",
                                                    max: 99,
                                                    message: "Tối đa 2 chữ số",
                                                },
                                            ]}
                                        >
                                            <InputNumber min={1} defaultValue={1} type="number" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11} style={{ width: "100%" }}>
                                        <Form.Item
                                            name="type_extension_time"
                                            key="type_extension_time"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Không được để trống",
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Chọn mốc thời gian"
                                                showSearch={false}
                                                style={{
                                                    width: 218,
                                                }}
                                                key="time"
                                            >
                                                {time.map((item, index) => (
                                                    <Option key={item.toString()} value={item}>
                                                        {item}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                ""
                            )}
                            {/* </div> */}
                            {/* </div> */}
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="file_document"
                                label="File Upload"
                                key="file_document"
                            >
                                <div style={{ overflowY: "auto", height: '10vh' }}>
                                    {lsFile.map((item, index) => (
                                        <Row style={{ marginBottom: "5px", width: "100%" }}>
                                            <Col span={22}>
                                                <Tag
                                                    style={{ fontSize: "12px" }}
                                                    color="blue"
                                                    value={item.name_file}
                                                    key={item.name_file}
                                                >
                                                    {item.name_file}
                                                </Tag>
                                            </Col>
                                            <Col span={1}>
                                                <span
                                                    title="Xóa file"
                                                    className={"col-6"}
                                                    style={{ color: "#4290d3", cursor: "pointer" }}
                                                >
                                                    <Popconfirm
                                                        placement="top"
                                                        title={"Bạn có chắc muốn xóa file này?"}
                                                        onConfirm={() => deleteFile(item)}
                                                        okText="Có"
                                                        cancelText="Không"
                                                    >
                                                        <DeleteOutlined
                                                            style={{ color: "red", cursor: "pointer" }}
                                                        />
                                                    </Popconfirm>
                                                </span>
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            </Form.Item>
                        </Col>

                        {/* <Col span={12}>
                            {checkBoxTN ? (
                                <Row style={{ width: "100%" }}>
                                    <Col span={8}></Col>
                                    <Col span={5}>
                                        <Form.Item
                                            name="extension_time"
                                            key="extension_time"
                                            rules={[
                                                {
                                                    required: true,
                                                    type: "number",
                                                    max: 99,
                                                    message: "Tối đa 2 chữ số",
                                                },
                                            ]}
                                        >
                                            <InputNumber min={1} defaultValue={1} type="number" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11} style={{ width: "100%" }}>
                                        <Form.Item
                                            name="type_extension_time"
                                            key="type_extension_time"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Không được để trống",
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Chọn mốc thời gian"
                                                showSearch={false}
                                                style={{
                                                    width: 218,
                                                }}
                                                key="time"
                                            >
                                                {time.map((item, index) => (
                                                    <Option key={item.toString()} value={item}>
                                                        {item}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                ""
                            )}
                        </Col> */}

                        <Col span={24} className="btnModal">
                            <Form.Item {...tailLayout} key="btn">
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        form.getFieldValue("auto_extend") === true
                                            ? form.getFieldValue("day_expired") > moment(new Date())
                                                ? form.getFieldValue("day_expired") >
                                                    form.getFieldValue("day_sign")
                                                    ? gotoStep(current + 1)
                                                    : openNotificationWithIcon(
                                                        "error",
                                                        "Thất bại",
                                                        "Ngày hết hạn phải lớn hơn ngày kí kết"
                                                    )
                                                : openNotificationWithIcon(
                                                    "error",
                                                    "Thất bại",
                                                    "Ngày hết hạn phải lớn hơn ngày hiện tại"
                                                )
                                            : form.getFieldValue("day_expired") === undefined ||
                                                form.getFieldValue("day_expired") === null ||
                                                form.getFieldValue("day_expired") >
                                                form.getFieldValue("day_sign")
                                                ? gotoStep(current + 1)
                                                : openNotificationWithIcon(
                                                    "error",
                                                    "Thất bại",
                                                    "Ngày hết hạn phải lớn hơn ngày kí kết"
                                                );
                                    }}
                                >
                                    Tiếp theo
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            );
        };

        const SecondStep = () => {
            const handleChange = (info) => {
                let newFileList = [...info.fileList];
                setFileList(newFileList);
            };

            return (
                <Spin spinning={loadingUpdate}>
                    {screen.width > 768 ? (
                        // Màn hình > 768
                        <Row>
                            <Col span={18}>
                                <p
                                    style={{
                                        marginLeft: "33%",
                                        color: "#FF463F",
                                        fontStyle: "italic",
                                        marginBottom: 0,
                                    }}
                                    className="ant-upload-text"
                                >
                                    Tổng dung lượng File tối đa 50MB !
                                </p>
                                <Form.Item
                                    name="file_document"
                                    label={<FolderIcon />}
                                    key="folder"
                                >
                                    <Dragger
                                        accept="image/png, image/jpeg, .pdf, .xlsx,.docx"
                                        name="file"
                                        openFileDialogOnClick={true}
                                        // defaultFileList={fileList.map(file => file.file_contract)}
                                        defaultFileList={fileList}
                                        // rules={[{ required: true }]}
                                        // action ='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                                        fileList={fileList}
                                        // fileList={dataInforUploadFile.file_document}
                                        // maxCount={1}
                                        onChange={(info) => handleChange(info)}
                                        showUploadList={true}
                                        multiple={true}
                                        beforeUpload={(file, FileList) => {
                                            const isJpgOrPng =
                                                file.type !== "application/pdf" &&
                                                file.type !== "image/png" &&
                                                file.type !== "image/jpeg" &&
                                                file.type !==
                                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                                                file.type !==
                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                                            const checkSize = file.size / 1024 / 1024 > 5;

                                            if (isJpgOrPng) {
                                                openNotificationWithIcon(
                                                    "error",
                                                    "Lỗi",
                                                    `Sai định dạng file !`
                                                );
                                                return Upload.LIST_IGNORE;
                                            }
                                            if (checkSize) {
                                                openNotificationWithIcon("error", "Lỗi", `Quá 5MB !`);
                                            }

                                            return !isJpgOrPng && !checkSize
                                                ? false
                                                : Upload.LIST_IGNORE;
                                        }}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">
                                            Click hoặc kéo thả để chọn file
                                        </p>
                                        <p className="ant-upload-hint">
                                            Chọn file trên thiết bị của bạn (
                                            <span style={{ color: "#FF463F", fontStyle: "italic" }}>
                                                Hỗ trợ định dạng Hình ảnh *.png, pdf, xlsx, docx
                                            </span>
                                            )
                                        </p>
                                    </Dragger>
                                </Form.Item>
                            </Col>
                            <Col span={24} className="btnModal">
                                <Form.Item {...tailLayout} key="2">
                                    <LoadingButton
                                        style={{ marginRight: 10 }}
                                        variant="contained"
                                        color="success"
                                        // loading={loadingUpdate}
                                        type="submit"
                                        key={"11"}
                                        onClick={(values) => {
                                            form.validateFields().then((r) => {
                                                submit().then((result) => {
                                                    var newSize = 0;
                                                    var oldSize = 0;
                                                    dataInforUploadFile.file_document.map((item) => {
                                                        oldSize += item.size;
                                                    });
                                                    if (r.file_document.fileList !== undefined) {
                                                        r.file_document.fileList.map((item) => {
                                                            newSize += item.size;
                                                        });
                                                    }
                                                    const listSize =
                                                        newSize / 1024 / 1024 + oldSize / 1024 / 1024 > 5;
                                                    if (listSize) {
                                                    }
                                                });
                                            });
                                        }}
                                    >
                                        Chỉnh sửa
                                    </LoadingButton>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            gotoStep(current - 1);
                                        }}
                                    // disabled={loadingUpdate}
                                    >
                                        Quay lại
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : (
                        // Màn hình < 768
                        <>
                            <Col span={24}>
                                <p
                                    style={{
                                        marginLeft: "14%",
                                        color: "#FF463F",
                                        fontStyle: "italic",
                                        marginBottom: 0,
                                    }}
                                    className="ant-upload-text"
                                >
                                    Tổng dung lượng File tối đa 50MB !
                                </p>
                                <Form.Item
                                    name="file_document"
                                    // label={<FolderIcon />}
                                    key="folder"
                                    style={{ marginBottom: 50 }}
                                >
                                    <Dragger
                                        accept="image/png, image/jpeg, .pdf, .xlsx,.docx"
                                        name="file"
                                        openFileDialogOnClick={true}
                                        defaultFileList={fileList}
                                        // rules={[{ required: true }]}
                                        // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
                                        fileList={fileList}
                                        // maxCount={1}
                                        style={{ width: "100%" }}
                                        onChange={(info) => handleChange(info)}
                                        showUploadList={true}
                                        multiple={true}
                                        beforeUpload={(file, FileList) => {
                                            const isJpgOrPng =
                                                file.type !== "application/pdf" &&
                                                file.type !== "image/png" &&
                                                file.type !== "image/jpeg" &&
                                                file.type !==
                                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                                                file.type !==
                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                                            const checkSize = file.size / 1024 / 1024 > 5;

                                            if (isJpgOrPng) {
                                                openNotificationWithIcon(
                                                    "error",
                                                    "Lỗi",
                                                    `Sai định dạng file !`
                                                );
                                                return Upload.LIST_IGNORE;
                                            }
                                            if (checkSize) {
                                                openNotificationWithIcon("error", "Lỗi", `Quá 5MB !`);
                                            }

                                            return !isJpgOrPng && !checkSize
                                                ? false
                                                : Upload.LIST_IGNORE;
                                        }}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">
                                            Click hoặc kéo thả để chọn file
                                        </p>
                                        <p className="ant-upload-hint">
                                            Chọn file trên thiết bị của bạn (
                                            <span style={{ color: "#FF463F", fontStyle: "italic" }}>
                                                Hỗ trợ định dạng Hình ảnh *.png, pdf, xlsx, docx
                                            </span>
                                            )
                                        </p>
                                    </Dragger>
                                </Form.Item>
                            </Col>
                            <Col span={24} className="btnModal">
                                <Form.Item {...tailLayout} key="2">
                                    <LoadingButton
                                        style={{ marginRight: 10 }}
                                        variant="contained"
                                        color="success"
                                        // loading={loadingUpdate}
                                        type="submit"
                                        key={"12"}
                                        onClick={(values) => {
                                            form.validateFields().then((r) => {
                                                submit().then((result) => {
                                                    var newSize = 0;
                                                    var oldSize = 0;
                                                    dataInforUploadFile.file_document.map((item) => {
                                                        oldSize += item.size;
                                                    });
                                                    if (r.file_document.fileList !== undefined) {
                                                        r.file_document.fileList.map((item) => {
                                                            newSize += item.size;
                                                        });
                                                    }
                                                    const listSize =
                                                        newSize / 1024 / 1024 + oldSize / 1024 / 1024 > 5;
                                                    if (listSize) {
                                                    }
                                                });
                                            });
                                        }}
                                    >
                                        Chỉnh sửa
                                    </LoadingButton>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            gotoStep(current - 1);
                                        }}
                                    // disabled={loadingUpdate}
                                    >
                                        Quay lại
                                    </Button>
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </Spin>
            );
        };

        const formList = [<FirstStep />, <SecondStep />];

        return (
            <div>
                <Steps current={current}>
                    <Step title="Khai báo thông tin" />
                    <Step title="Upload File" />
                </Steps>

                <div style={{ marginTop: 30 }}>
                    <Form
                        {...layout}
                        {...formProps}
                        style={{ width: "100%" }}
                        validateMessages={validateMessages}
                    >
                        {formList[current]}
                    </Form>

                    {current === 2 && (
                        <Result
                            status="success"
                            title="Submit is succeed!"
                            extra={
                                <>
                                    <Button
                                        onClick={() => {
                                            form.resetFields();
                                        }}
                                    >
                                        Tạo đăng ký mới
                                    </Button>
                                </>
                            }
                        />
                    )}
                </div>
            </div>
        );
    };

    return (
        <Modal
            title="Chỉnh sửa chứng từ"
            visible={open}
            onCancel={onCloseModal}
            width={screen.width > 768 ? 1000 : "80%"}
            footer={false}
            style={{ height: "50vh" }}
            bodyStyle={
                screen.width > 768
                    ? null
                    : { overflowY: "auto", maxHeight: "calc(50vh - 145px)" }
            }
        >
            <div>
                <BoxNewSchedule_pro_edit />
            </div>
        </Modal>
    );
};


export default React.memo(ModalEditContract)
import React, { useEffect, useState } from "react";
import {
    Modal,
    Form,
    Input,
    Row,
    Col,
} from "antd";

const ModalDetailUser = ({ open, onCancel, dataInforUser }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        form.resetFields();
        if (open) {
            form.setFieldsValue({
                username: dataInforUser.username,
                email: dataInforUser.email,
                last_name: dataInforUser.last_name,
                first_name: dataInforUser.first_name,
                role_user: dataInforUser.role_user[0].name,
                team: dataInforUser.team.length > 0 ? dataInforUser.team[0].name : [],
                msnv: dataInforUser.msnv,
            });
        }
    }, [open]);

    const onCloseModal = () => {
        form.resetFields();
        onCancel();
    };

    return (
        <Modal
            title="Chi tiết người dùng"
            visible={open}
            onCancel={onCloseModal}
            width={screen.width > 768 ? 800 : "80%"}
            footer={false}
            bodyStyle={
                screen.width > 768
                    ? null
                    : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
            }
        >
            <Form form={form} layout="vertical" autoComplete="off">
                {screen.width > 768 ? (
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                name="username"
                                label="User name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                    },
                                ]}
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="first_name"
                                label="Tên"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item
                                name="last_name"
                                label="Họ"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="role_user"
                                label="Phân quyền"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="role_user"
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item
                                name="team"
                                label="Team"
                                // rules={[
                                //   {
                                //     required: true,
                                //   },
                                // ]}
                                key="team"
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="msnv"
                                label="Mã số nhân viên"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="username"
                                label="User name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                    },
                                ]}
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="last_name"
                                label="Tên"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="first_name"
                                label="Họ"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="role_user"
                                label="Phân quyền"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="role_user"
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="team"
                                label="Team"
                                // rules={[
                                //   {
                                //     required: true,
                                //   },
                                // ]}
                                key="team"
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="msnv"
                                label="Mã số nhân viên"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Form.Item></Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalDetailUser
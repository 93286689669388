import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { GetRuleTypeDocumentAll, GetTypeDocumentAll, PutTypeDocumentApi } from '../../../../api/manageDocs';
import { errorHandle, openNotificationWithIcon } from '../../../Function';
import '../taiLieu/taiLieu.css';

const { Option } = Select;

const validateMessages = {
    required: "${label} không được để trống!",
    types: {
        email: "${label} is not in the correct email format!",
        number: "${label} not numbers!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};


const ModalEditNameDocument = ({ open, data, onCancel, fetchData, pager, search }) => {
    const [form] = Form.useForm();
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [dataNameDocsFilter, setDataNameDocsFilter] = useState([])

    const [dataAllTypeDocs, setDataAllTypeDocs] = useState([])
    const [dataAllNameDocs, setDataAllNameDocs] = useState([])

    const fetchAllTypeDocument = () => {
        // setLoading(true);
        GetRuleTypeDocumentAll()
            .then((res) => {
                setDataAllTypeDocs(res.data)
                // setData(res.data.results);
                // setPager({
                //   current: params.page,
                //   pageSize: params.page_size,
                //   count: res.data.count,
                // });
                // setLoading(false);
            })
            .catch((err) => {
                errorHandle(err);
                // setLoading(false);
            });
    };

    const fetchAllNameDocument = () => {
        // setLoading(true);
        GetTypeDocumentAll()
            .then((res) => {
                setDataAllNameDocs(res.data)
                const filterNameDocs = res.data.filter(item => item.fore_rule_typedoc === data.fore_rule_typedoc)
                setDataNameDocsFilter(filterNameDocs)
                // setData(res.data.results);
                // setPager({
                //   current: params.page,
                //   pageSize: params.page_size,
                //   count: res.data.count,
                // });
                // setLoading(false);
            })
            .catch((err) => {
                errorHandle(err);
                // setLoading(false);
            });
    };


    useEffect(() => {
        form.resetFields();
        if (open) {
            form.setFieldsValue({
                type_document: data.fore_rule_typedoc,
                name_document: data.name,
            });
            fetchAllTypeDocument()
            fetchAllNameDocument()
        }
    }, [open]);

    const onFinish = (values) => {
        setLoadingEdit(true);
        const typeDocument = {
            type_document_id: data.id,
            name_type_document: values.name_document.trim().replace(/\s+/g, ' '),
            is_lock: true,
            id_rule: values.type_document,
        };
        PutTypeDocumentApi(typeDocument)
            .then((res) => {
                if (res.data.error) {
                    openNotificationWithIcon("error", res.data.error);
                } else {
                    setLoadingEdit(false);
                    fetchData({ page: pager.current, page_size: pager.pageSize, search });
                    openNotificationWithIcon(
                        "success",
                        "Thành công",
                        "Chỉnh sửa tài liệu thành công"
                    );
                    setLoadingEdit(false);
                    onCancel();
                    form.resetFields();
                }
            })
            .catch((err) => {
                if (err.data.error) {
                    setLoadingEdit(false);
                    openNotificationWithIcon("error", err.data.error);
                }
            });
    };

    const typeDoc = [
        "MOU", "Thỏa thuận hợp tác", "Hợp đồng dịch vụ", "Hợp đồng bảo trì", "Phụ lục hợp đồng", "Biên bản", "Đề nghị thanh toán", "Khác"
    ]

    const handleChangeTypeDocs = (value) => {
        // const filterNameDocs = dataAllNameDocs.filter(item => item.data_rule[0].id === data.id_type_document)
        const filterNameDocs = dataAllNameDocs.filter(item => item.fore_rule_typedoc === value)
        setDataNameDocsFilter(filterNameDocs)
        // formAddDoc.setFieldsValue({ type_document: value })
    }

    return (<>
        <Modal
            title="Chỉnh sửa tài liệu"
            visible={open}
            onCancel={onCancel}
            className="modalAddUser"
            width={screen.width > 768 ? 800 : "80%"}
            footer={false}
            bodyStyle={
                screen.width > 768
                    ? null
                    : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
                // loading={loading}
                initialValues={{
                    remember: true,
                }}
                key="form_team"
            >
                <Row>
                    <Col span={11}>
                        <Form.Item
                            name="type_document"
                            label="Loại tài liệu"
                            rules={[
                                {
                                    required: true,
                                },
                                // {
                                //   pattern: new RegExp(
                                //     "^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]*$"
                                //   ),
                                //   message:
                                //     "Không được nhập khoảng cách hoặc tiếng Việt có dấu",
                                // },
                            ]}
                            key="type_document"
                        >
                            <Select
                                placeholder="Chọn loại tài liệu"
                                showSearch={false}
                                optionFilterProp="children"
                                maxTagCount="responsive"
                                style={{
                                    width: "100%",
                                }}
                                key="type_document"
                                onChange={handleChangeTypeDocs}
                            >
                                {dataAllTypeDocs.map((item, index) => (
                                    <Option key={item.name} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={2}>
                        <Form.Item
                            name="name_document"
                            label="Tên tài liệu"
                            key="name_document"
                            rules={[
                                // {
                                //     pattern: new RegExp(
                                //         "^[a-zA-Z0-9À-ỹỲỳỴỵỶỷỸỹ_ -]+$"
                                //     ),
                                //     message:
                                //         "Chỉ được nhập chữ, số và kí tự: - và _",
                                // },
                                {
                                    pattern: new RegExp(
                                        "^[a-zA-Z0-9À-ỹỲỳỴỵỶỷỸỹ_ \\-!@#$%^}&\\\\*=+{[\\|(`~),./?\\]]+$"
                                    ),
                                    message:
                                        "Không được nhập các kí tự đặc biệt như: \" : < > ; ' ",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    style={{ marginTop: "20px", textAlign: "center" }}
                    key="btn_team"
                >
                    <LoadingButton
                        type="submit"
                        className={"m-2"}
                        style={{ marginRight: "20px" }}
                        variant="contained"
                        loading={loadingEdit}
                    >
                        Chỉnh sửa
                    </LoadingButton>
                    <Button
                        onClick={onCancel}
                        color="error"
                        variant="contained"
                    // disabled={loadingAddNew}
                    >
                        Hủy
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    </>)
}

export default ModalEditNameDocument
import React, { useEffect } from 'react'
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Col, Row, Input, Popconfirm, Table } from 'antd';
import { Box, Button, Grid } from '@mui/material';
import { DeleteOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { useState } from 'react';
import ModalAddForm from './ModalAddForm';
import ModalEditForm from './ModalEditForm';
import { openNotificationWithIcon } from '../../../Function';
import ModalImportFileForm from './ModalImportFileForm';
import { useSelector } from 'react-redux';
import { ExportFormTemplateApi, GetFormTemplate, PutForm } from '../../../../api/manageForm';
import { GetRuleTypeDocumentAll } from '../../../../api/manageDocs';
import moment from 'moment';
const { Search } = Input;

function BieuMau() {
  const [isOpenModalAddForm, setIsOpenModalAddForm] = useState(false)
  const [isOpenModalEditForm, setIsOpenModalEditForm] = useState(false)
  const [dataDetail, setDataDetail] = useState({})
  const [dataForm, setDataForm] = useState([])
  const [ruleTypeDocs, setRuleTypeDocs] = useState([])
  const [search, setSearch] = useState("");
  const [isOpenModalImportFile, setIsOpenModalImportFile] = useState(false)

  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const checkRoleManageAdmin = userInfo.role_name.toLowerCase() === "manage_admin"

  const [pager, setPager] = useState({
    pageSize: 10,
    count: 0,
    current: 1,
  });

  const fetchFormData = (params = {}) => {
    GetFormTemplate(params).then(res => {
      setDataForm(res.data.results)
      setPager({
        current: params.page,
        pageSize: params.page_size,
        count: res.data.count,
      });
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    fetchRuleTypeDocs()
  }, []);

  const onShowModalImportFile = (record) => {
    setDataDetail(record)
    setIsOpenModalImportFile(true)
  }

  const closeModalImport = () => {
    setIsOpenModalImportFile(false)
  }

  const deleteForm = (record) => {
    const lock = {
      form_id: record.id,
      is_lock: false,
      is_active: false,
    };
    PutForm(lock)
      .then((res) => {
        fetchFormData({ page: pager.current, page_size: pager.pageSize, search });
        openNotificationWithIcon("success", "Thành công", res.data.result);
      })
      .catch((err) => {
        if (err.data.error) {
          openNotificationWithIcon("error", err.data.error);
        }
      });
  };

  // const downloadForm = (record) => {
  //   console.log(record)
  //   let file_path = record.file_form;
  //   let a = document.createElement("a");
  //   a.href = file_path;
  //   a.target = "_blank";
  //   console.log(file_path.substr(file_path.lastIndexOf("/") + 1))
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // }

  const downloadForm = (record) => {
    // setLoading_page(true);
    const fnFileDownload = require("js-file-download");
    const filename =
      "DanhSachFile_" + moment(new Date()).format("DDMMYYYY") + ".pdf";
    ExportFormTemplateApi({id_form: record.id})
      .then((res) => {
        fnFileDownload(res.data,record.name_file);
        // setLoading_page(false);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Không tải được danh sách hồ sơ. Bạn vui lòng thử lại."
        );
        // setLoading_page(false);
      });
  };

  const renderDetailsButton = (record) => {
    return (
      <Box sx={{ width: "100%" }}>
        <Grid item container xs={24} style={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: "1.2ch" }}>
          <Grid
            xs={1}
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <span
              title="Download"
              className={"col-6"}
              // style={{ color: "#4290d3", cursor: "pointer"}}
              onClick={() => downloadForm(record)}
            >
              <DownloadOutlined style={{ color: "green", cursor: "pointer" }} />
            </span>
          </Grid>
          {/* <Grid
            xs={1}
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <span
              title="Upload"
              className={"col-6"}
              // style={{ color: "#4290d3", cursor: "pointer"}}
              onClick={() => onShowModalImportFile(record)}
            >
              <UploadOutlined style={{ color: "red", cursor: "pointer" }} />
            </span>
          </Grid> */}
          <Grid
            xs={1}
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <span
              title="Chỉnh sửa"
              className={"col-6"}
              style={{ color: "#4290d3", cursor: "pointer" }}
              onClick={() => onShowModalEdit(record)}
            >
              {/* <a href={record.file_form} download>test</a> */}
              <EditOutlined />
            </span>
          </Grid>
          <Grid
            xs={1}
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Popconfirm
              placement="top"
              title={"Bạn có chắc muốn xóa hồ sơ này?"}
              onConfirm={() => deleteForm(record)}
              okText="Có"
              cancelText="Không"
            >
              <DeleteOutlined style={{ color: "red", cursor: "pointer" }} />
            </Popconfirm>
            {/* {record.is_active === false ? (
              <span
                title={"Tài liệu đang khóa"}
                className={"col-6"}
                style={{ color: "red", cursor: "pointer" }}
              >
                <Popconfirm
                  title="Xác nhận mở khóa tài liệu"
                  onConfirm={() => onLock_Unlock(record)}
                  okText="Mở khóa"
                  cancelText="Hủy"
                >
                  <LockOutlined style={{ color: "#ff4d4f" }} />

                </Popconfirm>
              </span>
            ) : (
              <span
                title={"Tài liệu đang mở"}
                className={"col-6"}
                style={{ color: "#008000", cursor: "pointer" }}
              >
                <Popconfirm
                  title="Xác nhận khóa loại tài liệu"
                  onConfirm={() => onLock_Unlock(record)}
                  okText="Khóa"
                  cancelText="Hủy"
                >
                  <UnlockOutlined style={{ color: "green" }} />

                </Popconfirm>
              </span>
            )} */}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const columnsManageAdmin = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "10%",
      render: (value, item, index) => index + 1 + (pager.current - 1) * pager.pageSize,
    },
    {
      title: "Loại tài liệu",
      dataIndex: "name_rule",
      key: "name_rule",
      align: "left",
      ellipsis: true,
      render: (text, record) => text
      // (
      //   <>
      //     <Tooltip placement="top" title={text}>
      //       <span>{text}</span>
      //     </Tooltip>
      //   </>
      // ),
    },

    {
      title: "Tên biểu mẫu",
      dataIndex: "name_form",
      key: "name_form",
      align: "left",
      ellipsis: true,
      render: (text, record) => text
    },
  ];
  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "10%",
      render: (value, item, index) => index + 1 + (pager.current - 1) * pager.pageSize,
    },
    {
      title: "Loại tài liệu",
      dataIndex: "name_rule",
      key: "name_rule",
      align: "left",
      ellipsis: true,
      render: (text, record) => text
      // (
      //   <>
      //     <Tooltip placement="top" title={text}>
      //       <span>{text}</span>
      //     </Tooltip>
      //   </>
      // ),
    },

    {
      title: "Tên biểu mẫu",
      dataIndex: "name_form",
      key: "name_form",
      align: "left",
      ellipsis: true,
      render: (text, record) => text
    },

    {
      title: "Thao tác",
      align: "center",
      render: renderDetailsButton,
      width: "20%",
    },
  ];

  const onLock_Unlock = (record) => {
    const lock = {
      form_id: record.id,
      is_lock: false,
      is_active: !record.is_active,
    };
    PutForm(lock)
      .then((r) => {
        fetchFormData({ page: pager.current, page_size: pager.pageSize, search });
      })
      .catch((err) => {
        if (err.data.error) {
          openNotificationWithIcon("error", err.data.error);
        }
      });
  };

  const onShowModalEdit = (record) => {
    setDataDetail(record)
    setIsOpenModalEditForm(true)
  }

  const onCancelModalEdit = (record) => {
    setIsOpenModalEditForm(false)
  }


  const showModalAddForm = () => {
    setIsOpenModalAddForm(true)
  }

  const onCancelModalAdd = () => {
    setIsOpenModalAddForm(false)
  }

  const fetchRuleTypeDocs = () => {
    GetRuleTypeDocumentAll().then(res => {
      setRuleTypeDocs(res.data)
    }).catch(err => {
      console.log(err)
    })
  }

  const onSearch = (e) => {
    setSearch(e);
  };

  useEffect(() => {
    const id = setInterval(() => {
      fetchFormData({ page: 1, page_size: pager.pageSize, search });
      clearInterval(id);
    }, 300);
    return () => clearInterval(id);
  }, [search]);

  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = pagination.pageSize;
    setPager({ ...currentPager });
    fetchFormData({
      status: currentPager.status,
      page_size: pagination.pageSize,
      page: pagination.current,
      search,
    });
  };

  return (
    <>
      <Row style={{ width: "100%", marginBottom: "10px" }}>
        <Col span={16}>
          <Button
            className="btnAddUser"
            style={{ marginRight: "16px", float: "left" }}
            onClick={showModalAddForm}
          >
            <ControlPointIcon style={{ fontSize: 20, marginRight: "5px" }} /> Thêm mới
          </Button>
        </Col>
        <Col span={8}>
          <Search
            placeholder="Nhập thông tin cần Tìm kiếm"
            allowClear
            enterButton="Tìm kiếm"
            size="middle"
            onSearch={onSearch}
            loading={false}
          />
        </Col>
      </Row>

      <Row>
        <Col span={18} offset={3}>
          <Table
            columns={columns}
            dataSource={dataForm}
            onChange={handleChange}
            rowClassName={"styleTaiLieu"}
            locale={{ emptyText: "Không có dữ liệu" }}
            style={{ position: "absolute" }}
            scroll={{
              y: "68vh",
            }}
            pagination={{
              current: pager.current,
              pageSize: pager.pageSize,
              // showSizeChanger: true,
              // showQuickJumper: true,
              total: pager.count,
              // pageSizeOptions: ["10", "15", "25", "50"],
              // locale: {
              //   jump_to: "Đến trang: ",
              //   page: "",
              // },
              showSizeChanger: true,
            }}
            size="middle"
          />
        </Col>
      </Row>


      <ModalAddForm
        isOpenModalAddForm={isOpenModalAddForm}
        onCancelModalAdd={onCancelModalAdd}
        ruleTypeDocs={ruleTypeDocs}
        fetchFormData={fetchFormData}
        pager={pager}
        search={search}
      />

      <ModalEditForm
        isOpenModalEditForm={isOpenModalEditForm}
        onCancelModalEdit={onCancelModalEdit}
        dataDetail={dataDetail}
        ruleTypeDocs={ruleTypeDocs}
        fetchFormData={fetchFormData}
        pager={pager}
        search={search}
      />

      <ModalImportFileForm
        isOpenModalImportFile={isOpenModalImportFile}
        dataDetail={dataDetail}
        closeModalImport={closeModalImport}
        fetchFormData={fetchFormData}
        pager={pager}
        search={search}

      />
    </>
  )
}

export default BieuMau
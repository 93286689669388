import React, { useEffect, useState } from "react";
import {
    Modal,
    Form,
    Row,
    Col,
    Select,
    Input,
    Upload,
    Spin,
} from "antd";

import {
    openNotificationWithIcon,
} from "../../../Function";
import moment from "moment";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FolderIcon from "@mui/icons-material/Folder";

import {
    DownloadOutlined,
    InboxOutlined,
} from "@ant-design/icons";
import {
    ImportCustomer,
    TemplateCustomer,
} from "../../../../api/manageCustomer";

import "../quanLyKhachHang/QuanLyKH"

const validateMessages = {
    required: "Vui lòng nhập ${label} !",
    types: {
        email: "${label} sai định dạng!",
        number: "${label} phải là số!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};
const { Option } = Select;
const { Search } = Input;
const { Dragger } = Upload;

const ModalImportFileCustomer = ({ open, onCancel, fetchData, pager, setIsModalImportFile }) => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [loadingImport, setLoadingImport] = useState(false);

    useEffect(() => {
        form.resetFields();
    }, []);

    const onCloseModal = () => {
        form.resetFields();
        setFileList([])
        onCancel();
    };

    const handleChange = (info) => {
        let newFileList = [...info.fileList];
        setFileList(newFileList);
    };

    const onFinishImport = (values) => {
        setLoadingImport(true);
        var FormData = require("form-data");
        var data = new FormData();
        data.append("fileupload", values.fileupload.fileList[0].originFileObj);
        ImportCustomer(data)
            .then((res) => {
                // setLoadingAddNew(true);
                openNotificationWithIcon(
                    "success",
                    "Thành công",
                    "Thêm mới khách hàng thành công"
                );
                fetchData({ page: pager.current, page_size: pager.pageSize });
                onCloseModal();
                form.resetFields();
                setLoadingImport(false);
            })
            .catch((err) => {
                setLoadingImport(false);
                openNotificationWithIcon("error", err.data.message);
            });
    };

    const DownloadTemplate_Customer = () => {
        // setLoading_page(true);
        const fnFileDownload = require("js-file-download");
        const filename =
            "FileMauKhachHang_" + moment(new Date()).format("DDMMYYYY") + ".xlsx";
        TemplateCustomer({ responseType: "blob" })
            .then((res) => {
                fnFileDownload(res.data, filename);
                // setLoading_page(false);
            })
            .catch((err) => {
                openNotificationWithIcon(
                    "error",
                    "Không tải được danh sách hồ sơ. Bạn vui lòng thử lại."
                );
                // setLoading_page(false);
            });
    };
    return (
        <>
            <Modal
                title={
                    <Row>
                        <Col span={15}>
                            <span>Upload file</span>
                        </Col>
                        <Col span={8}>
                            <a
                                // href={localhost + "/media/template/Template_customer.xlsx"}
                                // href={"/media/template/Template_customer.xlsx"}
                                style={{ float: "right" }}
                                onClick={DownloadTemplate_Customer}
                            >
                                <DownloadOutlined style={{ marginRight: 5, fontSize: 14 }} />
                                <span style={{ fontSize: 14 }}>File mẫu</span>
                            </a>
                        </Col>
                    </Row>
                }
                visible={open}
                onCancel={onCloseModal}
                className="modalAddUser site-drawer-render-in-current-wrapper"
                width={screen.width > 768 ? 800 : "80%"}
                footer={false}
                bodyStyle={
                    screen.width > 768
                        ? null
                        : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
                }
            >
                <Spin spinning={loadingImport}>
                    <Form
                        form={form}
                        layout="vertical"
                        autoComplete="off"
                        onFinish={onFinishImport}
                        // onFinishFailed={onFinishFailed}
                        // validateMessages={validateMessages}
                        initialValues={{
                            remember: true,
                        }}
                        key="formImport"
                    >
                        <Form.Item
                            name="fileupload"
                            label={<FolderIcon />}
                            rules={[
                                {
                                    required: true,
                                    message: "File upload không được để trống!",
                                },
                            ]}
                            key="fileupload"
                        >
                            <Dragger
                                accept=".xlsx"
                                name="file"
                                openFileDialogOnClick={true}
                                fileList={fileList}
                                onChange={(info) => handleChange(info)}
                                showUploadList={true}
                                beforeUpload={(file, FileList) => {
                                    const isJpgOrPng =
                                        file.type !==
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                                    // const checkSize = file.size / 1024 / 1024 > 5;

                                    if (isJpgOrPng) {
                                        openNotificationWithIcon(
                                            "error",
                                            "Lỗi",
                                            `Sai định dạng file !`
                                        );
                                        return Upload.LIST_IGNORE;
                                    }
                                    // if (checkSize) {
                                    //   openNotificationWithIcon("error", "Lỗi", `Quá 5MB !`);
                                    // }

                                    return !isJpgOrPng ? false : Upload.LIST_IGNORE;
                                }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    Click hoặc kéo thả để chọn file
                                </p>
                                <p className="ant-upload-hint">
                                    Chọn file trên thiết bị của bạn (
                                    <span style={{ color: "#FF463F", fontStyle: "italic" }}>
                                        Hỗ trợ định dạng xlsx
                                    </span>
                                    )
                                </p>
                            </Dragger>
                        </Form.Item>
                        <Form.Item
                            style={{ marginTop: "20px", textAlign: "center" }}
                            key="button"
                        >
                            <LoadingButton
                                type="submit"
                                className={"m-2"}
                                style={{ marginRight: "20px" }}
                                variant="contained"
                            // loading={loadingAddNew}
                            >
                                Đăng ký
                            </LoadingButton>
                            <Button
                                onClick={onCloseModal}
                                color="error"
                                variant="contained"
                            // disabled={loadingAddNew}
                            >
                                Hủy
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
};

export default ModalImportFileCustomer
import { EditOutlined, LockOutlined, UnlockOutlined, DeleteOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid } from '@mui/material';
import { Col, Form, Input, Modal, Popconfirm, Row, Table, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { GetDetailLegalEntity, GetListLegalEntity, PostLegalEntity, PutLegalEntity, PutLegalEntityAPI } from '../../../../api/usersApi';
import { errorHandle, openNotificationWithIcon, toSlug } from '../../../Function';
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import './phapNhanKy.css';
import { useSelector } from 'react-redux';

const { Search } = Input;

const validateMessages = {
  required: "${label} không được để trống !",
  types: {
    email: "${label} is not in the correct email format!",
    number: "${label} not numbers!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const ModalEditTypeDocument = ({ open, data, onCancel, fetchData, pager }) => {
  const [form] = Form.useForm();
  const [loadingEdit, setLoadingEdit] = useState(false);
  useEffect(() => {
    form.resetFields();
    if (open) {
      form.setFieldsValue({
        id_legal_signed: data.id,
        code_signer: data.code_signer,
        name_signer: data.name_signer,
      });
    }
  }, [open]);

  const onFinish = (values) => {
    setLoadingEdit(true);
    const typeDocument = {
      id_legal_signed: data.id,
      code_signer: values.code_signer,
      name_signer: values.name_signer,
      is_lock: true,
      // is_active: true,
    };
    PutLegalEntityAPI(typeDocument)
      .then((res) => {
        if (res.data.error) {
          openNotificationWithIcon("error", res.data.error);
        } else {
          setLoadingEdit(true);
          fetchData({ page: pager.current, page_size: pager.pageSize });
          openNotificationWithIcon(
            "success",
            "Thành công",
            "Chỉnh sửa mã pháp nhân thành công"
          );
          setLoadingEdit(false);
          onCancel();
          form.resetFields();
        }
      })
      .catch((err) => {
        if (err.data.error) {
          setLoadingEdit(false);
          openNotificationWithIcon("error", err.data.error);
        }
      });
  };

  return (<>
    <Modal
      title="Chỉnh sửa Pháp Nhân"
      visible={open}
      onCancel={onCancel}
      className="modalAddUser"
      width={screen.width > 768 ? 800 : "80%"}
      footer={false}
      bodyStyle={
        screen.width > 768
          ? null
          : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
      }
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        validateMessages={validateMessages}
        // loading={loading}
        initialValues={{
          remember: true,
        }}
        key="form_team"
      >
        <Row gutter={[48, 0]}>
          <Col span={12} >
            <Form.Item
              name="code_signer"
              label="Mã pháp nhân"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: new RegExp(
                    "^[a-zA-Z0-9À-ỹỲỳỴỵỶỷỸỹ_ \\-!@#$%^}&\\\\*=+{[\\|(`~),./?\\]]+$"
                  ),
                  message:
                    "Không được nhập các kí tự đặc biệt như: \" : < > ; ' ",
                },
              ]}
              key="code_signer"
            >
              <Input
                placeholder="Nhập mã pháp nhân"
                maxLength={30}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={12} >
            <Form.Item
              name="name_signer"
              label="Tên pháp nhân"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: new RegExp(
                    "^[a-zA-Z0-9À-ỹỲỳỴỵỶỷỸỹ_ \\-!@#$%^}&\\\\*=+{[\\|(`~),./?\\]]+$"
                  ),
                  message:
                    "Không được nhập các kí tự đặc biệt như: \" : < > ; ' ",
                },
              ]}
              key="name_signer"
            >
              <Input
                placeholder="Nhập tên pháp nhân"
                maxLength={30}
              ></Input>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          style={{ marginTop: "20px", textAlign: "center" }}
          key="btn_team">
          <LoadingButton
            type="submit"
            className={"m-2"}
            style={{ marginRight: "20px" }}
            variant="contained"
            loading={loadingEdit}
          >
            Chỉnh sửa
          </LoadingButton>
          <Button
            onClick={onCancel}
            color="error"
            variant="contained"
          // disabled={loadingAddNew}
          >
            Hủy
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  </>)
}

const ModalAddDocs = ({ open, onCancel, lsRole, loading, fetchData, pager }) => {
  const [form] = Form.useForm();
  const [loadingAddNew, setLoadingAddNew] = useState(false);
  useEffect(() => {
    form.resetFields();
  }, []);

  const onFinish = (values) => {
    setLoadingAddNew(true);
    const typeDocument = {
      code_signer: values.code_signer.trim(),
      name_signer: values.name_signer.trim(),
    };
    PostLegalEntity(typeDocument)
      .then((res) => {
        if (res.data.error) {
          openNotificationWithIcon("error", res.data.error);
        } else {
          setLoadingAddNew(false);

          openNotificationWithIcon(
            "success",
            "Thành công",
            "Thêm mới Pháp nhân thành công"
          );
          fetchData({ page: pager.current, page_size: pager.pageSize });
          onCloseModal();
          form.resetFields();
        }
      })
      .catch((err) => {
        if (err.data.error) {
          setLoadingAddNew(false);
          openNotificationWithIcon("error", err.data.error);
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    openNotificationWithIcon("error", "Lỗi");
  };

  const onCloseModal = () => {
    form.resetFields();
    onCancel();
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const onChangeFields = (event, field, intChoose) => {
    let valueTemp = event.target.value;
    switch (intChoose) {
      case 1:
        if (
          event.target.value.charAt(event.target.value.length - 1) === "." ||
          event.target.value === "-"
        ) {
          valueTemp = event.target.value.slice(0, -1);
        }
        let newValue = toSlug(event.target.value);
        form.setFieldsValue({
          [field]: newValue,
          // username: valueTemp.replace(/\s+/, ''),
        });
        break;
      case 2:
        break;
      default:
      // code block
    }
  };

  return (
    <Modal
      title="Thêm Pháp nhân"
      visible={open}
      onCancel={onCloseModal}
      className="modalAddUser"
      width={screen.width > 768 ? 800 : "80%"}
      footer={false}
      bodyStyle={
        screen.width > 768
          ? null
          : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
      }
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        validateMessages={validateMessages}
        initialValues={{
          remember: true,
        }}
        key="form_docs"
      >
        <Row gutter={[48, 0]}>
          <Col span={12}>
            <Form.Item
              name="code_signer"
              label="Mã pháp nhân"
              rules={[
                {
                  required: true,
                },
                // {
                //   pattern: new RegExp(
                //     "^[a-zA-Z0-9À-ỹỲỳỴỵỶỷỸỹ_ -]+$"
                //   ),
                //   message:
                //     "Chỉ được nhập chữ, số và kí tự: - và _",
                // },
                {
                  pattern: new RegExp(
                    "^[a-zA-Z0-9À-ỹỲỳỴỵỶỷỸỹ_ \\-!@#$%^}&\\\\*=+{[\\|(`~),./?\\]]+$"
                  ),
                  message:
                    "Không được nhập các kí tự đặc biệt như: \" : < > ; ' ",
                },
              ]}
              key="code_signer"
            >
              <Input
                placeholder="Nhập mã pháp nhân"
                maxLength={30}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name_signer"
              label="Tên pháp nhân"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: new RegExp(
                    "^[a-zA-Z0-9À-ỹỲỳỴỵỶỷỸỹ_ \\-!@#$%^}&\\\\*=+{[\\|(`~),./?\\]]+$"
                  ),
                  message:
                    "Không được nhập các kí tự đặc biệt như: \" : < > ; ' ",
                },
              ]}
              key="name_signer"
            >
              <Input
                placeholder="Nhập tên pháp nhân"
                maxLength={30}
              ></Input>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          style={{ marginTop: "20px", textAlign: "center" }}
          key="btn_docs"
        >
          <LoadingButton
            type="submit"
            className={"m-2"}
            style={{ marginRight: "20px" }}
            variant="contained"
            loading={loadingAddNew}
          >
            Đăng ký
          </LoadingButton>
          <Button
            onClick={onCloseModal}
            color="error"
            variant="contained"
            disabled={loadingAddNew}
          >
            Hủy
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

function PhapNhanKy() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isModalAddNew, setIsModalAddNew] = useState(false);
  const [lsRole, setLsRole] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [detailLegalEntity, setDetailLegalEntity] = useState();
  const [search, setSearch] = useState("");

  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const checkRoleManageAdmin = userInfo.role_name.toLowerCase() === "manage_admin"

  const [pager, setPager] = useState({
    pageSize: pageSize,
    count: 0,
    current: 1,
  });
  const fetchData = (params = {}) => {
    setLoading(true);
    GetListLegalEntity(params)
      .then((res) => {
        setData(res.data.results);
        setPager({
          current: params.page,
          pageSize: params.page_size,
          count: res.data.count,
        });
        setLoading(false);
      })
      .catch((err) => {
        errorHandle(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    const id = setInterval(() => {
      fetchData({ page: 1, page_size: pager.pageSize, search });
      clearInterval(id);
    }, 300);
    return () => clearInterval(id);
  }, [search]);

  const showModalAddDocs = () => {
    setIsModalAddNew(true);
  };

  const delete_legal_signed = (record) => {
    const lock = {
      id_legal_signed: record.id,
      is_lock: false,
      is_active: false,
    };
    PutLegalEntityAPI(lock)
      .then((res) => {
        openNotificationWithIcon("success", res.data.result);
        fetchData({ page: pager.current, page_size: pager.pageSize, search });
      })
      .catch((err) => {
        if (err.data.error) {
          openNotificationWithIcon("error", err.data.error);
        }
      });
  };

  const onShowModalEdit = (record) => {
    GetDetailLegalEntity(record.id)
      .then((re) => {
        setDetailLegalEntity(re.data);
        setIsEditing(true);
      })
      .catch((err) => { });
  };

  const renderDetailsButton = (record) => {
    return (
      <Box sx={{ width: "100%" }}>
        <Grid item container xs={12}>
          <Grid
            xs={5}
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
          ></Grid>
          <Grid
            xs={1}
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <span
              title="Chỉnh sửa"
              className={"col-6"}
              style={{ color: "#4290d3", cursor: "pointer", marginRight: 10 }}
              onClick={() => onShowModalEdit(record)}
            >
              <EditOutlined style={{ color: "green" }} />
            </span>
          </Grid>
          <Grid
            xs={2}
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <span
              // title={"Tài liệu đang mở"}
              className={"col-6"}
              style={{ color: "#008000", cursor: "pointer" }}
            >
              <Popconfirm
                title="Bạn có muốn xóa mã pháp nhân này không?"
                onConfirm={() => delete_legal_signed(record)}
                okText="Có"
                cancelText="Không"
              >
                <DeleteOutlined style={{ color: "red" }} />
              </Popconfirm>
            </span>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const columnsManageAdmin = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "10%",
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
    },
    {
      title: "Mã pháp nhân",
      dataIndex: "code_signer",
      key: "code_signer",
      align: "left",
      ellipsis: true,
      render: (text, record) => text,

      //(
      //   <>
      //     <Tooltip placement="top" title={text}>
      //       <span>{text}</span>
      //     </Tooltip>
      //   </>
      // ),
    },
    {
      title: "Tên pháp nhân",
      dataIndex: "name_signer",
      key: "name_signer",
      align: "left",
      ellipsis: true,
      render: (text, record) => text
      // (
      //   <>
      //     <Tooltip placement="top" title={text}>
      //       <span>{text}</span>
      //     </Tooltip>
      //   </>
      // ),
    },
  ];
  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "10%",
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
    },
    {
      title: "Mã pháp nhân",
      dataIndex: "code_signer",
      key: "code_signer",
      align: "left",
      ellipsis: true,
      render: (text, record) => text,

      //(
      //   <>
      //     <Tooltip placement="top" title={text}>
      //       <span>{text}</span>
      //     </Tooltip>
      //   </>
      // ),
    },
    {
      title: "Tên pháp nhân",
      dataIndex: "name_signer",
      key: "name_signer",
      align: "left",
      ellipsis: true,
      render: (text, record) => text
      // (
      //   <>
      //     <Tooltip placement="top" title={text}>
      //       <span>{text}</span>
      //     </Tooltip>
      //   </>
      // ),
    },
    {
      title: "Thao tác",
      align: "center",
      render: renderDetailsButton,
      width: "20%",
    },
  ];

  const onSearch = (value) => {
    setSearch(value);
  };


  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = pagination.pageSize;
    setPager({ ...currentPager });
    fetchData({
      status: currentPager.status,
      page_size: pagination.pageSize,
      page: pagination.current,
      search,
    });
  };

  return (
    <>
      <Row style={{ width: "100%", marginBottom: "10px" }}>
        <Col span={16}>
          <Button
            className="btnAddUser"
            style={{ marginRight: "16px", float: "left" }}
            onClick={showModalAddDocs}
          >
            <ControlPointIcon style={{ fontSize: 20, marginRight: "5px" }} /> Thêm mới
          </Button>
        </Col>
        <Col span={8}>
          <Search
            placeholder="Nhập thông tin cần Tìm kiếm"
            allowClear
            enterButton
            size="middle"
            onSearch={onSearch}
            loading={false}
          />
        </Col>
      </Row>
      <Row>
        <Col span={18} offset={3}>
          <Table
            columns={columns}
            dataSource={data}
            onChange={handleChange}
            rowClassName={"styleTaiLieu"}
            locale={{ emptyText: "Không có dữ liệu" }}
            style={{ position: "absolute" }}
            scroll={{
              y: "68vh",
            }}
            pagination={{
              current: pager.current,
              pageSize: pager.pageSize,
              // showSizeChanger: true,
              total: pager.count,
              // pageSizeOptions: ["10", "15", "25", "50"],
              locale: {
                jump_to: "Đến trang: ",
                page: "",
              },
              showSizeChanger: true,
            }}
            size="middle"
          />
        </Col>
      </Row>
      <ModalAddDocs
        open={isModalAddNew}
        onCancel={() => setIsModalAddNew(false)}
        lsRole={lsRole}
        loading={loading}
        search={search}
        fetchData={fetchData}
        pager={pager}
      />
      <ModalEditTypeDocument
        open={isEditing}
        data={detailLegalEntity}
        onCancel={() => setIsEditing(false)}
        fetchData={fetchData}
        pager={pager}
        search={search}
      // loading= {loading}
      />
    </>
  )
}

export default PhapNhanKy
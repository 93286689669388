import React, { useEffect, useState } from "react";
import {
    Modal,
    Form,
    Input,
    Row,
    Col,
    Select,
} from "antd";
import {openNotificationWithIcon } from "../../../Function";
import {
    PostUser,
} from "../../../../api/manageUser";
import moment from "moment";

import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import "../qlyNguoiDung.css";
const { Option } = Select;

const validateMessages = {
    required: "Không được để trống ô ${label} !",
    types: {
        email: "${label} đang sai định dạng!",
        number: "${label} not numbers!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const ModalAddUser = ({
    open,
    onCancel,
    fetchData,
    pager,
    lsRole,
    lsTeam,
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        form.resetFields();
    }, []);

    const onFinish = (values) => {
        setLoading(true);
        const user = {
            username: values.username.trim(),
            email: values.email,
            last_name: values.last_name.trim(),
            first_name: values.first_name.trim(),
            group_role: values.group_role,
            msnv: values.msnv.trim(),
            role_user: values.role_user,
            team: values.team || "",
        };
        PostUser(user)
            .then((res) => {
                if (res.data.error) {
                    setLoading(false);
                    openNotificationWithIcon("error", res.data.error);
                } else {
                    setLoading(false);
                    openNotificationWithIcon("success", "Thành công", res.data.result);
                    fetchData({ page: pager.current, page_size: pager.pageSize });
                    onCloseModal();
                    form.resetFields();
                }
            })
            .catch((err) => {
                if (err.data.error) {
                    setLoading(false);
                    openNotificationWithIcon("error", err.data.error);
                }
            });
    };

    const onCloseModal = () => {
        form.resetFields();
        onCancel();
    };

    const disabledDate = (current) => {
        return current && current > moment().endOf("day");
    };

    return (
        <Modal
            title={
                <Row>
                    <Col span={15}>
                        <span>Thêm người dùng</span>
                    </Col>
                </Row>
            }
            visible={open}
            onCancel={onCloseModal}
            className="modalAddUser"
            width={screen.width > 768 ? 800 : "80%"}
            bodyStyle={
                screen.width > 768
                    ? null
                    : { overflowY: "auto", maxHeight: "calc(65vh - 145px)" }
            }
            footer={false}
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
                loading={loading}
                initialValues={{
                    remember: true,
                }}
            >
                {screen.width > 768 ? (
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                name="username"
                                label="User name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="username"
                            >
                                <Input maxLength={100} placeholder="User name"></Input>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Email"
                                    onBlur={(e) => {
                                        form.setFieldsValue({ email: e.target.value.trim() });
                                    }}
                                    maxLength={100}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="last_name"
                                label="Tên"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: new RegExp(
                                            "^[0-9a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$"
                                        ),
                                        message: "Không được nhập kí tự đặc biệt",
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={100}
                                    placeholder="Tên"
                                    onBlur={(e) => {
                                        form.setFieldsValue({
                                            last_name: e.target.value.trim(),
                                        });
                                    }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item
                                name="first_name"
                                label="Họ"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: new RegExp(
                                            "^[0-9a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$"
                                        ),
                                        message: "Không được nhập kí tự đặc biệt",
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={100}
                                    placeholder="Họ"
                                    onBlur={(e) => {
                                        form.setFieldsValue({
                                            first_name: e.target.value.trim(),
                                        });
                                    }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="role_user"
                                label="Phân quyền"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="role_user"
                            >
                                <Select
                                    placeholder="Chọn quyền cho user"
                                    showSearch={false}
                                    style={{
                                        width: "100%",
                                    }}
                                    allowClear
                                >
                                    {lsRole.map((item, index) => (
                                        <Option key={item.name} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item name="team" label="Team" key="team">
                                <Select
                                    placeholder="Chọn Team"
                                    showSearch={false}
                                    style={{
                                        width: "100%",
                                    }}
                                    allowClear
                                >
                                    {lsTeam.map((item, index) => (
                                        <Option key={item.name} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="msnv"
                                label="Mã số nhân viên"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="msnv"
                            >
                                <Input maxLength={100} placeholder="Mã số nhân viên"></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="username"
                                label="User name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="username"
                            >
                                <Input maxLength={100} placeholder="User name"></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Email"
                                    onBlur={(e) => {
                                        form.setFieldsValue({ email: e.target.value.trim() });
                                    }}
                                    maxLength={100}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="last_name"
                                label="Tên"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: new RegExp(
                                            "^[0-9a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$"
                                        ),
                                        message: "Không được nhập kí tự đặc biệt",
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={100}
                                    placeholder="Tên"
                                    onBlur={(e) => {
                                        form.setFieldsValue({
                                            last_name: e.target.value.trim(),
                                        });
                                    }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="first_name"
                                label="Họ"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: new RegExp(
                                            "^[0-9a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$"
                                        ),
                                        message: "Không được nhập kí tự đặc biệt",
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={100}
                                    placeholder="Họ"
                                    onBlur={(e) => {
                                        form.setFieldsValue({
                                            first_name: e.target.value.trim(),
                                        });
                                    }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="role_user"
                                label="Phân quyền"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="role_user"
                            >
                                <Select
                                    placeholder="Chọn quyền cho user"
                                    showSearch={false}
                                    style={{
                                        width: "100%",
                                    }}
                                    allowClear
                                >
                                    {lsRole.map((item, index) => (
                                        <Option key={item.name} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="team"
                                label="Nhóm người dùng"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="team"
                            >
                                <Select
                                    placeholder="Chọn nhóm người dùng"
                                    showSearch={false}
                                    style={{
                                        width: "100%",
                                    }}
                                    allowClear
                                >
                                    {lsTeam.map((item, index) => (
                                        <Option key={item.name} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="msnv"
                                label="Mã số nhân viên"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                key="msnv"
                            >
                                <Input maxLength={100} placeholder="Mã số nhân viên"></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Form.Item style={{ marginTop: "20px", textAlign: "center" }}>
                    <LoadingButton
                        type="submit"
                        className={"m-2"}
                        style={{ marginRight: "20px" }}
                        variant="contained"
                        loading={loading}
                    >
                        Đăng ký
                    </LoadingButton>
                    <Button
                        onClick={onCloseModal}
                        color="error"
                        variant="contained"
                        disabled={loading}
                    >
                        Hủy
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAddUser